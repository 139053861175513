import {GenericEntity} from '../entites/generic.entity';

export class ConditionEntity extends GenericEntity {
    type: string | null = null;
    value: number | null = null;
    operator: string | null = null;
    product_fk_id: number | null = null;

    static get type_SET() {
        return {
            sum_of_sales_price: 'sum_of_sales_price',
            count_of_deals: 'count_of_deals',
            total_side_count: 'total_side_count',
            total_sales_volume: 'total_sales_volume',
            total_agent_split: 'total_agent_split',
            total_company_split: 'total_company_split',
            total_team_split: 'total_team_split',
            total_royalty_split: 'total_royalty_split',
            total_user_royalty_split: 'total_user_royalty_split',
            per_deal_sales_price: 'per_deal_sales_price',
            per_deal_gross_commission: 'per_deal_gross_commission',
            per_deal_adjusted_gross_commission: 'per_deal_adjusted_gross_commission',
            total_product_income: 'total_product_income',
            total_product_expense: 'total_product_expense'
        };
    }

    static get operator_SET() {
        return {
            more_than: 'more_than',
            less_then: 'less_then',
            more_than_or_equal: 'more_than_or_equal',
            less_then_or_equal: 'less_then_or_equal',
            equals: 'equals'
        };
    }
}
