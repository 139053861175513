<div>
    <!--    Content Container-->
    <div style="padding: 24px 24px 0 24px">
        <!--    Header-->
        <div class="d-flex align-items-center">
            <h2>Money Transfers (EFT)</h2>
            <a
                href="https://zilmoney.com/terms-and-condition/"
                target="_blank"
                style="margin: 5px 0 0 10px"
                *ngIf="isPayloadCoFeatureFlagEnabled && selectedDriver !== 'payload' && selectedDriver !== null"
            >
                Powered by ZilMoney
            </a>
            <a
                href="https://payload.co/terms"
                target="_blank"
                style="margin: 5px 0 0 10px"
                *ngIf="isPayloadCoFeatureFlagEnabled && selectedDriver === 'payload'"
            >
                Powered by Payload.co
            </a>
            <span class="sp-spacer"></span>
            <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
        </div>
        <div>
            <span style="font-size: 15px" *ngIf="isPayloadCoFeatureFlagEnabled && selectedDriver === 'payload'">
                If you need any assistance with your Money Transfer account, call (833) 593-0877 or email at
                <a href="mailto:support@payload.co" target="_blank">support&#64;payload.co</a>
            </span>
        </div>

        <!--    Payment Settings-->
        <div [formGroup]="moneyTransferPaymentSettingsGroup">
            <div
                class="d-flex align-items-center"
                style="min-height: 65.5px"
                *ngIf="isScheduledPaymentFeatureFlagEnabled"
            >
                <div class="d-flex w-50 mr-2">
                    <mat-slide-toggle
                        [checked]="moneyTransferPaymentSettingsGroup.controls.is_schedule_enabled.value"
                        (change)="scheduleModeChange($event)"
                        >Schedule Payment</mat-slide-toggle
                    >
                </div>
                <div class="w-50 mr-2" *ngIf="moneyTransferPaymentSettingsGroup.controls.is_schedule_enabled.value">
                    <mat-form-field [floatLabel]="'always'" class="w-100">
                        <input
                            matInput
                            type="text"
                            autocomplete="off"
                            placeholder="Pay Date"
                            [min]="minScheduleDate"
                            [max]="maxScheduleDate"
                            formControlName="scheduled_date"
                            [matDatepicker]="picker"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="d-flex align-items-center">
                <div class="w-50 mr-2">
                    <app-date-picker
                        [disabled]="true"
                        [placeholder]="'Payment Date'"
                        [dateControl]="moneyTransferPaymentSettingsGroup.controls.invoice_date"
                    >
                    </app-date-picker>
                </div>
                <mat-form-field class="w-50 mr-2">
                    <mat-select [placeholder]="'Payment Method'" formControlName="paid_by__payment_method_fk_id">
                        <mat-optgroup *ngFor="let gateway of availableGateways" [label]="gateway.title">
                            <ng-container *ngFor="let method of gateway.payment_methods">
                                <mat-option
                                    *ngIf="method.type !== 'zipi_financial_credit_card'"
                                    (onSelectionChange)="methodChanged($event, gateway)"
                                    [value]="method.payment_method_id"
                                    [disabled]="
                                        gateway.status !== 'active' ||
                                        method.status !== 'active' ||
                                        !method.related__ledger_account_fk_id
                                    "
                                >
                                    <span>{{ method.title }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!--Payouts List-->
        <div>
            <!--Table Header-->
            <div class="table-header">
                <div class="w-25">Payment To</div>
                <div class="w-15">Amount</div>
                <div class="w-20">Account</div>
                <div class="w-30">Reference</div>
                <div class="w-10"></div>
            </div>

            <!--Items Section-->
            <div style="height: 400px; max-height: 45vh; overflow-y: auto">
                <div *ngIf="itemsArray && itemsArray.length > 0 && itemsArrayBuilt">
                    <!--Item-->
                    <div *ngFor="let payoutGroup of itemsArray.controls; index as idx">
                        <div [formGroup]="payoutGroup" class="list-item">
                            <div class="w-25 d-flex" style="padding-top: 17px">
                                <!--                                <span style="width: 20px; display: flex; align-items: center;">-->
                                <!--                                    <span *ngIf="selectedDriver !== null">-->
                                <!--                                        <mat-icon *ngIf="selectedDriver !== 'payload' && !payoutGroup.controls.is_available_for_zipi_pay.value" style="color: orange; font-size: 18px; width: 18px; height: 18px" matTooltip="Receiver cannot get money via Money Transfers (EFT) via ZilMoney. Payout will not be processed">warning</mat-icon>-->
                                <!--                                        <mat-icon *ngIf="selectedDriver === 'payload' && !payoutGroup.controls.is_available_for_payloadco_zipi_pay.value" style="color: orange; font-size: 18px; width: 18px; height: 18px" matTooltip="Receiver cannot get money via Money Transfers (EFT) via Payload.co. Payout will not be processed">warning</mat-icon>-->
                                <!--                                    </span>-->
                                <!--                                </span>-->
                                <span>{{ payoutGroup.controls.pay_to_name.value }}</span>
                            </div>
                            <div class="w-15" style="padding-top: 17px">
                                <div class="amount-label d-flex p-0">
                                    <app-custom-popover
                                        *ngIf="
                                            moneyTransferPaymentSettingsGroup.controls.is_need_to_combine_payments
                                                .value &&
                                            payoutGroup.controls.sub_payouts &&
                                            payoutGroup.controls.sub_payouts.value &&
                                            payoutGroup.controls.sub_payouts.value.length &&
                                            payoutGroup.controls.sub_payouts.value.length > 1
                                        "
                                        [elementId]="'remainingSubPayout' + idx"
                                        [width]="440"
                                    >
                                        <div class="w-100 p-0">
                                            <ng-container
                                                *ngFor="
                                                    let subPayout of payoutGroup.controls.sub_payouts.value;
                                                    last as last
                                                "
                                                class="w-100 p-0"
                                            >
                                                <div class="d-flex my-3 p-0">
                                                    <span style="font-weight: bold">Deal #{{ subPayout.deal_id }}</span>
                                                    <span class="sp-spacer"></span>
                                                    <span>{{ subPayout.amount | currency }}</span>
                                                </div>
                                                <mat-divider *ngIf="!last" class="mb-2"></mat-divider>
                                            </ng-container>
                                        </div>
                                    </app-custom-popover>
                                    <span
                                        *ngIf="
                                            !payoutGroup.controls.sub_payouts ||
                                            !payoutGroup.controls.sub_payouts.value ||
                                            !payoutGroup.controls.sub_payouts.value.length ||
                                            payoutGroup.controls.sub_payouts.value.length === 1
                                        "
                                        >{{ payoutGroup.controls.amount.value | currency }}</span
                                    >
                                    <span
                                        [id]="'remainingSubPayout' + idx"
                                        class="combined-payouts"
                                        *ngIf="
                                            payoutGroup.controls.sub_payouts &&
                                            payoutGroup.controls.sub_payouts.value &&
                                            payoutGroup.controls.sub_payouts.value.length &&
                                            payoutGroup.controls.sub_payouts.value.length > 1
                                        "
                                        [matBadge]="payoutGroup.controls.sub_payouts.value.length"
                                        matBadgeOverlap="true"
                                        >{{ payoutGroup.controls.amount.value | currency }}</span
                                    >
                                </div>
                            </div>
                            <div class="w-20">
                                <div class="p-0" *ngIf="selectedDriver !== null">
                                    <mat-form-field *ngIf="selectedDriver === 'payload'" class="w-90">
                                        <mat-label
                                            *ngIf="payoutGroup.controls.pay_to__payment_method_fk_id.value === null"
                                            class="add-new-account-label"
                                        >
                                            <div class="d-flex align-items-center">
                                                <mat-icon
                                                    style="
                                                        color: orange;
                                                        font-size: 18px;
                                                        width: 18px;
                                                        height: 18px;
                                                        margin-right: 5px;
                                                    "
                                                    >warning</mat-icon
                                                >
                                                <span>No details</span>
                                            </div>
                                        </mat-label>
                                        <mat-label
                                            *ngIf="
                                                payoutGroup.controls.pay_to__payment_method_fk_id.value === undefined
                                            "
                                            class="add-new-account-label"
                                        >
                                            <div class="d-flex align-items-center">
                                                <span>Add New</span>
                                            </div>
                                        </mat-label>
                                        <mat-select formControlName="pay_to__payment_method_fk_id">
                                            <mat-option
                                                *ngFor="
                                                    let method of payoutGroup.controls.payloadco_receive_only_methods
                                                        .value
                                                "
                                                [value]="method.payment_method_id"
                                            >
                                                <span>{{ method.title }}</span>
                                            </mat-option>
                                            <mat-option
                                                [value]="undefined"
                                                (click)="
                                                    setupZipiFinancialForContact(
                                                        'contact_bank',
                                                        'payload',
                                                        payoutGroup.value
                                                    )
                                                "
                                            >
                                                <div class="add-new-account-option">
                                                    <span>Add New</span>
                                                    <mat-icon style="margin: 0">add</mat-icon>
                                                </div>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div
                                        style="padding: 17px 0 0 0"
                                        *ngIf="
                                            selectedDriver !== 'payload' &&
                                            payoutGroup.controls.is_available_for_zipi_pay.value
                                        "
                                    >
                                        Zil Money
                                    </div>
                                    <div
                                        style="padding: 17px 0 0 0"
                                        *ngIf="
                                            selectedDriver !== 'payload' &&
                                            !payoutGroup.controls.is_available_for_zipi_pay.value
                                        "
                                        class="d-flex align-items-center"
                                    >
                                        <mat-icon
                                            *ngIf="
                                                selectedDriver !== 'payload' &&
                                                !payoutGroup.controls.is_available_for_zipi_pay.value
                                            "
                                            style="
                                                color: orange;
                                                font-size: 18px;
                                                width: 18px;
                                                height: 18px;
                                                margin-right: 5px;
                                            "
                                            matTooltip="Receiver cannot get money via Money Transfers (EFT) via ZilMoney. Payout will not be processed"
                                            >warning</mat-icon
                                        >
                                        <mat-icon
                                            *ngIf="
                                                selectedDriver === 'payload' &&
                                                !payoutGroup.controls.is_available_for_payloadco_zipi_pay.value
                                            "
                                            style="
                                                color: orange;
                                                font-size: 18px;
                                                width: 18px;
                                                height: 18px;
                                                margin-right: 5px;
                                            "
                                            matTooltip="Receiver cannot get money via Money Transfers (EFT) via Payload.co. Payout will not be processed"
                                            >warning</mat-icon
                                        >
                                        <span> No Account </span>
                                    </div>
                                </div>
                            </div>
                            <div class="w-33">
                                <mat-form-field class="w-100">
                                    <textarea matInput type="text" formControlName="reference"> </textarea>
                                    <mat-label *ngIf="!payoutGroup.controls.reference.value">Reference</mat-label>
                                </mat-form-field>
                            </div>
                            <div class="w-7" style="padding-top: 12px">
                                <button mat-icon-button (click)="removeItem(idx)">
                                    <span class="material-symbols-outlined remove-item-icon"> do_not_disturb_on </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!itemsArrayBuilt" class="w-100 text-center mt-2">Data Loading...</div>
                <div *ngIf="!itemsArray || (itemsArray.length === 0 && itemsArrayBuilt)" class="w-100 text-center mt-2">
                    Nothing to Display
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="action-section">
        <div class="d-flex w-50 mr-2" [formGroup]="moneyTransferPaymentSettingsGroup" *ngIf="!isCombineFeatureDisabled">
            <mat-slide-toggle formControlName="is_need_to_combine_payments" (change)="setupItemsArray()"
                >Combine Payments</mat-slide-toggle
            >
        </div>
        <span class="sp-spacer"></span>
        <button mat-button color="primary" class="ml-2" (click)="dialogRef.close(false)" tabindex="-1">Back</button>
        <button
            mat-raised-button
            class="ml-1"
            [disabled]="
                !submitAvailable ||
                !itemsArray ||
                (itemsArray.length === 0 && itemsArrayBuilt) ||
                this.moneyTransferPaymentSettingsGroup.invalid ||
                this.itemsArray.invalid
            "
            color="primary"
            (click)="continue()"
            tabindex="1"
        >
            Save & Proceed
        </button>
    </div>
</div>
