import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, first, takeUntil, tap, find, skipWhile} from 'rxjs/operators';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {UntypedFormControl, Validators} from '@angular/forms';
import {GenericFormArray, GenericFormGroup} from 'app/entites/generic.entity';
import {ChipNode} from '../../models/chip-node';
import {ContactWildcard} from '../../models/contact-wildcard';
import {SessionService} from 'app/services/session.service';
import {Company} from 'app/models/company';
import {SearchEntitiesSource} from 'app/services/sources/search-entities.source';
import {GroupApi} from 'app/services/api/group.api';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import * as moment from 'moment';
import {ProfilesService} from 'app/services/profiles.service';
import {ContactClassService} from '../../../../../services/api/contact-class.service';
import {IContact, ISearchEntity, IContactPartLink, IParticipantSubTypes} from '@cyberco-nodejs/zipi-typings';
import {IEntitiesPack} from '../../../../../models/entity-load';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ITextPartcipantData} from 'app/modules/shared/components/deal-participants/deal-participants.component';
import {ContactClass} from '../../../../../models/contact-class';

export type ___TYPE =
    | 'company_group'
    | 'individuals_in_group'
    | 'individual'
    | 'contact'
    | 'contact_wildcard'
    | 'wildcard'
    | 'contact_class'
    | 'contact_person';
export type CONTACT_METATYPE = 'agent' | 'customer' | 'vendor' | null;

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-company-compensation-combined-picker',
    styleUrls: ['./combined-compensation-profile-picker.component.scss'],
    templateUrl: './combined-compensation-profile-picker.component.html'
})
export class CombinedCompensationProfilePickerComponent implements OnChanges, OnInit, OnDestroy, AfterViewChecked {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeSearch: Subject<void> = new Subject();

    CHIP_NODE = ChipNode;
    commonList: BehaviorSubject<ISearchEntity[]> = new BehaviorSubject<ISearchEntity[]>([]);

    // @workaround around a "Contact Wildcard" workaround
    @Input() allowDealWildcard: boolean = false;
    @Input() nodesFA: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    @Input() hideControls: boolean = false;
    @Input() singleSelected: boolean = false;
    @Input() heading: string = '';
    @Input() title: string = 'Participants';
    @Input() bold_style: boolean = true;
    @Input() only_compensation_groups: boolean = true;
    @Input() without_company_group: boolean = false;
    @Input() availableTypes: Array<___TYPE> = ['company_group', 'individuals_in_group', 'individual'];
    @Input() displayMode: 'single-line' | 'double-line' = 'single-line';
    @Input() positionMode: 'bottom' | 'top' | 'none' = 'top';
    @Input() disabled: boolean = false;
    @Input() seemDisabled: boolean = false;
    @Input() disableUpdate: boolean = false;
    @Input() initialContactIds: any[] = [];
    @Input() initialGroupIds: number[] = [];
    @Input() initialProfilesInGroupIds: number[] = [];
    @Input() initialProfileIds: number[] = [];
    @Input() initialContactClassIds: number[] = [];
    @Input() initialContactProfileIds: {id: number; fake_title: string}[] = [];
    @Input() panelWidth: string | number = 0;
    @Input() onlyDivisions: boolean = false;
    @Input() onlyCommonGroups: boolean = false;
    @Input() contactMetatype: CONTACT_METATYPE = null;
    @Input() contactPersonMain: boolean = false;
    @Input() isContactNeeded: boolean = false;
    @Input() dealParticipantsWithSubType: IContactPartLink[] | null = null;
    @Input() excludeMembersFromGroup: number | null = null;
    @Input() closeAfterSelection: boolean = true;
    @Input() required: boolean = false;
    @Input() hideRequiredMarker: boolean = false;
    @Input() floatLabel: 'always' | 'never' | 'auto' = 'always';
    @Input() lineClamp: boolean = false; // show text reduced and in 2 rows if it's too long
    @Input() textParticipants: ITextPartcipantData[] = [];
    @Input() needContactName: boolean = false;

    @Input() contactWithPartnerCompany: boolean = false;
    @Input() contactWithoutPartnerCompany: boolean = false;

    // for looking entities through sub-companies
    @Input() allowSubCompanySearch: boolean = false;
    @Input() chosenSubCompanies: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);
    subCompanyIds: number[] = [];

    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger | undefined = undefined;
    @Input() closePanelEvent$: Subject<void> = new Subject<void>();

    @ViewChild('filter') filterInput: ElementRef<HTMLInputElement> | undefined = undefined;

    @Output() contactCreateEmitter: EventEmitter<{created: IContact}> = new EventEmitter<{created: IContact}>();
    @Output() isLoadedEmitter: EventEmitter<void> = new EventEmitter<void>();
    @Output() toggleVisibilityEmitter: EventEmitter<{is_ignored: boolean; id: number}> = new EventEmitter<{
        is_ignored: boolean;
        id: number;
    }>();
    @Output() contactCreateFromTextEmitter: EventEmitter<{id: number}> = new EventEmitter<{id: number}>();

    filterFC: UntypedFormControl = new UntypedFormControl('');
    queryString: string = '';
    showMore: boolean = false;

    visible = true;
    addOnBlur = true;
    firstLoad = true;
    params = {
        offset: 0,
        limit: 30
    };

    entitySubscription: BehaviorSubject<IEntitiesPack[]> = new BehaviorSubject<IEntitiesPack[]>([]);
    @ViewChild('auto') matAutocomplete: MatAutocomplete | undefined = undefined;

    subTypesArray: {title: string; values: IParticipantSubTypes[]}[] = [];

    // this was hardcoded to show icons for text deal-participants, reason - this icon urls stored in addons/ui-mods and we cannot simply deliver these entities here from DB
    textParticipantIconByAddonId: {[key: string]: {title: string; imgUrl: string}} = {
        // dotloop
        '956808986764553': {
            title: 'Dotloop',
            imgUrl: 'https://storage.googleapis.com/zipi-static/addons/dotloop/icons/dotloop_grey_20px.png'
        }, // local vbunin
        '664722315014166': {
            title: 'Dotloop',
            imgUrl: 'https://storage.googleapis.com/zipi-static/addons/dotloop/icons/dotloop_grey_20px.png'
        }, // dev
        '696202648263385': {
            title: 'Dotloop',
            imgUrl: 'https://storage.googleapis.com/zipi-static/addons/dotloop/icons/dotloop_grey_20px.png'
        }, // prod

        // skyslope
        '167173806925364': {
            title: 'SkySlope',
            imgUrl: 'https://storage.googleapis.com/zipi-static/addons/skyslope/icons/skyslope_grey_20px.png'
        },
        // local skyslope
        '123': {
            title: 'SkySlope',
            imgUrl: 'https://storage.googleapis.com/zipi-static/addons/skyslope/icons/skyslope_grey_20px.png'
        }, // local vbunin
        '682795390110691': {
            title: 'SkySlope',
            imgUrl: 'https://storage.googleapis.com/zipi-static/addons/skyslope/icons/skyslope_grey_20px.png'
        }, // dev
        '273454297222156': {
            title: 'SkySlope',
            imgUrl: 'https://storage.googleapis.com/zipi-static/addons/skyslope/icons/skyslope_grey_20px.png'
        } // prod
    };

    constructor(
        protected sessionService: SessionService,
        protected searchEntitiesSource: SearchEntitiesSource,
        private groupService: GroupApi,
        private contactsService: ShipperContactsService,
        private profileService: ProfilesService,
        private contactClassService: ContactClassService,
        private dialog: MatDialog,
        private changeDetector: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        // if filter query string is changed
        this.filterFC.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe((data) => {
            if (typeof data === 'string' && this.queryString !== data.trim()) {
                this.queryString = data.trim();
                this.params.offset = 0;
                this.initialLoadData();
            }
        });

        // if selected sub-companies are changed
        if (this.chosenSubCompanies.value.length) {
            this.chosenSubCompanies.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe((companyList) => {
                this.params.offset = 0;
                if (companyList.length) {
                    this.subCompanyIds = companyList
                        .map((company) => company?.id)
                        .filter((id) => id)
                        .map((id) => id as number);
                }
                this.initialLoadData();
            });
        } else {
            await this.initialLoadData();
        }

        // handle closing mat-autocomplete
        this.closePanelEvent$.pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
            // to allow add selected value from dropdown
            setTimeout(() => (this.autocomplete ? this.autocomplete.closePanel() : null), 300);
        });
    }

    ngAfterViewChecked() {
        if (this.initialProfileIds.length && this.commonList.value.find((e) => this.initialProfileIds.includes(e.id))) {
            // exclude from array
            this.commonList.value.forEach((p) => {
                p.hidden = this.initialProfileIds.includes(p.id);
            });
            this.commonList.next(this.commonList.value);
            this.changeDetector.detectChanges();
        } else if (this.initialProfileIds.length === 0 && this.commonList.value.find((e) => e.hidden)) {
            this.commonList.value.forEach((p) => {
                if (p.hidden) {
                    p.hidden = false;
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // set saved values into picker
        Object.keys(changes).forEach((key) => {
            if (key === 'initialContactIds' && changes[key].currentValue && changes[key].currentValue.length) {
                // try to find contacts in commonList
                const left = changes[key].currentValue
                    .map((i: number | {id: number; fake_title: string}) => {
                        if (typeof i === 'number') {
                            const found = this.commonList.value.find((chip) => chip.id === i);
                            return found ? this.doAddNode(found) : i;
                        } else {
                            const found = this.commonList.value.find((chip) => chip.id === i.id);
                            return found ? this.doAddNode(found) : i.id;
                        }
                    })
                    .filter((chip: ISearchEntity | undefined) => chip);

                // find contacts by ids
                if (left.length) {
                    this.contactsService
                        .getContactsById(left)
                        .pipe(
                            first(),
                            tap((contacts) => {
                                this.initialisingContactPicker(contacts);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();
                }
            }
            if (key === 'initialContactClassIds' && changes[key].currentValue && changes[key].currentValue.length) {
                // try to find contact classes in commonList
                const left = changes[key].currentValue
                    .map((id: number) => {
                        const found = this.commonList.value.find((chip) => chip.id === id);
                        return found ? this.doAddNode(found) : id;
                    })
                    .filter((chip: ISearchEntity | undefined) => chip);

                // find contact classes by ids
                if (left.length) {
                    this.contactClassService
                        .getContactClassesByIds(changes[key].currentValue)
                        .pipe(
                            first(),
                            tap((classes) => {
                                this.initialisingContactClassesPicker(classes);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();
                }
            }
            if (key === 'initialProfilesInGroupIds' && changes[key].currentValue && changes[key].currentValue.length) {
                // try to find groups in commonList
                const left = changes[key].currentValue
                    .map((id: number) => {
                        const found = this.commonList.value.find((chip) => chip.id === id);
                        return found ? this.doAddNode(found) : id;
                    })
                    .filter((chip: ISearchEntity | undefined) => chip);

                // find groups by ids
                if (left.length) {
                    this.groupService
                        .getGroupsByIds(changes[key].currentValue)
                        .pipe(
                            first(),
                            tap((groups) => {
                                this.initialisingProfilesInGroupsPicker(groups.result);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();
                }
            }
            if (key === 'initialGroupIds' && changes[key].currentValue && changes[key].currentValue.length) {
                // try to find groups in commonList
                const left = changes[key].currentValue
                    .map((id: number) => {
                        const found = this.commonList.value.find((chip) => chip.id === id);
                        return found ? this.doAddNode(found) : id;
                    })
                    .filter((chip: ISearchEntity | undefined) => chip);

                // find groups by ids
                if (left.length) {
                    this.groupService
                        .getGroupsByIds(changes[key].currentValue, this.isContactNeeded ? 1 : 0)
                        .pipe(
                            first(),
                            tap((groups) => {
                                this.initialisingGroupsPicker(groups.result);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();
                }
            }
            if (key === 'initialProfileIds' && changes[key].currentValue && changes[key].currentValue.length) {
                // try to find profiles in commonList
                const left = changes[key].currentValue
                    .map((id: number) => {
                        const found = this.commonList.value.find((chip) => chip.id === id);
                        return found ? this.doAddNode(found) : id;
                    })
                    .filter((chip: ISearchEntity | undefined) => chip);

                // find profiles by ids
                if (left.length) {
                    this.profileService
                        .getProfilesByIds(changes[key].currentValue, this.isContactNeeded ? 1 : 0)
                        .pipe(
                            first(),
                            tap((profiles) => {
                                this.initialisingProfilesPicker(profiles.result);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();
                }
            }
            if (key === 'initialContactProfileIds' && changes[key].currentValue && changes[key].currentValue.length) {
                // try to find profiles in commonList
                // if contact_person IS main
                let left = [];
                if (this.contactPersonMain) {
                    left = changes[key].currentValue
                        .map((cp: {id: number; fake_title: string}) => {
                            const found = this.commonList.value.find((chip) => chip.id === cp.id);
                            return found ? this.doAddNode(found) : cp.id;
                        })
                        .filter((chip: ISearchEntity | undefined) => chip);
                    // if contact_person IS NOT main
                } else {
                    left = changes[key].currentValue
                        .map((cp: {id: number; fake_title: string}) => {
                            const found = this.commonList.value.find(
                                (chip: ISearchEntity) =>
                                    chip.contact_persons &&
                                    chip.contact_persons.find((c) => c.contact_person_id === cp.id)
                            );
                            if (found && this.dealParticipantsWithSubType && this.dealParticipantsWithSubType.length) {
                                found.subType =
                                    this.dealParticipantsWithSubType.find((sub) => sub.contact_person_fk_id === cp.id)
                                        ?.sub_type || [];
                            }
                            return found ? this.doAddNode(found) : cp.id;
                        })
                        .filter((chip: ISearchEntity | undefined) => chip);
                }

                // find profiles by ids
                if (left.length) {
                    this.contactsService
                        .getContactPersonsById(left)
                        .pipe(
                            first(),
                            tap((contacts) => {
                                this.initialisingContactPersonsPicker(contacts);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();
                }
            }
        });

        if (this.dealParticipantsWithSubType && this.dealParticipantsWithSubType.length && !this.subTypesArray.length) {
            const types: IParticipantSubTypes[] = this.sessionService.profile?.company
                ?.participant_sub_types as IParticipantSubTypes[];
            let reduced: {[key: string]: {title: string; values: IParticipantSubTypes[]}} = {};
            if (types && types.length) {
                reduced = types.reduce((acc: {[key: string]: {title: string; values: IParticipantSubTypes[]}}, cur) => {
                    if (acc.hasOwnProperty(cur.scope)) {
                        acc[cur.scope].values.push(cur);
                    } else {
                        acc[cur.scope] = {
                            values: [cur],
                            title: cur.scope
                        };
                    }
                    return acc;
                }, {});
            }
            this.subTypesArray = Object.values(reduced);
        }

        if (changes.required) {
            this.filterFC.patchValue(
                [this.filterFC.value, changes.required.currentValue ? Validators.required : null],
                {emitEvent: false}
            );
        }
        if (changes.disableUpdate && changes.disableUpdate.currentValue) {
            this.filterFC.disable({emitEvent: false});
        }
        if (changes.disableUpdate && !changes.disableUpdate.currentValue) {
            this.filterFC.enable({emitEvent: false});
        }
    }

    doRemoveNodeFA(ni: number) {
        this.nodesFA.removeAt(ni);
        this.filterFC.enable();
        // remove from initial array
        this.initialContactIds = [];
        this.initialGroupIds = [];
        this.initialProfilesInGroupIds = [];
        this.initialProfileIds = [];
        this.initialContactClassIds = [];
        this.initialContactProfileIds = [];
    }

    doRemoveAllNodes() {
        this.nodesFA.clear();
        this.filterFC.enable();
    }

    doAddNode(value: ISearchEntity) {
        const chip = new ChipNode()
            .setType(value.type)
            .setLabel(value.label)
            .setTargetId(value.id)
            .setCompanyFkId(value.company_fk_id as number)
            .setSubType(value.hasOwnProperty('subType') && value.subType ? value.subType : [])
            .setPermissions(value.permissions)
            .setEmail(value.email)
            .setContactId(value.contact_id)
            .setContactName(value.contact_name)
            .setContactPerson(value.contact_persons)
            .setRelatedProfileAndGroup(value.related_profile_and_group);

        // not add entity that already exists
        if (
            this.nodesFA.controls &&
            this.nodesFA.controls.filter(
                (control) =>
                    control?.controls?.type?.value === chip.type &&
                    ((control?.controls?.type?.value !== 'contact_person' &&
                        control.controls.target_id &&
                        control.controls.target_id.value === chip.target_id) ||
                        // compare contact_person_id, not a contact_id
                        (control?.controls?.type?.value === 'contact_person' &&
                            control.value.contact_persons[0].contact_person_id ===
                                chip.contact_persons[0].contact_person_id))
            ).length === 0
        ) {
            if (this.singleSelected) {
                if (this.nodesFA.controls.length > 0) {
                    this.filterFC.disable();
                } else {
                    this.nodesFA.push(new GenericFormGroup(chip));
                    this.filterFC.disable();
                }
            } else {
                this.nodesFA.push(new GenericFormGroup(chip));
            }
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.filterFC.patchValue('');
        if (typeof this.filterInput !== 'undefined') {
            this.filterInput.nativeElement.value = '';
            this.filterInput.nativeElement.blur();
        }
        this.doAddNode(event.option.value);
    }

    async initialLoadData() {
        // close previous request
        this.unsubscribeSearch.next();

        // to prevent several requests
        this.showMore = false;

        // create options for entities request
        const filters = {
            query_string: this.queryString,
            types: this.availableTypes,
            sub_company_ids: this.subCompanyIds,
            contact_metatype: this.contactMetatype,
            contact_person_main: this.contactPersonMain ? 1 : 0,
            isContactNeeded: this.isContactNeeded ? 1 : 0,
            excludeMembersFromGroup: this.excludeMembersFromGroup ? this.excludeMembersFromGroup : '',
            contact_with_partner_company: this.contactWithPartnerCompany ? 1 : 0,
            contact_without_partner_company: this.contactWithoutPartnerCompany ? 1 : 0,
            needContactName: this.needContactName ? 1 : 0
        };
        const options = JSON.stringify({filters, params: this.params}) + moment(new Date()).format('DDHHmm').toString();
        const optionsWithoutTime = options.slice(0, -6);
        // check time expiration
        const currentTime = moment(new Date()).format('DDHHmm');
        const currentTimeMinus1Minute = moment(currentTime, 'DDHHmm').subtract(1, 'm').format('DDHHmm');

        // create subscription to reduce number of requests
        this.entitySubscription = await this.searchEntitiesSource.load(options);
        this.entitySubscription
            .pipe(
                find(
                    (list) =>
                        !list.find(
                            (entitiesPack) =>
                                entitiesPack.params === optionsWithoutTime + currentTime ||
                                entitiesPack.params === optionsWithoutTime + currentTimeMinus1Minute
                        )?.load
                ),
                skipWhile((res) => typeof res === 'undefined'),
                takeUntil(this.unsubscribeSearch)
            )
            .subscribe((res) => {
                if (typeof res === 'undefined') {
                    return;
                }

                this.firstLoad = false;
                this.isLoadedEmitter.next();
                const found = res.find(
                    (entitiesPack) =>
                        entitiesPack.params === optionsWithoutTime + currentTime ||
                        entitiesPack.params === optionsWithoutTime + currentTimeMinus1Minute
                );
                if (typeof found === 'undefined') {
                    return;
                }

                // include contact_wildcard
                let contactWildcards: ContactWildcard[] = [];
                if (this.availableTypes.includes('contact_wildcard')) {
                    contactWildcards = this.collectContactWildcard();
                }

                // save data by options
                const listWithFilteredGroups: ISearchEntity[] = this.collectGroups(found.entities);
                contactWildcards.forEach((w) => (w.hidden = false));
                listWithFilteredGroups.forEach((e) => (e.hidden = false));
                this.commonList.next([...contactWildcards, ...listWithFilteredGroups]);

                // change scroll data
                this.params.offset += this.params.limit;
                this.showMore = found.entities.length === this.params.limit;
            });
    }

    private async loadData() {
        // to prevent several requests
        this.showMore = false;

        // get structured and sorted data
        this.searchEntitiesSource
            .searchEntities({
                filters: {
                    query_string: this.queryString,
                    types: this.availableTypes,
                    sub_company_ids: this.subCompanyIds,
                    contact_metatype: this.contactMetatype,
                    contact_person_main: this.contactPersonMain ? 1 : 0,
                    isContactNeeded: this.isContactNeeded ? 1 : 0,
                    excludeMembersFromGroup: this.excludeMembersFromGroup ? this.excludeMembersFromGroup : '',
                    contact_with_partner_company: this.contactWithPartnerCompany ? 1 : 0,
                    contact_without_partner_company: this.contactWithoutPartnerCompany ? 1 : 0
                },
                params: this.params
            })
            .then((list: ISearchEntity[]) => {
                // add previous values only if it was scrolling
                const updatedList = this.collectGroups(list);
                updatedList.forEach((e: ISearchEntity) => (e.hidden = false));
                this.commonList.next([...this.commonList.value, ...updatedList]);

                // change scroll data
                this.params.offset += this.params.limit;
                this.showMore = list.length === this.params.limit;
            });
    }

    private collectGroups(list: ISearchEntity[]) {
        let filteredList = list;
        if (this.only_compensation_groups) {
            filteredList = filteredList.filter(
                (item) =>
                    (item.type === 'company_group' &&
                        JSON.parse(<string>item.permissions).use_in_compensation_as_entity_group) ||
                    (item.type === 'individuals_in_group' &&
                        JSON.parse(<string>item.permissions).use_in_compensation_as_group_of_entities) ||
                    (item.type !== 'company_group' && item.type !== 'individuals_in_group')
            );
        }

        if (this.onlyDivisions) {
            filteredList = filteredList.filter(
                (item) =>
                    ((item.type === 'company_group' || item.type === 'individuals_in_group') &&
                        item.kind === 'division') ||
                    (item.type !== 'company_group' && item.type !== 'individuals_in_group')
            );
        }

        if (this.onlyCommonGroups) {
            filteredList = filteredList.filter(
                (item) =>
                    ((item.type === 'company_group' || item.type === 'individuals_in_group') &&
                        item.kind !== 'division') ||
                    (item.type !== 'company_group' && item.type !== 'individuals_in_group')
            );
        }

        if (this.without_company_group && this.subCompanyIds.length) {
            filteredList = filteredList.filter(
                (item) =>
                    ((item.type === 'company_group' || item.type === 'individuals_in_group') &&
                        !this.chosenSubCompanies.value.map((c) => c.company_group?.id).includes(item.id)) ||
                    (item.type !== 'company_group' && item.type !== 'individuals_in_group')
            );
        }
        if (this.without_company_group && this.subCompanyIds.length === 0) {
            const companyGroupId = this.sessionService.profile?.company?.company_group?.id;
            if (companyGroupId) {
                filteredList = filteredList.filter(
                    (item) =>
                        ((item.type === 'company_group' || item.type === 'individuals_in_group') &&
                            item.id !== companyGroupId) ||
                        (item.type !== 'company_group' && item.type !== 'individuals_in_group')
                );
            }
        }

        return filteredList;
    }

    private collectContactWildcard() {
        // some of contact_wildcards are legacy
        return [
            // new ContactWildcard()
            //     .setContactWildcardId(1)
            //     .setLabel('Mentor')
            //     .setType('contact_wildcard'),
            // new ContactWildcard()
            //     .setContactWildcardId(2)
            //     .setLabel('TRX Coordinator')
            //     .setType('contact_wildcard'),
            new ContactWildcard().setContactWildcardId(3).setLabel('Deal').setType('contact_wildcard'),
            // new ContactWildcard()
            //     .setContactWildcardId(4)
            //     .setLabel('Mentor Team Leader')
            //     .setType('contact_wildcard'),
            new ContactWildcard().setContactWildcardId(5).setLabel('Commission Payer').setType('contact_wildcard'),
            new ContactWildcard().setContactWildcardId(6).setLabel('Primary Agent').setType('contact_wildcard'),
            new ContactWildcard().setContactWildcardId(7).setLabel('Company').setType('contact_wildcard')
        ];
    }

    initialisingContactPicker(items: IContact[]): void {
        items.forEach((contact) => {
            this.doAddNode({
                id: contact.contact_id as number,
                type: 'contact',
                label: contact.display_name!,
                kind: null,
                permissions: null,
                company_fk_id: contact.owner__company_fk_id as number,
                email: '',
                contact_id: null,
                contact_name: '',
                contact_persons: contact.contact_persons.map((cp) => {
                    return {
                        contact_person_id: cp.contact_person_id as number,
                        contact_company_name: contact.company_name!,
                        contact_person_name: cp.first_name + ' ' + cp.last_name,
                        contact_person_type: cp.type
                    };
                }),
                hidden: false,
                subType: [],
                related_profile_and_group: !!(contact.related__profile_fk_id && contact.related__company_group_fk_id)
            });
        });
    }
    initialisingProfilesInGroupsPicker(
        items: {
            company_group_id: number;
            title: string;
            company_fk_id: number;
        }[]
    ): void {
        items.forEach((company_group) => {
            this.doAddNode({
                id: company_group.company_group_id,
                type: 'individuals_in_group',
                label: company_group.title,
                kind: null,
                permissions: null,
                company_fk_id: company_group.company_fk_id,
                email: '',
                contact_id: null,
                contact_name: '',
                contact_persons: null,
                hidden: false,
                subType: [],
                related_profile_and_group: false
            });
        });
    }
    initialisingGroupsPicker(
        items: {
            company_group_id: number;
            title: string;
            company_fk_id: number;
            contact_id: number;
            contact_name: string;
        }[]
    ): void {
        items.forEach((company_group) => {
            this.doAddNode({
                id: company_group.company_group_id,
                type: 'company_group',
                label: company_group.title,
                kind: null,
                permissions: null,
                company_fk_id: company_group.company_fk_id,
                email: '',
                contact_id: company_group.contact_id,
                contact_name: company_group.contact_name,
                contact_persons: null,
                hidden: false,
                subType: [],
                related_profile_and_group: false
            });
        });
    }
    initialisingProfilesPicker(
        items: {
            id: number;
            profile_id: number;
            first_name: string;
            last_name: string;
            company_fk_id: number;
            email: string;
            contact_id?: number;
            contact_name?: string;
        }[]
    ): void {
        items.forEach((profile) => {
            this.doAddNode({
                id: profile.id,
                type: 'individual',
                label: `${profile.first_name} ${profile.last_name}`,
                kind: null,
                permissions: null,
                company_fk_id: profile.company_fk_id,
                email: profile.email,
                contact_id: profile.contact_id || null,
                contact_name: profile.contact_name || '',
                contact_persons: null,
                hidden: false,
                subType: [],
                related_profile_and_group: false
            });
        });
    }
    initialisingContactClassesPicker(items: ContactClass[]): void {
        items.forEach((contactClass) => {
            this.doAddNode({
                id: contactClass.contact_class_id as number,
                type: 'contact_class',
                label: contactClass.title!,
                kind: null,
                permissions: null,
                company_fk_id: contactClass.company_fk_id,
                email: '',
                contact_id: null,
                contact_name: '',
                contact_persons: null,
                hidden: false,
                subType: [],
                related_profile_and_group: false
            });
        });
    }
    initialisingContactPersonsPicker(
        items: {
            id: number;
            label: string;
            permissions: string | null;
            company_fk_id: number;
            contact_persons:
                | {
                      contact_company_name: string;
                      contact_person_id: number;
                      contact_person_name: string;
                      contact_person_type: string;
                  }[]
                | null;
        }[]
    ): void {
        items.forEach((contactPerson) => {
            this.doAddNode({
                id: contactPerson.id,
                type: 'contact_person',
                label: contactPerson.label,
                kind: null,
                permissions: contactPerson.permissions ? JSON.parse(contactPerson.permissions) : null,
                company_fk_id: contactPerson.company_fk_id,
                email: '',
                contact_id: null,
                contact_name: '',
                contact_persons: contactPerson.contact_persons,
                hidden: false,
                subType: [],
                related_profile_and_group: false
            });
        });
    }

    getContactIcon(contactId: number): string {
        const contactFound = this.commonList.value.find((contact) => contact.id === contactId);

        if (typeof contactFound === 'undefined') {
            return 'person_outline';
        }
        switch (true) {
            case contactFound.related_profile_and_group:
                return 'domain';
            default:
                return 'person_outline';
        }
    }

    public getCompanyNameById(companyId: number): string {
        const findCompany = this.chosenSubCompanies.value.find((c) => c.id === companyId);
        if (findCompany) {
            return findCompany.title!;
        }
        return '';
    }

    async nextBatch() {
        if (this.showMore) {
            await this.loadData();
        }
    }

    contactCreate(event: MouseEvent, type: CONTACT_METATYPE | null) {
        event.stopPropagation();

        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            width: '90vw',
            autoFocus: false,
            data: {
                category: type,
                isCompany: this.contactMetatype === 'vendor'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contactCreated) => {
                this.contactCreateEmitter.next(contactCreated);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeSearch.next();
        this.unsubscribeSearch.complete();
        this.commonList.complete();
        this.isLoadedEmitter.complete();
        this.contactCreateEmitter.complete();
        this.toggleVisibilityEmitter.complete();
        this.contactCreateFromTextEmitter.complete();
    }
}
