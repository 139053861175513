<section class="bg-white">
    <!------- Filters ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Invoice Statements</h3>

        <i class="sp-spacer"></i>

        <app-date-range-picker
            class="mr-2 mt-2"
            [floatLabel]="'never'"
            (click)="$event.stopPropagation()"
            (dateEmitter)="setRange($event)"
            [maxDate]="range.controls.end_date.value"
            [minDate]="range.controls.start_date.value"
            [initialDateFrom]="initialDateFrom"
            [initialDateTo]="initialDateTo"
        ></app-date-range-picker>

        <app-company-compensation-combined-picker
            class="mr-2 mt-2"
            style="width: 180px"
            [availableTypes]="['contact', 'company_group']"
            [floatLabel]="'never'"
            [title]="'Customer / Group'"
            [only_compensation_groups]="false"
            [singleSelected]="true"
            [bold_style]="false"
            [nodesFA]="contactFilterCtrlArr"
        >
        </app-company-compensation-combined-picker>
    </header>

    <!------- Multi-selection toolbar ------->
    <app-multi-selection-toolbar
        [numberSelected]="selection.selected.length"
        toolbarMinWidth="800px"
        selectionName="Customer"
        *ngIf="isAnyCheckboxChecked()"
        [hideSelectionDivider]="true"
    >
        <button class="mat-button" (click)="sendSummaries()">
            <span style="width: 18px; height: 18px" class="material-symbols-outlined action-button-icon">send</span>
            Send {{ selection.selected.length > 1 ? 'Statements' : 'Statement' }}
        </button>
    </app-multi-selection-toolbar>

    <!------- Table of invoices ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <!-- Checkbox -->
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef class="w-5"></th>
                <td mat-cell *matCellDef="let element" class="w-5" (click)="$event.stopPropagation()">
                    <mat-checkbox
                        (change)="onCheckboxChange(element.contact_id, $event.checked)"
                        [checked]="selection.isSelected(element.contact_id)"
                        [disabled]="isCheckBoxDisabled(element.contact_id)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Customer</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 200px">
                        {{ element.contact_name }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="prev_balance_due">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Previous Balance</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <div *ngIf="element.prev_balance_due !== null && element.prev_invoice_count !== null">
                        <div>{{ element.prev_balance_due | currency: 'USD' : 'symbol' }}</div>
                        <div style="font-size: 0.8em">
                            {{ element.prev_invoice_count }}
                            {{ element.prev_invoice_count === 1 ? 'invoice' : 'invoices' }}
                        </div>
                    </div>
                    <div *ngIf="element.prev_balance_due === null || element.prev_invoice_count === null">
                        <div>No previous balances</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Invoice Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <div *ngIf="element.total_amount !== null && element.invoice_count !== null">
                        <div>{{ element.total_amount | currency: 'USD' : 'symbol' }}</div>
                        <div style="font-size: 0.8em">
                            {{ element.invoice_count }}
                            {{ element.invoice_count === 1 ? 'invoice' : 'invoices' }}
                        </div>
                    </div>
                    <div *ngIf="element.total_amount === null || element.invoice_count === null">
                        <div>No invoices</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="payments_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Payment Received</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <div *ngIf="element.payments_amount !== null && element.payment_received_count !== null">
                        <div>{{ element.payments_amount | currency: 'USD' : 'symbol' }}</div>
                        <div style="font-size: 0.8em">
                            {{ element.payment_received_count }}
                            {{ element.payment_received_count === 1 ? 'payment' : 'payments' }}
                        </div>
                    </div>
                    <div *ngIf="element.payments_amount === null || element.payment_received_count === null">
                        <div>No payments received</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="credit_notes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Credit Notes</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <div
                        *ngIf="
                            element.credit_notes !== null &&
                            element.credit_notes_count !== null &&
                            element.credit_notes_count > 0
                        "
                    >
                        <div>{{ element.credit_notes | currency: 'USD' : 'symbol' }}</div>
                        <div style="font-size: 0.8em">
                            {{ element.credit_notes_count }}
                            {{ element.credit_notes_count === 1 ? 'credit note' : 'credit notes' }}
                        </div>
                    </div>
                    <div *ngIf="element.credit_notes === null || element.credit_notes_count === null">
                        <div>No credit notes</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="last_sent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Last Sent</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.last_sent | date: 'longDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="balance_due">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Amount Due</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <div *ngIf="element.balance_due !== null">
                        <div>{{ element.balance_due | currency: 'USD' : 'symbol' }}</div>
                    </div>
                    <div *ngIf="element.balance_due === null">
                        <div>$0.00</div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no invoices.
        </div>
    </div>
</section>
