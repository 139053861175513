import {GenericEntity} from '../../../../entites/generic.entity';
import {MultipleTargetsDealsQueryModel} from '../../../deals/components/deal/common/deal.models';
import {CompensationPlanModel} from './compensation-plan.model';
import {DisbursementTemplateModel} from './disbursement-template.model';
import {SplitPlanModel} from './split-plan.model';
import {OverheadModel} from './overhead.model';
import {CommissionCategorizationModel} from './commission-categorization.model';
import {DealsSummary} from '../../../../models/deals-summary';
import {ChipNode} from './chip-node';
import {ConditionEntity} from '../../../../models/condition.entity';
import {DisbursementInstructionsEntity} from '../../../../entites/disbursement-instructions.entity';
import {CybercoEntity} from '@cyberco-nodejs/cyberco-entity';

export class ParticipantModel extends GenericEntity {
    chip_node: ChipNode | null = null;
    compensation_plan: CompensationPlanModel = new CompensationPlanModel();
}

export class RuleModel extends GenericEntity {
    rule_filters: MultipleTargetsDealsQueryModel = new MultipleTargetsDealsQueryModel();
    type: string | null = null;
    value: number | null = null;
    title: string | null = null;
    product_fk_id: number | null = null;
    public_title: string | null = null;
    operator: string | null = null;
    compensation_plan: CompensationPlanModel = new CompensationPlanModel();
    split_plan: SplitPlanModel = new SplitPlanModel();
    disbursement_template: DisbursementTemplateModel = new DisbursementTemplateModel();
    disbursement_instructions: DisbursementInstructionsEntity = new DisbursementInstructionsEntity();
    commission_categorization: CommissionCategorizationModel = new CommissionCategorizationModel();
    overhead: OverheadModel = new OverheadModel();
    participant: ParticipantModel = new ParticipantModel();
    is_final: boolean = true;
    is_prorated: boolean = true;
    is_new: boolean = false;
    summary: DealsSummary = new DealsSummary();
    and_conditions: ConditionEntity[] = [];

    static hydrate(input: any, output = new RuleModel(), props = this.helpers.props, transform = this.helpers.build) {
        if (input === null) {
            return null;
        }
        const rule: RuleModel = CybercoEntity.fabric.default('hydrate', input, output, props, transform);

        // need to uniquelize as duplicates are possible
        const un: Object = {};
        rule.and_conditions.forEach((condition) => {
            // @ts-ignore
            un[`${condition.type}.${condition.operator}.${condition.value}`] = condition;
        });

        rule.and_conditions = Object.values(un).filter((condition) => {
            return !(
                condition.type === rule.type &&
                condition.operator === rule.operator &&
                (condition.value === rule.value ||
                    ((condition.value === null || condition.value === '') &&
                        (<any>rule.value === null || <any>rule.value === '')))
            );
        });

        return rule;
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc, curr) => {
                    // @ts-ignore
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    rule_filters: (val: object, fabric: string) =>
                        Object.assign(new MultipleTargetsDealsQueryModel(), val),
                    split_plan: (val: object, fabric: string) => Object.assign(new SplitPlanModel(), val),
                    compensation_plan: (val: object, fabric: string) => Object.assign(new CompensationPlanModel(), val),
                    disbursement_template: (val: object, fabric: string) =>
                        Object.assign(new DisbursementTemplateModel(), val),
                    commission_categorization: (val: object, fabric: string) =>
                        Object.assign(new CommissionCategorizationModel(), val),
                    overhead: (val: object, fabric: string) => Object.assign(new OverheadModel(), val),
                    and_conditions: (val: object[], fabric: string) =>
                        // @ts-ignore
                        val.map((item) => GenericEntity.FABRIC(ConditionEntity)[fabric](item))
                }
            },
            GenericEntity.helpers
        );
    }

    static get type_SET() {
        return ConditionEntity.type_SET;
    }

    static get operator_SET() {
        return ConditionEntity.operator_SET;
    }
}
