import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {Deal} from '../../../../../../models/deal';
import {Subject} from 'rxjs';
import {IDealDeposit} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {NotificationsService} from 'angular2-notifications';
import {filter, takeUntil} from 'rxjs/operators';
import {DealApproveApiService} from '../../deal-approve.api.service';
import {DealFinancialApiService} from '../../deal-financial.api.service';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {IContactPartLink} from '@cyberco-nodejs/zipi-typings/contacts.typings';
import {DealProcessingService} from '../../deal-processing.service';
import {RecordClosingPayoutDialogComponent} from '../../../../modules/common-deals-module/components/process-closing-payouts/record-closing-payout-dialog/record-closing-payout-dialog.component';
import {IDataToBeProcessed} from '../../../../modules/remaining-payouts/types/remaining-payouts.types';
import {ClosingPayoutsApiService} from '../../../../modules/common-deals-module/services/closing-payouts.api-service';
import {DealPayoutsSource} from '../../../../../../services/sources/deal-payouts.source';

@Component({
    selector: 'app-deal-payouts-escrow',
    template: `
        <div class="m-3">
            <div
                class="d-flex justify-content-between"
                style="padding: 10px 10px 0 0; min-height: 65px;"
                *ngIf="!dealFormGroup.getRawValue().is_escrow_paid"
            >
                <!--NEW closing flow-->
                <div class="justify-content-left">
                    <div class="d-flex align-items-center">
                        <button
                            mat-stroked-button
                            color="primary"
                            class="ml-1 mr-2"
                            *ngIf="!deal.dual_deal_id"
                            (click)="openPayPopup('single')"
                            [disabled]="
                                !isEscrowPaidEnabledValue ||
                                !!(!deal!.disbursement_approved && escrowCompany) ||
                                (dealDeposit && dealDeposit.unreleased_balance >= 0.01) ||
                                (companyPayout && companyPayout.processing_status === 'locked') ||
                                (dualDealCompanyPayout && dualDealCompanyPayout.processing_status === 'locked')
                            "
                            rbac
                            [rule]="{deals__manage_payouts: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            {{
                                (companyPayout && companyPayout.processing_status === 'locked') ||
                                (dualDealCompanyPayout && dualDealCompanyPayout.processing_status === 'locked')
                                    ? 'Processing...'
                                    : 'Confirm Payment Received'
                            }}
                        </button>
                        <button
                            mat-stroked-button
                            color="primary"
                            *ngIf="deal.dual_deal_id && deal.dual_deal"
                            rbac
                            [rule]="{deals__manage_payouts: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            [disabled]="
                                !isEscrowPaidEnabledValue ||
                                !!(!deal!.disbursement_approved && escrowCompany) ||
                                (dealDeposit && dealDeposit.unreleased_balance >= 0.01) ||
                                (companyPayout && companyPayout.processing_status === 'locked') ||
                                (dualDealCompanyPayout && dualDealCompanyPayout.processing_status === 'locked')
                            "
                            [matMenuTriggerFor]="appMenu"
                            class="m1-3 pr-2 mr-2"
                        >
                            {{
                                (companyPayout && companyPayout.processing_status === 'locked') ||
                                (dualDealCompanyPayout && dualDealCompanyPayout.processing_status === 'locked')
                                    ? 'Processing...'
                                    : 'Confirm Payment Received'
                            }}
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #appMenu="matMenu">
                            <button mat-menu-item (click)="openPayPopup('single')">
                                Confirm for {{ dealTypesMap[deal!.client_type!] }}
                            </button>

                            <button
                                mat-menu-item
                                [disabled]="
                                    deal.dual_deal &&
                                    (!deal.dual_deal.disbursement_approved || deal.dual_deal.is_escrow_paid)
                                "
                                (click)="openPayPopup('dual')"
                            >
                                Confirm for Both Sides
                            </button>
                        </mat-menu>
                        <mat-icon
                            *ngIf="
                                (!deal!.disbursement_approved && (escrowCompany || !commissionPayer)) ||
                                (!isTransfersValidValue && deal!.disbursement_approved) ||
                                (dealDeposit && dealDeposit.unreleased_balance >= 0.01)
                            "
                            [id]="'closingItem'"
                            style="color: #FFAB00FF"
                        >
                            error
                        </mat-icon>
                        <app-custom-popover
                            *ngIf="
                                (!deal!.disbursement_approved && (escrowCompany || !commissionPayer)) ||
                                (!isTransfersValidValue && deal!.disbursement_approved) ||
                                (dealDeposit && dealDeposit.unreleased_balance >= 0.01)
                            "
                            [theme]="'dark'"
                            [position]="'right'"
                            [elementId]="'closingItem'"
                            [width]="325"
                        >
                            <div class="w-100 p-0" style="font-size: 16px">
                                <div
                                    class="d-flex my-3 p-0"
                                    *ngIf="!deal!.disbursement_approved && (escrowCompany || !commissionPayer)"
                                >
                                    <span>Please approve disbursements.</span>
                                </div>
                                <mat-divider
                                    style="border-color: rgba(255, 255, 255, 0.25)"
                                    *ngIf="
                                        !deal!.disbursement_approved &&
                                        (escrowCompany || !commissionPayer) &&
                                        dealDeposit &&
                                        dealDeposit.unreleased_balance >= 0.01
                                    "
                                    class="mb-2"
                                ></mat-divider>
                                <div
                                    *ngIf="dealDeposit && dealDeposit.unreleased_balance >= 0.01"
                                    class="d-flex my-3 p-0"
                                >
                                    <span>Deal has unreleased escrow deposits.</span>
                                </div>
                                <mat-divider
                                    style="border-color: rgba(255, 255, 255, 0.25)"
                                    *ngIf="
                                        (!isTransfersValidValue &&
                                            deal!.disbursement_approved &&
                                            dealDeposit &&
                                            dealDeposit.unreleased_balance >= 0.01) ||
                                        (!isTransfersValidValue &&
                                            deal!.disbursement_approved &&
                                            !deal!.disbursement_approved &&
                                            (escrowCompany || !commissionPayer))
                                    "
                                    class="mb-2"
                                ></mat-divider>
                                <div
                                    *ngIf="!isTransfersValidValue && deal!.disbursement_approved"
                                    class="d-flex my-3 p-0"
                                >
                                    <span>Payouts aren't valid.</span>
                                </div>
                            </div>
                        </app-custom-popover>
                    </div>
                </div>
                <span class="sp-spacer"></span>
            </div>
            <div
                class="d-flex justify-content-between"
                style="margin-top: 8px; padding: 10px 10px 20px 0;"
                *ngIf="deal!.is_escrow_paid"
            >
                <button mat-raised-button class="ml-1" (click)="revertEscrowPaid()" [disabled]="escrowButtonBlock">
                    Revert Payment
                </button>
            </div>
            <div>
                <div class="d-flex" style="font-size: 16px; margin: 12px 5px 6px; font-weight: bold">
                    {{ dealTypesMap[deal!.client_type!] }}
                    <div *ngIf="deal!.dual_deal_id">&nbsp;(dual agency)</div>
                    <span class="sp-spacer"></span>
                    <div>{{ deal!.company_calculation.commission.amount | currency }}</div>
                </div>
                <div *ngIf="companyTransfersArray.length > 0" style="margin-bottom: 15px">
                    <div style="font-size: 14px; margin: 5px;">Company Financials</div>
                    <div *ngFor="let transfer of companyTransfersArray; let i = index">
                        <ng-container>
                            <app-deal-payouts-card
                                [transferMoneyInGroup]="transfer"
                                [dealFormGroup]="dealFormGroup"
                                [lastUsedAccount]="lastUsedAccount"
                                [cardType]="cardType"
                                [brokerReleasesAmount]="brokerReleasesAmount"
                                [isInfoOnly]="false"
                            >
                            </app-deal-payouts-card>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="otherTransfersArray.length > 0">
                    <div style="font-size: 14px; margin: 5px 5px 5px 5px;">Other Financials</div>
                    <div *ngFor="let transfer of otherTransfersArray; let i = index">
                        <ng-container>
                            <app-deal-payouts-card
                                [transferMoneyInGroup]="transfer"
                                [dealFormGroup]="dealFormGroup"
                                [lastUsedAccount]="lastUsedAccount"
                                [cardType]="cardType"
                                [isInfoOnly]="false"
                            ></app-deal-payouts-card>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="companyTransfersArray.length === 0 && otherTransfersArray.length === 0" class="m-2">
                    <span style="color: #808080FF">No Payouts</span>
                </div>
            </div>
            <div class="mt-3" *ngIf="deal!.dual_deal_id && deal!.dual_deal">
                <div class="d-flex" style="font-size: 16px; margin: 5px; font-weight: bold">
                    {{ dealTypesMap[deal!.dual_deal!.client_type!] }}
                    <span class="sp-spacer"></span>
                    <div>{{ deal!.dual_deal.company_calculation?.commission?.amount | currency }}</div>
                </div>
                <div *ngIf="dualDealCompanyTransfersArray.length > 0" style="margin-bottom: 15px">
                    <div style="font-size: 14px; margin: 5px;">Company Financials</div>
                    <div *ngFor="let transfer of dualDealCompanyTransfersArray; let i = index">
                        <ng-container>
                            <app-deal-payouts-card
                                [transferMoneyInGroup]="transfer"
                                [dealFormGroup]="dualDealForm!"
                                [cardType]="cardType"
                                [brokerReleasesAmount]="dualDealBrokerReleasesAmount"
                                [isInfoOnly]="true"
                            ></app-deal-payouts-card>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="dualDealOtherTransfersArray.length > 0">
                    <div style="font-size: 14px; margin: 5px 5px 5px 5px;">Other Financials</div>
                    <div *ngFor="let transfer of dualDealOtherTransfersArray; let i = index">
                        <ng-container>
                            <app-deal-payouts-card
                                [transferMoneyInGroup]="transfer"
                                [dealFormGroup]="dualDealForm!"
                                [cardType]="cardType"
                                [isInfoOnly]="true"
                            ></app-deal-payouts-card>
                        </ng-container>
                    </div>
                </div>
                <div
                    *ngIf="dualDealCompanyTransfersArray.length === 0 && dualDealOtherTransfersArray.length === 0"
                    class="m-2"
                >
                    <span style="color: #808080FF">No Payouts</span>
                </div>
            </div>
        </div>
    `
})
export class EscrowPayoutsTabComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealFormGroup = new GenericFormGroup(new Deal());
    @Input() lastUsedAccount: any;
    @Input() brokerReleasesAmount: any;
    @Input() dualDealBrokerReleasesAmount: number | undefined;

    dualDealForm: GenericFormGroup<Deal> | undefined;

    // @ts-ignore
    dealTypesMap: {[key: string]: string} = {
        buyer: 'Buyer Side',
        seller: 'Listing Side',
        rental_tenant: 'Rental Tenant',
        rental_owner: 'Rental Owner',
        referral: 'Referral',
        broker_price_opinion: 'Broker Price Opinion'
    };

    transfersArray: FinancialTransferEntity[] = [];
    companyTransfersArray: FinancialTransferEntity[] = [];
    dualDealCompanyTransfersArray: FinancialTransferEntity[] = [];
    companyPayout: FinancialTransferEntity | null = null;
    dualDealCompanyPayout: FinancialTransferEntity | null = null;
    otherTransfersArray: FinancialTransferEntity[] = [];
    dualDealOtherTransfersArray: FinancialTransferEntity[] = [];

    commissionPayer: IContactPartLink | undefined;
    escrowCompany: IContactPartLink | undefined;

    cardType = 'escrow';

    dealDeposit: IDealDeposit | undefined;
    dualDealDeposit: IDealDeposit | undefined;
    escrowButtonBlock: boolean | undefined;

    deal: Deal | undefined;
    isEscrowPaidEnabledValue: boolean | undefined;
    isTransfersValidValue: boolean | undefined;

    constructor(
        public dialog: MatDialog,
        private ntfs: NotificationsService,
        protected dealApproval: DealApproveApiService,
        private dealFinancialApiService: DealFinancialApiService,
        protected dealProcessingService: DealProcessingService,
        private closingPayoutsService: ClosingPayoutsApiService,
        private dealPayoutsSource: DealPayoutsSource
    ) {
        this.escrowButtonBlock = false;
    }

    ngOnInit() {
        this.dealPayoutsSource.payoutEvents.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            if (
                this.dealFormGroup
                    .getRawValue()
                    .financial_transfers.some(
                        (item) => item.id === Number(data.financial_transfer_id) && item.type === 'company_payout'
                    )
            ) {
                if (data.status === 'completed') {
                    if (data.additional_entities) {
                        Object.keys(data.additional_entities).forEach((transferId) => {
                            const updatedFinTransfers = this.dealFormGroup
                                .getRawValue()
                                .financial_transfers.map((item) => {
                                    if (item.id === Number(transferId) && data.additional_entities) {
                                        for (const info of Object.values(
                                            data.additional_entities[Number(transferId)]
                                        )) {
                                            if (info.type === 'invoice') {
                                                item.connected__invoice_fk_id = info.entity.invoice_id;
                                                item.connected_invoice = info.entity;
                                            }
                                            if (info.type === 'bill') {
                                                item.connected__bill_fk_id = info.entity.bill_id;
                                                item.connected_bill = info.entity;
                                            }
                                        }
                                    }
                                    if (item.type === 'company_payout') {
                                        item.processing_status = 'open';
                                    }
                                    return item;
                                });
                            this.dealFormGroup.controls.financial_transfers!.patchValue(updatedFinTransfers, {
                                emitEvent: false
                            });
                        });
                    }
                    if (this.companyPayout) {
                        this.companyPayout.processing_status = 'open';
                    }

                    this.dealFormGroup.controls.is_escrow_paid!.patchValue(true, {emitEvent: false});

                    this.dealProcessingService.syncPayouts();
                    this.dealFormGroup.updateValueAndValidity();
                } else {
                    if (this.companyPayout) {
                        this.companyPayout.processing_status = data.status as any;
                    }
                }
            } else if (this.dealFormGroup.getRawValue().dual_deal) {
                const dual = this.dealFormGroup.getRawValue().dual_deal;
                if (
                    dual &&
                    dual.financial_transfers.some(
                        (item) => item.id === Number(data.financial_transfer_id) && item.type === 'company_payout'
                    )
                ) {
                    if (data.status === 'completed') {
                        if (data.additional_entities) {
                            Object.keys(data.additional_entities).forEach((transferId) => {
                                dual!.financial_transfers = dual!.financial_transfers.map((item) => {
                                    if (item.id === Number(transferId) && data.additional_entities) {
                                        for (const info of Object.values(
                                            data.additional_entities[Number(transferId)]
                                        )) {
                                            if (info.type === 'invoice') {
                                                item.connected__invoice_fk_id = info.entity.invoice_id;
                                                item.connected_invoice = info.entity;
                                            }
                                            if (info.type === 'bill') {
                                                item.connected__bill_fk_id = info.entity.bill_id;
                                                item.connected_bill = info.entity;
                                            }
                                        }
                                    }
                                    if (item.type === 'company_payout') {
                                        item.processing_status = 'open';
                                    }
                                    return item;
                                });
                                dual!.is_escrow_paid = true;
                                this.dealFormGroup.controls.dual_deal!.patchValue(dual, {emitEvent: false});
                            });
                        }
                        if (this.dualDealCompanyPayout) {
                            this.dualDealCompanyPayout.processing_status = 'open';
                        }
                    } else {
                        if (this.dualDealCompanyPayout) {
                            this.dualDealCompanyPayout.processing_status = data.status as any;
                        }
                    }
                }
            }
        });
        this.initParticipants();
        this.getTransfersArray();
        this.dealFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((change) => {
            this.deal = this.dealFormGroup.getRawValue();
            this.getTransfersArray();
        });

        if (this.dealFormGroup && this.dealFormGroup.getRawValue().id) {
            this.dealFinancialApiService
                .getDealDepositAmounts(this.dealFormGroup.getRawValue().id!)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((dealDeposit) => {
                    if (dealDeposit) {
                        this.dealDeposit = dealDeposit;
                    }
                });
        }
        if (this.dealFormGroup.controls.dual_deal_id!.value && this.dealFormGroup.controls.dual_deal!.value) {
            this.dealFinancialApiService
                .getDealDepositAmounts(this.dealFormGroup.controls.dual_deal_id!.value)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((dualDealDeposit) => {
                    if (dualDealDeposit) {
                        this.dualDealDeposit = dualDealDeposit;
                    }
                });
        }
        this.setupTab();
    }

    setupTab() {
        this.deal = this.dealFormGroup.getRawValue();
        this.isEscrowPaidEnabledValue = this.isEscrowPaidEnabled();
        this.isTransfersValidValue = this.isTransfersValid();
    }

    initParticipants() {
        const deal = this.dealFormGroup.getRawValue();
        if (deal.deal_participants.commission_payer) {
            this.commissionPayer = deal.deal_participants.commission_payer;
        }
        if (deal.deal_participants.escrow_agent) {
            this.escrowCompany = deal.deal_participants.escrow_agent;
        }
    }

    isEscrowPaidEnabled(): boolean {
        return !!(this.isTransfersValid() && this.commissionPayer);
    }

    isTransfersValid() {
        let isTransfersValid = true;
        this.transfersArray.forEach((transfer) => {
            if (
                isTransfersValid &&
                (transfer.amount === null ||
                    transfer.receiver === null ||
                    transfer.sender === null ||
                    transfer.invoice_fk_id)
            ) {
                isTransfersValid = false;
            }
        });
        return isTransfersValid;
    }

    openPayPopup(type: 'single' | 'dual') {
        let dialogRef = null;
        const payoutIds: Array<number> = [];
        let companyPayouts = [];
        let dualCompanyPayouts = [];
        if (this.companyTransfersArray.length === 0) {
            companyPayouts = this.deal!.financial_transfers.filter((transfer) => transfer.type === 'company_payout');
        } else {
            companyPayouts = this.companyTransfersArray;
        }
        if (companyPayouts && companyPayouts.length > 0) {
            companyPayouts.forEach((payout) => {
                if (payout.id) {
                    payoutIds.push(payout.id);
                }
            });
        }
        if (type === 'dual') {
            if (this.dualDealCompanyTransfersArray.length === 0 && this.deal && this.deal.dual_deal) {
                dualCompanyPayouts = this.deal.dual_deal.financial_transfers.filter(
                    (transfer) => transfer.type === 'company_payout'
                );
            } else {
                dualCompanyPayouts = this.dualDealCompanyTransfersArray;
            }
            if (dualCompanyPayouts && dualCompanyPayouts.length > 0) {
                dualCompanyPayouts.forEach((payout) => {
                    if (payout.id) {
                        payoutIds.push(payout.id);
                    }
                });
            }
        }

        dialogRef = this.dialog.open<RecordClosingPayoutDialogComponent, {payoutIds: Array<number>}>(
            RecordClosingPayoutDialogComponent,
            {
                minWidth: 917,
                maxWidth: 1272,
                maxHeight: '80vh',
                disableClose: true,
                panelClass: 'no-padding-dialog',
                autoFocus: false,
                data: {
                    payoutIds: payoutIds
                }
            }
        );

        if (dialogRef) {
            dialogRef
                .afterClosed()
                .pipe(
                    filter((c) => !!c),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((dataToProcess: any) => {
                    if (dataToProcess && dataToProcess.payouts && dataToProcess.payouts.length > 0) {
                        this.startProcessing(dataToProcess);
                    }
                });
        }
    }

    startProcessing(dataToProcess: IDataToBeProcessed) {
        this.closingPayoutsService
            .startPayoutsProcessing(dataToProcess)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (
                    response.status === 'success' &&
                    response.payouts_in_processing &&
                    response.payouts_in_processing.length > 0
                ) {
                    this.ntfs.success('Confirming initiated');
                }
            });
    }

    async revertEscrowPaid() {
        this.escrowButtonBlock = true;
        this.dealApproval
            .postUnmarkEscrowPaid(this.dealFormGroup.getRawValue().id!)
            .then((result) => {
                this.escrowButtonBlock = false;
                if (result) {
                    this.otherTransfersArray.forEach((transfer: any) => {
                        transfer.connected__invoice_fk_id = null;
                        transfer.connected__bill_fk_id = null;
                        transfer.connected_invoice = {};
                        transfer.connected_bill = null;
                        for (const trControl of this.dealFormGroup.controls.financial_transfers!.controls) {
                            if (trControl.controls.id!.value === transfer.id) {
                                trControl.controls.connected__invoice_fk_id!.setValue(null);
                                trControl.controls.connected__bill_fk_id!.setValue(null);
                                trControl.controls.connected_invoice!.reset({});
                                trControl.controls.connected_bill!.reset({});
                            }
                        }
                    });
                    this.companyTransfersArray.forEach((transfer: any) => {
                        transfer.connected__invoice_fk_id = null;
                        transfer.connected__bill_fk_id = null;
                        transfer.connected_invoice = {};
                        transfer.connected_bill = null;

                        for (const trControl of this.dealFormGroup.controls.financial_transfers!.controls) {
                            if (trControl.controls.id!.value === transfer.id) {
                                trControl.controls.connected__invoice_fk_id!.setValue(null);
                                trControl.controls.connected__bill_fk_id!.setValue(null);
                                trControl.controls.connected_invoice!.reset({});
                                trControl.controls.connected_bill!.reset({});
                            }
                        }
                    });
                    this.dealFormGroup.controls.financial_transfers!.controls.forEach((transferFG) => {
                        const ft = transferFG.getRawValue(FinancialTransferEntity);
                        if (ft.type === 'deduct_transfer') {
                            ft.connected__bill_fk_id = null;
                            ft.connected_bill = null;
                            transferFG.controls.connected__bill_fk_id!.setValue(null);
                            transferFG.controls.connected_bill!.reset({});
                        }
                    });
                    if (this.companyTransfersArray.length === 0) {
                        for (const trControl of this.dealFormGroup.controls.financial_transfers!.controls) {
                            if (trControl.controls.type!.value === 'company_payout') {
                                trControl.controls.connected__invoice_fk_id!.setValue(null);
                                trControl.controls.connected__bill_fk_id!.setValue(null);
                                trControl.controls.connected_invoice!.reset({});
                                trControl.controls.connected_bill!.reset({});
                            }
                        }
                    }
                    this.dealFormGroup.controls.is_escrow_paid!.patchValue(false, {emitEvent: false});

                    if (this.dealFormGroup.getRawValue().dual_deal) {
                        const dual = this.dealFormGroup.getRawValue().dual_deal;
                        this.dualDealCompanyTransfersArray.forEach((transfer: any) => {
                            transfer.connected__invoice_fk_id = null;
                            transfer.connected__bill_fk_id = null;
                            transfer.connected_invoice = {};
                            transfer.connected_bill = null;
                            dual!.financial_transfers = dual!.financial_transfers.map((item: any) => {
                                if (item.id === Number(transfer.financial_transfer_id)) {
                                    item.connected__invoice_fk_id = null;
                                    item.connected_invoice = {};
                                    item.connected__bill_fk_id = null;
                                    item.connected_bill = {};
                                }
                                return item;
                            });
                        });
                        this.dualDealForm!.controls.financial_transfers!['controls'].forEach((transferFG) => {
                            const ft = transferFG.getRawValue(FinancialTransferEntity);
                            if (ft.type === 'deduct_transfer') {
                                ft.connected__bill_fk_id = null;
                                ft.connected_bill = null;
                                transferFG.controls.connected__bill_fk_id!.setValue(null);
                                transferFG.controls.connected_bill!.reset({});
                            }
                        });
                        if (this.dualDealCompanyTransfersArray.length === 0) {
                            for (const tr of dual!.financial_transfers) {
                                if (tr.type === 'company_payout') {
                                    tr.connected__invoice_fk_id = null;
                                    tr.connected__bill_fk_id = null;
                                    tr.connected_invoice = null;
                                    tr.connected_bill = null;
                                }
                            }
                        }
                        dual!.is_escrow_paid = false;
                        this.dealFormGroup.controls.dual_deal!.patchValue(dual);
                    }

                    this.dealProcessingService.syncPayouts();
                    this.dealFormGroup.updateValueAndValidity();
                    this.ntfs.success(`Revert Escrow paid successfully.`);
                }
            })
            .catch(() => {});
    }

    getTransfersArray() {
        this.companyPayout = null;
        this.dualDealCompanyPayout = null;
        this.companyTransfersArray = [];
        this.otherTransfersArray = [];
        this.dualDealCompanyTransfersArray = [];
        this.dualDealOtherTransfersArray = [];
        const transfers = this.dealFormGroup.getRawValue().escrow_payouts;
        this.transfersArray = transfers.map((transfer) => {
            if (!transfer.sender && this.commissionPayer) {
                transfer.sender = this.commissionPayer;
            }
            return transfer;
        });
        this.transfersArray.forEach((transfer) => {
            if (this.isCompanyPayout(transfer)) {
                this.companyTransfersArray.push(transfer);
            } else {
                this.otherTransfersArray.push(transfer);
            }
        });

        if (this.companyTransfersArray.length === 0) {
            this.companyPayout = transfers.filter((transfer) => transfer.type === 'company_payout')[0];
        } else {
            this.companyPayout = this.companyTransfersArray[0];
        }

        // use this to show company payout with zero amount on the escrow tab before closing process
        // if (this.companyTransfersArray.length === 0) {
        //     this.companyTransfersArray = this.dealFormGroup.getRawValue().financial_transfers.filter(transfer => transfer.type === 'company_payout');
        // }

        this.companyTransfersArray.sort(compareAmount);
        this.otherTransfersArray.sort(compareAmount);

        if (this.dealFormGroup.getRawValue().dual_deal_id && this.dealFormGroup.getRawValue().dual_deal) {
            const dualDeal = this.dealFormGroup.getRawValue().dual_deal;
            if (dualDeal) {
                this.dualDealForm = new GenericFormGroup(dualDeal);
                const dualTransfers = this.dealFormGroup.getRawValue().dual_deal!.escrow_payouts;
                dualTransfers.forEach((transfer) => {
                    if (this.isCompanyPayout(transfer)) {
                        this.dualDealCompanyTransfersArray.push(transfer);
                    } else {
                        this.dualDealOtherTransfersArray.push(transfer);
                    }
                });
                this.dualDealCompanyTransfersArray.sort(compareAmount);
                this.dualDealOtherTransfersArray.sort(compareAmount);

                if (this.dualDealCompanyTransfersArray.length === 0) {
                    this.dualDealCompanyPayout = dualDeal.financial_transfers.filter(
                        (transfer) => transfer.type === 'company_payout'
                    )[0];
                } else {
                    this.dualDealCompanyPayout = this.dualDealCompanyTransfersArray[0];
                }
            }
        }
    }

    isCompanyPayout(transfer: FinancialTransferEntity) {
        if (
            (transfer.receiver &&
                transfer.receiver.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id) ||
            (transfer.sender &&
                transfer.sender.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id)
        ) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

function compareAmount(a: FinancialTransferEntity, b: FinancialTransferEntity) {
    if (a.amount! > b.amount!) {
        return -1;
    }
    if (a.amount! < b.amount!) {
        return 1;
    }
    return 0;
}
