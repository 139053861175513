import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {IInvoice, IBill, IPayRequestObject, IInvoiceLog, IContact} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../../../models/scroll-data';
import {IQBMappingResponse} from '../../../modules/finance/types/financial.types';

@Injectable()
export class BillsService {
    private url = '/finance/bills/';
    private qbAddonUrl = '/quickbooks-addon/';

    constructor(protected requester: ServiceRequester) {}

    getBills(params: any): Observable<{result: IBill[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getBillById(billId: number): Observable<IBill> {
        return this.requester.makeMsCall$(this.url + `${billId}`, 'GET', 'shipp');
    }

    getBillsByReceiverContactId(
        contactId: number,
        data: IScrollData
    ): Observable<{_meta: {total: number}; result: IBill[]}> {
        return this.requester.makeMsCall$(this.url + `${contactId}/receiver`, 'GET', 'shipp', data);
    }

    getMySourceDocumentsForBill(params: any = {}): Observable<{result: IInvoice[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url + `forapproveinvoices`, 'GET', 'shipp', params);
    }

    getBillForApproveById(billId: number): Observable<IBill> {
        return this.requester.makeMsCall$(this.url + `forapproveinvoices/${billId}`, 'GET', 'shipp');
    }

    getBillHistory(billId: number): Observable<IInvoiceLog[]> {
        return this.requester.makeMsCall$(this.url + `${billId}/log`, 'GET', 'shipp');
    }

    getBillsForCreditsApply(contactId: number): Observable<IBill[]> {
        return this.requester.makeMsCall$(this.url + `${contactId}/creditsapply`, 'GET', 'shipp');
    }

    editBill(
        billId: number,
        data: {
            bill: IBill;
            bill_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
        }
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}`, 'PUT', 'shipp', data);
    }

    createBillAndSend(data: {
        bill: IBill;
        bill_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }): Observable<IBill> {
        return this.requester.makeMsCall$(this.url + 'send', 'POST', 'shipp', data);
    }

    createBillAsDraft(data: {
        bill: IBill;
        bill_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }): Observable<IBill> {
        return this.requester.makeMsCall$(this.url + 'draft', 'POST', 'shipp', data);
    }

    createBillAsOpen(data: {
        bill: IBill;
        bill_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }): Observable<IBill> {
        return this.requester.makeMsCall$(this.url + 'open', 'POST', 'shipp', data);
    }

    markAsOpen(billId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}/asopen`, 'GET', 'shipp');
    }

    markAsVoid(billId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}/mark-as-void`, 'GET', 'shipp');
    }

    payBill(billId: number, payData: IPayRequestObject) {
        return this.requester.makeMsCall$(this.url + `${billId}`, 'POST', 'shipp', payData);
    }

    approveBills(data: any): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `approve/bulk`, 'POST', 'shipp', data);
    }

    linkBills(data: any): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `link/bulk`, 'POST', 'shipp', data);
    }

    archiveBill(billId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}/archive`, 'POST', 'shipp');
    }

    createVendorContactByBill(billId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}/vendorcontact`, 'POST', 'shipp');
    }

    associateVendorContactByBill(billId: number, contactId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}/vendorcontact/${contactId}`, 'POST', 'shipp');
    }

    createVendorContactByInvoice(invoiceId: number): Observable<IContact> {
        return this.requester.makeMsCall$(this.url + `${invoiceId}/foraprove/vendorcontact`, 'POST', 'shipp');
    }

    associateVendorContactByInvoice(invoiceId: number, contactId: number): Observable<IContact> {
        return this.requester.makeMsCall$(
            this.url + `${invoiceId}/foraprove/vendorcontact/${contactId}`,
            'POST',
            'shipp'
        );
    }

    setLinkedStatus(billId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}/linked`, 'PUT', 'shipp');
    }

    disconnectBill(billId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `disconnect/${billId}`, 'DELETE', 'shipp');
    }

    deleteBill(billId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}`, 'DELETE', 'shipp');
    }

    bulkDeleteBills(params: any): Observable<{
        deleted_bills: number;
        not_deleted_bills: number;
    }> {
        return this.requester.makeMsCall$(this.url + 'bulk/delete', 'PUT', 'shipp', params);
    }

    bulkDeleteAllBills(params: any): Observable<{
        deleted_bills: number;
        not_deleted_bills: number;
    }> {
        return this.requester.makeMsCall$(this.url + 'bulk/delete/all', 'PUT', 'shipp', params);
    }

    applyFromCredits(
        billId: number,
        data: {
            vendor_credits: Array<{vendor_credit_id: number; amount_to_apply: number}>;
            payments_made: Array<{payment_made_id: number; amount_to_apply: number}>;
        }
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}/apply`, 'PUT', 'shipp', data);
    }

    syncBillWithQB(billId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${billId}` + '/qb-sync', 'GET', 'shipp');
    }

    loadQBSync(billId: number): Observable<IQBMappingResponse> {
        return this.requester.makeMsCall$(this.qbAddonUrl + `bill/${billId}/mapping`, 'GET', 'shipp');
    }
}
