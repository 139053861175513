import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {firstValueFrom, Observable} from 'rxjs';
import {IBill, IInvoice, IInvoiceLog, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../../../models/scroll-data';
import {IQBMappingResponse} from '../../../modules/finance/types/financial.types';

@Injectable()
export class InvoicesService {
    private url = '/finance/invoices/';
    private qbAddonUrl = '/quickbooks-addon/';

    constructor(public requester: ServiceRequester) {}

    getPageInvoices(params: any = {}): Observable<{_meta: {total: number}; result: IInvoice[]}> {
        return this.requester.makeMsCall$(this.url + 'page', 'GET', 'shipp', params);
    }

    getInvoices(params: any = {}): Observable<IInvoice[]> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getInvoiceById(invoiceId: number): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url + `${invoiceId}`, 'GET', 'shipp');
    }

    getInvoiceForEditById(invoiceId: number): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url + `edit/${invoiceId}`, 'GET', 'shipp');
    }

    getInvoiceForViewById(invoiceId: number): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url + `view/${invoiceId}`, 'GET', 'shipp');
    }

    getInvoiceBySenderContactId(
        contactId: number,
        data: IScrollData
    ): Observable<{_meta: {total: number}; result: IInvoice[]}> {
        return this.requester.makeMsCall$(this.url + `${contactId}/sender`, 'GET', 'shipp', data);
    }

    getInvoiceBySenderContactIdCount(contactId: number): Observable<{count: number}> {
        return this.requester.makeMsCall$(this.url + `${contactId}/sender-count`, 'GET', 'shipp');
    }

    getInvoicesForCreditsApply(contactId: number): Observable<IInvoice[]> {
        return this.requester.makeMsCall$(this.url + `${contactId}/creditsapply`, 'GET', 'shipp');
    }

    getInvoiceMethods(methodsIds: Array<number>): Observable<IPaymentMethod[]> {
        return this.requester.makeMsCall$(`${this.url}methods`, 'POST', 'shipp', methodsIds);
    }

    getDeductedInvoicesByContactId(contactId: number): Observable<IInvoice[]> {
        return this.requester.makeMsCall$(this.url + `deducted/${contactId}`, 'GET', 'shipp');
    }

    getInvoiceHistory(invoiceId: number): Observable<IInvoiceLog[]> {
        return this.requester.makeMsCall$(this.url + `${invoiceId}/log`, 'GET', 'shipp');
    }

    // TODO move on merge conflict
    getInvoiceForApproveById(invoiceId: number): Observable<{invoice: IInvoice; zipi_fin_customer_ref: string}> {
        return this.requester.makeMsCall$(this.url + `forapprovebills/${invoiceId}`, 'GET', 'shipp');
    }

    getMySourceDocumentsForInvoice(params: any = {}): Observable<{_meta: {total: number}; result: IBill[]}> {
        return this.requester.makeMsCall$(this.url + `forapprovebills`, 'GET', 'shipp', params);
    }

    createInvoices(data: IInvoice): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    createInvoiceAndSend(data: {
        invoice: IInvoice;
        invoice_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url + 'send', 'POST', 'shipp', data);
    }

    createInvoiceAsDraft(data: {
        invoice: IInvoice;
        invoice_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url + 'draft', 'POST', 'shipp', data);
    }

    createInvoiceAsOpen(data: {
        invoice: IInvoice;
        invoice_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url + 'open', 'POST', 'shipp', data);
    }

    updateInvoice(
        invoiceId: number,
        data: {
            invoice: IInvoice;
            invoice_association: {
                to_create: Array<{[key: string]: any}>;
                to_delete: Array<{[key: string]: any}>;
            } | null;
        }
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${invoiceId}`, 'PUT', 'shipp', data);
    }

    updateAndChargeCustomer(
        invoiceId: number,
        data: {note: string; amount: number; paid_by__payment_method_fk_id: number}
    ): Observable<{error: string}> {
        return this.requester.makeMsCall$(this.url + `${invoiceId}/charge`, 'PUT', 'shipp', data);
    }

    approveInvoices(data: any): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `approve/bulk`, 'POST', 'shipp', data);
    }

    linkInvoices(data: any): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `link/bulk`, 'POST', 'shipp', data);
    }

    archiveInvoice(invoice_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${invoice_id}/archive`, 'POST', 'shipp');
    }

    applyFromCredits(
        invoice_id: number,
        data: {
            customer_credits: Array<{credit_note_id: number; amount_to_apply: number}>;
            payments_received: Array<{payment_received_id: number; amount_to_apply: number}>;
        }
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${invoice_id}/apply`, 'PUT', 'shipp', data);
    }

    markAsOpen(invoice_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${invoice_id}/asopen`, 'GET', 'shipp');
    }

    sendInvoiceMail(invoice_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${invoice_id}/resend`, 'GET', 'shipp');
    }

    markAsVoid(invoice_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${invoice_id}/mark-as-void`, 'GET', 'shipp');
    }

    recheckInvoicePaymentsStatuses(invoice_id: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `${invoice_id}/recheck`, 'GET', 'shipp');
    }

    disconnectInvoice(invoiceId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `disconnect/${invoiceId}`, 'DELETE', 'shipp');
    }

    deleteInvoice(invoiceId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${invoiceId}`, 'DELETE', 'shipp');
    }

    bulkDeleteInvoices(invoiceIds: number[]): Observable<{
        deleted_invoices: number;
        not_deleted_invoices: number;
    }> {
        return this.requester.makeMsCall$(this.url + 'bulk/delete', 'PUT', 'shipp', invoiceIds);
    }

    dewnloadInvoicePdf(data: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + data + '/pdf', 'GET', 'shipp');
    }

    syncInvoiceWithQB(invoiceId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${invoiceId}` + '/qb-sync', 'GET', 'shipp');
    }

    loadQBSync(invoiceId: number): Observable<IQBMappingResponse> {
        return this.requester.makeMsCall$(this.qbAddonUrl + `invoice/${invoiceId}/mapping`, 'GET', 'shipp');
    }

    getInvoiceStatements(
        start: number,
        end: number,
        sort_column: string | undefined,
        sort_direction: string | undefined,
        limit: number = 50,
        offset: number = 0,
        contactIds: number[] = [],
        groupIds: number[] = []
    ): Observable<{_meta: {total: number}; result: any[]}> {
        return this.requester.makeMsCall$('/finance/invoice-statement', 'GET', 'shipp', {
            start: start,
            end: end,
            limit: limit,
            offset: offset,
            sort_column: sort_column,
            sort_direction: sort_direction,
            contactIds: contactIds.join(','),
            groupIds: groupIds.join(',')
        });
    }

    async sendInvoiceStatements(data: {contactIds: number[]; startDate: number; endDate: number}): Promise<boolean> {
        return await firstValueFrom(
            this.requester.makeMsCall$('/finance/invoice-statement/send', 'POST', 'shipp', data)
        );
    }
}
