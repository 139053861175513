import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MessagesService} from './messages.service';
// import {ChatComponent} from './components/chat/chat.component';
import {SharedModule} from '../shared/shared.module';
import {AngularFireDatabase} from '@angular/fire/compat/database';
// import { ReminderComponent } from './components/reminder/reminder.component';
import {ShippNotificationsComponent} from './components/notifications/shipp-notifications.component';
import {UserNotificationModule} from '../user-notification/user-notification.module';
import {ProfileUploadNotificationItemComponent} from './components/profile-upload-notification-item/profile-upload-notification-item.component';
import {NotificationBadgeComponent} from './components/notification-badge/notification-badge.component';
import {ReportPdfFilesModalComponent} from './components/report-pdf-files-modal/report-pdf-files-modal.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        // ChatComponent,
        // ReminderComponent,
        ShippNotificationsComponent,
        ProfileUploadNotificationItemComponent,
        NotificationBadgeComponent,
        ReportPdfFilesModalComponent
    ],
    imports: [SharedModule, UserNotificationModule, RouterModule],
    providers: [MessagesService, AngularFireDatabase],
    exports: [
        // ChatComponent,
        // ReminderComponent,
        ShippNotificationsComponent,
        NotificationBadgeComponent
    ],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MessagesModule {}
