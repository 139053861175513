import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {MatExpansionPanel} from '@angular/material/expansion';
import {RuleModel} from '../models/rule.model';
import {RuleComponent} from './rule.component';
import {debounce, takeUntil} from 'rxjs/operators';
import {Subject, timer} from 'rxjs';
import {FinancialElementModel} from '../models/financial-element.model';
import {FinancialTransferEntity} from '../models/financial-transfer.entity';
import {MultipleTargetsDealsQueryModel} from '../../../deals/components/deal/common/deal.models';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';
import {ConditionEntity} from '../../../../models/condition.entity';
import {CalculationSettingsService} from '../../../../services/calculation-settings.service';

@Component({
    selector: 'app-company-compensation-financial-element',
    styles: [
        `
            .mat-expanded {
                background-color: lightgray;
            }

            .intradeal-icon {
                font-size: 18px;
                color: grey;
                width: 18px;
                height: 18px;
            }

            ::ng-deep .tooltip {
                white-space: pre-line;
            }
        `
    ],
    template: `
        <mat-expansion-panel
            #matExpansionPanel
            hideToggle
            style="margin-bottom:16px"
            [disabled]="!financialElementFG?.controls?.type?.value"
        >
            <mat-expansion-panel-header
                (click)="expandPanel(matExpansionPanel, $event)"
                class="expandable-height expansion-indicator"
                [collapsedHeight]="'90px'"
                [expandedHeight]="'90px'"
            >
                <mat-panel-title style="align-items: center">
                    <mat-form-field style="width:100%">
                        <input
                            matInput
                            placeholder="Element Title"
                            (keydown)="handleSpacebar($event)"
                            (click)="setDefaultTitle()"
                            style="padding-right: 30px;"
                            [formControl]="financialElementFG.controls.title!"
                        />
                        <mat-hint
                            *ngIf="financialElementFG?.controls?.public_title?.value"
                            style="color: black;font-size: 12px;display: flex;"
                        >
                            {{ financialElementFG?.controls?.public_title?.value }}
                            <mat-icon
                                class="ml-1"
                                style="font-size: 14px;"
                                matTooltip="This title is visible to ALL Assigned Users (ie Selecting Cap Widget Parameters)."
                            >
                                visibility
                            </mat-icon>
                        </mat-hint>
                        <app-edit-title
                            [titleFormControl]="financialElementFG.controls.public_title!"
                            [disabled]="disabled"
                        ></app-edit-title>
                    </mat-form-field>
                </mat-panel-title>
                <mat-panel-description style="align-items: center; justify-content: space-between">
                    <mat-form-field>
                        <mat-select
                            placeholder="Element"
                            [formControl]="financialElementFG.controls.type!"
                            (selectionChange)="disableOnChange()"
                        >
                            <ng-container *ngFor="let type of object_keys(type_LABELS)">
                                <mat-option
                                    *ngIf="type !== 'compensation_expense' || compensationExpenseEnabledFlag"
                                    [value]="type"
                                >
                                    {{ type_LABELS[type] }}
                                    <mat-icon
                                        class="material-icons-outlined intradeal-icon"
                                        matTooltip="If the element conditions dictate, it will split and calculate applicable financial rules within a single deal."
                                        *ngIf="
                                            intradealable[type] && type !== financialElementFG?.controls?.type?.value
                                        "
                                    >
                                        assessment
                                    </mat-icon>
                                </mat-option>
                            </ng-container>
                            <!--                            <mat-optgroup label="Templates">-->
                            <!--                                <mat-option *ngFor="let type of object_keys(type_LABELS_templates)" [value]="type">-->
                            <!--                                    {{ type_LABELS[type] }}-->
                            <!--                                </mat-option>-->
                            <!--                            </mat-optgroup>-->
                        </mat-select>
                        <mat-icon
                            matSuffix
                            class="material-icons-outlined intradeal-icon"
                            matTooltip="If the element conditions dictate, it will split and calculate applicable financial rules within a single deal."
                            *ngIf="intradealable[financialElementFG?.controls?.type?.value]"
                        >
                            assessment
                        </mat-icon>
                    </mat-form-field>
                    <div style="display: flex; flex-direction: column; align-items: flex-start;">
                        <mat-slide-toggle
                            *ngIf="enforcedEnabledFlag && !financialElementFG?.controls?.is_template?.value"
                            (click)="$event.stopPropagation()"
                            [formControl]="financialElementFG.controls.is_enforced!"
                            [matTooltipDisabled]="
                                intradealable[financialElementFG?.controls?.type?.value] &&
                                !(
                                    financialElementFG?.controls?.is_enforced?.value &&
                                    financialElementFG?.controls?.is_enforced?.disabled
                                )
                            "
                            [matTooltip]="
                                type_LABELS[financialElementFG?.controls?.type?.value] +
                                ' element can be unenforced only if it has 1 rule without conditions'
                            "
                            >Enforced
                        </mat-slide-toggle>
                        <div
                            *ngIf="
                                !financialElementFG?.controls?.is_template?.value &&
                                financialElementFG?.controls?.is_enforced?.value &&
                                (financialElementFG?.controls?.type?.value === FINANCIAL_ELEMENT.type_set.agent ||
                                    financialElementFG?.controls?.type?.value === FINANCIAL_ELEMENT.type_set.royalty ||
                                    financialElementFG?.controls?.type?.value ===
                                        FINANCIAL_ELEMENT.type_set.user_royalty)
                            "
                            style="margin-top: 10px; display: flex; flex-direction: row; align-items: center;"
                        >
                            <mat-slide-toggle
                                [formControl]="financialElementFG.controls.commonRule!.controls.is_prorated!"
                            >
                                Prorated
                            </mat-slide-toggle>
                            <mat-icon
                                class="material-icons-outlined ml-2"
                                style="color: rgb(113, 113, 113)"
                                matTooltip="{{ tooltipText() }}"
                                matTooltipClass="tooltip"
                                matTooltipPosition="right"
                            >
                                info
                            </mat-icon>
                        </div>
                    </div>
                    <span style="display: flex; justify-content: flex-end">
                        <button
                            class="exp-button"
                            *ngIf="matExpansionPanel.expanded === false"
                            [disabled]="disabled"
                            mat-icon-button
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button
                            class="exp-button"
                            *ngIf="matExpansionPanel.expanded === true"
                            [disabled]="disabled"
                            mat-icon-button
                        >
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <button
                            [disabled]="disabled"
                            mat-icon-button
                            class="mat-warn"
                            (click)="delete.emit(); stop_propagation($event)"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                        <div
                            style="display: flex; flex-direction: column; margin-top: -20px; margin-bottom: -20px; justify-content: space-around;"
                        >
                            <button
                                [disabled]="disabled"
                                mat-icon-button
                                *ngIf="show_up"
                                (click)="up.emit(); stop_propagation($event)"
                            >
                                <mat-icon>arrow_upward</mat-icon>
                            </button>
                            <button
                                [disabled]="disabled"
                                mat-icon-button
                                *ngIf="show_down"
                                (click)="down.emit(); stop_propagation($event)"
                            >
                                <mat-icon>arrow_downward</mat-icon>
                            </button>
                        </div>
                    </span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div style="display:flex; flex-direction: row">
                <div style="display:flex; flex-direction: column; max-width: 50%;">
                    <div
                        style="display: flex; flex-direction: row; font-weight: bold; align-items: center; font-size: 16px; margin-bottom: 10px"
                        *ngIf="
                            (financialElementFG?.controls?.is_template?.value &&
                                !intradealable[financialElementFG?.controls?.type?.value]) ||
                            (!financialElementFG?.controls?.is_template?.value &&
                                (financialElementFG?.controls?.is_enforced?.value ||
                                    !intradealable[financialElementFG?.controls?.type?.value]))
                        "
                    >
                        Conditions
                        <button
                            [disabled]="disabled"
                            mat-icon-button
                            *ngIf="
                                showConditions === false &&
                                !financialElementFG?.controls?.commonRule?.controls?.type?.value &&
                                !financialElementFG?.controls?.commonRule?.controls?.operator?.value
                            "
                            (click)="showConditions = true"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                        <button
                            [disabled]="disabled"
                            mat-icon-button
                            *ngIf="
                                showConditions === true &&
                                !financialElementFG?.controls?.commonRule?.controls?.type?.value &&
                                !financialElementFG?.controls?.commonRule?.controls?.operator?.value
                            "
                            (click)="showConditions = false"
                        >
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                    <div
                        style="display: flex; flex-direction: row; flex-wrap: wrap;"
                        *ngIf="
                            ((financialElementFG?.controls?.is_enforced?.value &&
                                !financialElementFG?.controls?.is_template?.value) ||
                                !intradealable[financialElementFG?.controls?.type?.value]) &&
                            (financialElementFG?.controls?.commonRule?.controls?.type?.value ||
                                financialElementFG?.controls?.commonRule?.controls?.operator?.value ||
                                showConditions)
                        "
                    >
                        <mat-form-field style="margin-right: 4px">
                            <mat-select
                                placeholder="Condition Type"
                                [formControl]="financialElementFG.controls.commonRule!.controls.type!"
                            >
                                <mat-option [value]="null"> None </mat-option>
                                <mat-option
                                    *ngFor="
                                        let type of object_keys(
                                            ruleConditionType_LABELS[financialElementFG?.controls?.type?.value]
                                                ? ruleConditionType_LABELS[financialElementFG?.controls?.type?.value]
                                                : ruleConditionType_LABELS['default']
                                        )
                                    "
                                    [value]="type"
                                >
                                    {{ ruleConditionType_LABELS['default'][type] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <app-product-service-selector
                            class="four-width"
                            *ngIf="
                                financialElementFG.controls.commonRule!.controls.type!.value ===
                                    'total_product_income' ||
                                financialElementFG.controls.commonRule!.controls.type!.value === 'total_product_expense'
                            "
                            [placeholder]="'Product/Service'"
                            [productServiceControl]="financialElementFG.controls.commonRule!.controls.product_fk_id!"
                            [validators]="null"
                            [allowedProductsIdsOnly]="true"
                            [allowedProductsIdsToSelect]="capableMetricsByProductIds"
                            style="display: inline-block; position: relative; text-align: left; margin-right: 4px;"
                        >
                        </app-product-service-selector>

                        <mat-form-field style="margin-right: 4px">
                            <mat-select
                                placeholder="Condition Operator"
                                [formControl]="financialElementFG.controls.commonRule!.controls.operator!"
                            >
                                <mat-option
                                    *ngFor="let operator of object_keys(ruleConditionOperator_LABELS)"
                                    [value]="operator"
                                >
                                    {{ ruleConditionOperator_LABELS[operator] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <app-company-compensation-filters
                    *ngIf="
                        ((financialElementFG?.controls?.is_enforced?.value &&
                            !financialElementFG?.controls?.is_template?.value) ||
                            !intradealable[financialElementFG?.controls?.type?.value]) &&
                        (financialElementFG?.controls?.commonRule?.controls?.type?.value ||
                            financialElementFG?.controls?.commonRule?.controls?.operator?.value)
                    "
                    [isDefaultConditionParameters]="isDefaultConditionParameters"
                    [label]="isDefaultConditionParameters ? 'Condition Parameters' : 'Override Condition Parameters'"
                    [dropdown]="true"
                    [filtersForm]="financialElementFG?.controls?.commonRule?.controls?.rule_filters!"
                    [disabled]="disabled"
                    style="margin-left: 20px"
                ></app-company-compensation-filters>
            </div>

            <mat-card *ngIf="financialElementFG?.controls?.type?.value === FINANCIAL_ELEMENT.type_set.agent">
                <span>{{ type_LABELS[financialElementFG?.controls?.type?.value] }}</span>
                <app-company-ancillary
                    [type]="FINANCIAL_TRANSFER.type_SET.agent_split"
                    [hideIncomeTitle]="true"
                    [hideExpenseTitle]="true"
                    [hideAmount]="true"
                    [hideExpense]="false"
                    [defaultSenderWildcardId]="3"
                    [hideDisbursementInstructionsFlag]="false"
                    [hidePayAtEscrowFlag]="false"
                    [limitIncomes]="1"
                    [limitExpenses]="1"
                    [hideSender]="true"
                    [hideReceiver]="true"
                    [compensationType]="financialElementFG?.controls?.type?.value"
                    [disbursementTemplateFG]="financialElementFG?.controls?.commonRule?.controls?.disbursement_template"
                    [disabled]="disabled"
                ></app-company-ancillary>
            </mat-card>

            <mat-card *ngIf="financialElementFG?.controls?.type?.value === FINANCIAL_ELEMENT.type_set.user_royalty">
                <span>{{ type_LABELS[financialElementFG?.controls?.type?.value] }}</span>
                <app-company-ancillary
                    [type]="FINANCIAL_TRANSFER.type_SET.transfer"
                    [hideExpenseTitle]="true"
                    [hideIncome]="true"
                    [hideReceiver]="true"
                    [hideAmount]="true"
                    [limitExpenses]="1"
                    [compensationType]="financialElementFG?.controls?.type?.value"
                    [hidePayAtEscrowFlag]="false"
                    [hideDisbursementInstructionsFlag]="false"
                    [disbursementTemplateFG]="financialElementFG?.controls?.commonRule?.controls?.disbursement_template"
                    [disabled]="disabled"
                ></app-company-ancillary>
            </mat-card>

            <mat-card *ngIf="financialElementFG?.controls?.type?.value === FINANCIAL_ELEMENT.type_set.royalty">
                <span>{{ type_LABELS[financialElementFG?.controls?.type?.value!] }}</span>
                <app-company-ancillary
                    [type]="FINANCIAL_TRANSFER.type_SET.transfer"
                    [hideExpenseTitle]="true"
                    [hideIncome]="true"
                    [hideReceiver]="true"
                    [hideAmount]="true"
                    [limitExpenses]="1"
                    [compensationType]="financialElementFG?.controls?.type?.value!"
                    [hidePayAtEscrowFlag]="false"
                    [hideDisbursementInstructionsFlag]="false"
                    [disbursementTemplateFG]="financialElementFG?.controls?.commonRule?.controls?.disbursement_template"
                    [disabled]="disabled"
                ></app-company-ancillary>
            </mat-card>

            <h3 class="label" style="margin: 10px 0;">
                Rules
                <button
                    [disabled]="disabled"
                    mat-icon-button
                    *ngIf="
                        !intradealable[financialElementFG?.controls?.type?.value] ||
                        (intradealable[financialElementFG?.controls?.type?.value] &&
                            financialElementFG?.controls?.is_template?.value &&
                            !financialElementFG?.controls?.rules?.controls?.length) ||
                        (intradealable[financialElementFG?.controls?.type?.value] &&
                            !financialElementFG?.controls?.is_template?.value &&
                            financialElementFG?.controls?.is_enforced?.value) ||
                        (intradealable[financialElementFG?.controls?.type?.value] &&
                            !financialElementFG?.controls?.is_template?.value &&
                            !financialElementFG?.controls?.is_enforced?.value &&
                            !financialElementFG?.controls?.rules?.controls?.length)
                    "
                    (click)="doAddRule()"
                >
                    <mat-icon>add</mat-icon>
                </button>
            </h3>
            <div style="margin-bottom: 0px">
                <div
                    style="margin-bottom: 0px"
                    *ngFor="
                        let ruleForm of financialElementFG?.controls?.rules?.controls;
                        index as ri;
                        last as last;
                        first as first
                    "
                >
                    <app-company-compensation-rule
                        #ruleCmp
                        [isOverride]="isOverride"
                        [disabled]="disabled"
                        [compensationType]="financialElementFG?.controls?.type?.value"
                        [hideConditions]="!financialElementFG?.controls?.commonRule?.controls?.type?.value"
                        [canEditCondition]="false"
                        [ruleForm]="ruleForm"
                        [show_down]="!last"
                        [show_up]="!first"
                        [performanceCompensationProvides]="financialElementFG?.controls?.provides?.value"
                        [financialElementFG]="financialElementFG"
                        (delete)="doDeleteRule(ri)"
                        (up)="doMoveRuleUp(ri)"
                        (down)="doMoveRuleDown(ri)"
                    >
                    </app-company-compensation-rule>
                </div>
            </div>
        </mat-expansion-panel>
    `
})
export class FinancialElementComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild('matExpansionPanel', {static: true}) matExpansionPanel: MatExpansionPanel | undefined;
    @ViewChildren('ruleCmp') ruleCmp: QueryList<RuleComponent> | undefined;
    @Input() isOverride: boolean = false;
    @Input() disabled: boolean = false;
    @Input() editMode: boolean = false;
    @Input() financialElementFG: GenericFormGroup<FinancialElementModel> = new GenericFormGroup(
        new FinancialElementModel()
    );

    @Input() show_up: boolean = false;
    @Input() show_down: boolean = false;
    @Input() isDefaultConditionParameters: boolean = false;
    @Input() defaultConditionParameters: MultipleTargetsDealsQueryModel | undefined =
        new MultipleTargetsDealsQueryModel();

    @Output() up = new EventEmitter();
    @Output() down = new EventEmitter();
    @Output() delete = new EventEmitter();
    public FINANCIAL_ELEMENT = FinancialElementModel;
    public FINANCIAL_TRANSFER = FinancialTransferEntity;

    public type_SET = FinancialElementModel.type_set;
    public type_LABELS: {[key: string]: string} = {
        commission_categorization: 'Additional Commission',
        disbursement_instructions: 'CDA Disbursement Text',
        company_income_expense: 'Company Income/Expense',
        company_partner: 'Company Partner',
        incentive: 'Deal Incentive',
        company: 'Deal Income/Expense',
        overhead: 'Deal Overhead',
        referral: 'Deal Referral',
        royalty: 'Deal Royalty',
        disbursement_template: 'Entity Income/Expense',
        user_referral: 'Entity Referral',
        user_royalty: 'Entity Royalty',
        participant: 'Participant',
        agent: 'Sales Compensation',
        compensation_expense: 'Compensation Expense',
        sales: 'Sales Income/Expense',
        taxes: 'Taxes'
    };
    public type_LABELS_common = {
        company: 'Deal Income/Expense',
        royalty: 'Deal Royalty',
        participant: 'Participant',
        company_income_expense: 'Company Income/Expense',
        company_partner: 'Company Partner',
        agent: 'Sales Compensation',
        taxes: 'Taxes',
        user_royalty: 'User Royalty',
        user_referral: 'User Referral'
    };
    public type_LABELS_templates = {
        commission_categorization: 'Additional Commission',
        incentive: 'Incentive',
        disbursement_template: 'Income/Expense',
        overhead: 'Overhead'
    };

    public intradealable: {[key: string]: boolean} = FinancialElementModel.intradealable;

    public ruleConditionType_SET = RuleModel.type_SET;

    // Alex: seems, we can leave here specific conditions only for elements with intradeals.
    // for elements without intradeals we can show all conditions (listed in the 'default' section)
    public ruleConditionType_LABELS: {[key: string]: {[key: string]: string}} = {
        agent: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        company: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        company_income_expense: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        royalty: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            total_royalty_split: 'Aggregate Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        taxes: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        user_royalty: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        user_referral: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        company_partner: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        participant: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        default: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Deal Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        }
    };

    public ruleConditionOperator_SET = RuleModel.operator_SET;
    public ruleConditionOperator_LABELS: {[key: string]: string} = {
        // more_than_or_equal: '(≥) More Than or Equal',
        less_then_or_equal: '(≤) Less Than or Equal',
        // more_than: '(>) More Than',
        less_then: '(<) Less Than',
        equals: '(=) Equals'
    };
    public showConditions = false;
    enforcedEnabledFlag = false;
    compensationExpenseEnabledFlag = false;
    capableMetricsByProductIds: number[] = [];

    constructor(
        protected featureFlagsService: FeatureFlagsService,
        protected calculationSettingsService: CalculationSettingsService
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.enforcedEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'rules:not_enforced_compensation_profiles'
                );
                this.compensationExpenseEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'rules:compensation_expense_element'
                );
                if (this.featureFlagsService.isFeatureEnabled('deals:show_side_count_and_sales_volume')) {
                    // Alex: next 2 tricks(with agentConditions and defaultConditions) is for adding options, hidden under the feature flag,
                    // to the correct place of the list
                    const agentConditions = this.ruleConditionType_LABELS.agent;
                    this.ruleConditionType_LABELS.agent = {};
                    Object.keys(agentConditions).forEach((conditionKey) => {
                        this.ruleConditionType_LABELS.agent[conditionKey] = agentConditions[conditionKey];
                        if (conditionKey === 'count_of_deals') {
                            this.ruleConditionType_LABELS.agent.total_side_count =
                                'Aggregate Count of Deals (Based On # Allocation)';
                        }
                        if (conditionKey === 'sum_of_sales_price') {
                            this.ruleConditionType_LABELS.agent.total_sales_volume =
                                'Aggregate Sales Price (Based On % Allocation)';
                        }
                    });
                    const defaultConditions = this.ruleConditionType_LABELS.default;
                    this.ruleConditionType_LABELS.default = {};
                    Object.keys(defaultConditions).forEach((conditionKey) => {
                        this.ruleConditionType_LABELS.default[conditionKey] = defaultConditions[conditionKey];
                        if (conditionKey === 'count_of_deals') {
                            this.ruleConditionType_LABELS.default.total_side_count =
                                'Aggregate Count of Deals (Based On # Allocation)';
                        }
                        if (conditionKey === 'sum_of_sales_price') {
                            this.ruleConditionType_LABELS.default.total_sales_volume =
                                'Aggregate Sales Price (Based On % Allocation)';
                        }
                    });
                }
            });
    }

    ngOnInit() {
        this.calculationSettingsService.calculationSettings$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((calculationSettings) => {
                this.capableMetricsByProductIds = calculationSettings.capable_metrics_by_product_ids!;
            });
        this.calculationSettingsService.getCalculationSettings();
        this.financialElementFG.controls
            .commonRule!.controls.disbursement_template!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                if (
                    [
                        FinancialElementModel.type_set.agent,
                        FinancialElementModel.type_set.royalty,
                        FinancialElementModel.type_set.user_royalty
                    ].includes(this.financialElementFG.controls.type!.value)
                ) {
                    this.financialElementFG.controls.commonRule!.controls.disbursement_template!.controls.additional_incomes!.controls.forEach(
                        (income) => {
                            income.controls.amount!.disable({emitEvent: false});
                            income.controls.source_amount!.disable({emitEvent: false});
                            income.controls.percent!.disable({emitEvent: false});
                            income.controls.value_wildcard_fk_id!.disable({emitEvent: false});
                        }
                    );
                    this.financialElementFG.controls.commonRule!.controls.disbursement_template!.controls.additional_expenses!.controls.forEach(
                        (expense) => {
                            expense.controls.amount!.disable({emitEvent: false});
                            expense.controls.source_amount!.disable({emitEvent: false});
                            expense.controls.percent!.disable({emitEvent: false});
                            expense.controls.value_wildcard_fk_id!.disable({emitEvent: false});
                        }
                    );
                }
            });
        this.financialElementFG.controls
            .type!.valueChanges.pipe(
                debounce(() => timer(100)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((type) => {
                this.financialElementFG.controls.commonRule!.enable({emitEvent: false});
                this.financialElementFG.controls.provides!.patchValue(FinancialElementModel.providesByType(type));
                this.matExpansionPanel!.open();
                if (this.ruleCmp!.length > 0) {
                    this.ruleCmp!.first.panel!.open();
                }
            });

        // subscribe for changes in commonRuleForm
        this.financialElementFG.controls
            .commonRule!.controls.rule_filters!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((filters) => {
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.rule_filters!.patchValue(filters);
                });
            });
        this.financialElementFG.controls
            .commonRule!.controls.type!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((newType) => {
                if (newType === null) {
                    this.financialElementFG.controls.commonRule!.controls.operator!.patchValue(null);
                }
                if (
                    newType !== ConditionEntity.type_SET.total_product_income &&
                    newType !== ConditionEntity.type_SET.total_product_expense
                ) {
                    this.financialElementFG.controls.commonRule!.controls.product_fk_id!.patchValue(null);
                }
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.type!.patchValue(newType);
                });
                this.canElementBeNotEnforced();
            });
        this.financialElementFG.controls
            .commonRule!.controls.operator!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((newOperator) => {
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.operator!.patchValue(newOperator);
                });
                this.canElementBeNotEnforced();
            });
        this.financialElementFG.controls
            .commonRule!.controls.product_fk_id!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((newOperator) => {
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.product_fk_id!.patchValue(newOperator);
                    rule.controls.product_fk_id!.disable({emitEvent: false});
                });
            });

        this.financialElementFG.controls
            .commonRule!.controls.is_prorated!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((isProrated) => {
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.is_prorated!.patchValue(isProrated);
                });
            });

        this.financialElementFG.controls
            .commonRule!.controls.rule_filters!.controls.used_default_values!.valueChanges.pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe((usedDefaultValues) => {
                if (usedDefaultValues && this.defaultConditionParameters) {
                    delete this.defaultConditionParameters.used_default_values;
                    this.financialElementFG.controls.commonRule!.controls.rule_filters!.patchValue(
                        this.defaultConditionParameters
                    );
                }
            });

        this.financialElementFG.controls
            .is_enforced!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((is_enforced) => {
                if (!is_enforced) {
                    this.financialElementFG.controls.commonRule!.controls.is_prorated!.disable();
                } else if (!this.disabled) {
                    this.financialElementFG.controls.commonRule!.controls.is_prorated!.enable();
                }

                this.financialElementFG.controls.commonRule!.controls.is_prorated!.patchValue(is_enforced, {
                    emitEvent: false
                });
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.disbursement_template!.controls.additional_incomes!.controls.forEach((ftFg) => {
                        ftFg.controls.is_enforced!.patchValue(is_enforced, {emitEvent: false});
                    });
                    rule.controls.disbursement_template!.controls.additional_expenses!.controls.forEach((ftFg) => {
                        ftFg.controls.is_enforced!.patchValue(is_enforced, {emitEvent: false});
                    });
                });
                this.canElementBeNotEnforced();
            });
        this.canElementBeNotEnforced();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.matExpansionPanel?.close();

            this.financialElementFG.controls.commonRule!.controls.is_prorated?.disable({emitEvent: false});
            this.financialElementFG.controls.is_enforced?.disable({emitEvent: false});
            this.financialElementFG.controls.type?.disable({emitEvent: false});
            this.financialElementFG.controls.title?.disable({emitEvent: false});
            this.financialElementFG.controls.commonRule?.controls.operator?.disable({emitEvent: false});
            this.financialElementFG.controls.commonRule?.controls.type?.disable({emitEvent: false});
        }
    }

    stop_propagation(event: Event) {
        event.stopPropagation();
    }

    handleSpacebar(ev: KeyboardEvent) {
        if (ev.keyCode === 32) {
            ev.stopPropagation();
        }
    }

    setDefaultTitle() {
        if (!this.financialElementFG.controls.title!.value && this.financialElementFG.controls.type!.value) {
            this.financialElementFG.controls.title!.patchValue(
                // @ts-ignore
                this.type_LABELS[this.financialElementFG.controls.type.value]
            );
        }
    }

    tooltipText(): string {
        return `If an element is set to 'Not Prorated' then a deal that is ready to cap or move to the next tier will remain on the current plan/split/tier until the next deal.
        If set to 'Prorated', the deal will split proportionately.`;
    }

    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        if (this.disabled) {
            return;
        }
        event.stopPropagation(); // Preventing event bubbling
        if (
            !this._isExpansionIndicator(event.target, [
                'mat-expansion-panel-header-title',
                'mat-expansion-panel-header-description',
                'exp-button',
                'mat-icon'
            ])
        ) {
            matExpansionPanel.close(); // Here's the magic
        }
    }

    private _isExpansionIndicator(target: EventTarget | any, classes: string[] = []): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        classes.push(expansionIndicatorClass);
        for (let i = 0; i < classes.length; i++) {
            if (target.classList && target.classList.contains(classes[i])) {
                return true;
            }
        }
        return false;
    }

    object_keys(obj: {[key: string]: any}): string[] {
        return Object.keys(obj);
    }

    doMoveRuleUp(index: number) {
        const element = this.financialElementFG.controls.rules!.controls.splice(index, 1).pop();
        this.financialElementFG.controls.rules!.controls.splice(index - 1, 0, element!);
    }

    doMoveRuleDown(index: number) {
        const element = this.financialElementFG.controls.rules!.controls.splice(index, 1).pop();
        this.financialElementFG.controls.rules!.controls.splice(index + 1, 0, element!);
    }

    doDeleteRule(index: number) {
        this.financialElementFG.controls.rules!.controls.splice(index, 1);
        this.canElementBeNotEnforced();
    }

    doAddRule() {
        const ruleObj = new RuleModel();
        ruleObj.is_new = true;

        const form = new GenericFormGroup(ruleObj);

        // inherit values for commonRuleForm
        form.controls.operator!.patchValue(this.financialElementFG.controls.commonRule!.controls.operator!.value);
        form.controls.type!.patchValue(this.financialElementFG.controls.commonRule!.controls.type!.value);
        form.controls.product_fk_id!.patchValue(
            this.financialElementFG.controls.commonRule!.controls.product_fk_id!.value
        );
        form.controls.rule_filters!.patchValue(
            this.financialElementFG.controls.commonRule!.controls.rule_filters!.value
        );

        this.financialElementFG.controls.rules!.controls.push(form);
        this.canElementBeNotEnforced();
    }

    canElementBeNotEnforced() {
        const element = this.financialElementFG.getRawValue();
        if (FinancialElementModel.checkIfElementCanBeUnenforced(element.type!)) {
            // @ts-ignore
            if (
                FinancialElementModel.intradealable[element.type!] &&
                element.is_enforced &&
                (element.commonRule.type || element.commonRule.operator || element.rules.length > 1)
            ) {
                this.financialElementFG.controls.is_enforced!.disable({emitEvent: false});
            } else if (!this.disabled) {
                this.financialElementFG.controls.is_enforced!.enable({emitEvent: false});
            }
        } else {
            this.financialElementFG.controls.is_enforced!.disable({emitEvent: false});
        }
    }

    disableOnChange() {
        this.financialElementFG.controls.type?.disable({emitEvent: false});
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
