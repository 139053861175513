import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {timePeriodObj, transactionTypeList, listOfTransactionStatus, listOfDealApproval} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {IReport, IReportSetting, IReportSettingKeys, ITag} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {TagsService} from '../../../../../../services/api/tags.service';
import {ReplaySubject} from 'rxjs';

@Component({
    selector: 'app-report-edit-bar-agent-performance',
    templateUrl: './agent-performance.component.html',
    styleUrls: ['./agent-performance.component.scss']
})
export class AgentPerformanceBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.this_year.value,
        transaction_type: ['buyer', 'seller'],
        transaction_status: ['opportunity', 'active', 'pending', 'processed'],
        deal_approval: ['approved', 'unapproved']
    };

    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
    public activePanel: string = 'time_period';
    public allTags: ITag[] = [];
    public transactionTypeList: Array<{title: string; value: string}> = transactionTypeList;
    public listOfTransactionStatus: Array<{title: string; value: string}> = listOfTransactionStatus;
    public listOfDealApproval: Array<{title: string; value: string}> = listOfDealApproval;
    public reportSettings: IReportSetting | null = null;
    public isShowNext: boolean = true;
    public reportOptions = [
        {
            value: 'time_period',
            title: 'Time Period'
        },
        {
            value: 'deal_type',
            title: 'Transaction Type'
        },
        {
            value: 'deal_status',
            title: 'Status'
        },
        {
            value: 'deal_approval',
            title: 'Deal Approval'
        },
        {
            value: 'report_tagging',
            title: 'Report Tagging'
        },
        {
            value: 'entity',
            title: 'Customers'
        },
        {
            value: 'division',
            title: 'Offices'
        }
    ];

    constructor(private tagsService: TagsService) {}

    ngOnInit() {
        this.initData();
        this.initSubscription();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};
        }
    }

    initSubscription() {
        this.tagsService
            .getCompanyTags()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.allTags = data.result;
            });
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        switch (property) {
            case 'time_period':
                this.reportSettings = {
                    ...this.reportSettings,
                    ...data
                };
                break;
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data as never;
                }
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
