<div class="d-flex align-items-center">
    <div
        [ngClass]="{
            'w-100':
                (!convenienceFeeBankPayerControl && !convenienceFeeCardPayerControl) ||
                (!isBankConvenienceFeeAllowed && !isCardConvenienceFeeAllowed),
            'w-50':
                (convenienceFeeBankPayerControl || convenienceFeeCardPayerControl) &&
                (isBankConvenienceFeeAllowed || isCardConvenienceFeeAllowed)
        }"
    >
        <h3 class="d-flex align-items-center" *ngIf="pickerType === 'united'">
            {{ titleLabel }}
            <button mat-icon-button [matMenuTriggerFor]="appMenu"><mat-icon>add</mat-icon></button>

            <mat-menu #appMenu="matMenu">
                <div *ngFor="let gateway of companyGateways">
                    <button
                        mat-menu-item
                        [matMenuTriggerFor]="methods"
                        [matMenuTriggerData]="{gateway: gateway}"
                        (mouseout)="currentGatewayId = null"
                        (mouseover)="currentGatewayId = gateway!.payment_gateway_id!"
                    >
                        <span class="ml-2">{{
                            gateway.type === 'authorize_net_merchant' ? 'Authorize.Net' : gateway.title
                        }}</span>
                    </button>
                </div>
                <button
                    mat-menu-item
                    *ngIf="
                        (allowedGatewayTypes.includes('zipi_financial_trust') ||
                            allowedGatewayTypes.includes('zipi_financial_business')) &&
                        (!companyGateways || companyGateways.length === 0)
                    "
                >
                    <a
                        href=""
                        [routerLink]="['/marketplace/setup/moneytransfers']"
                        (click)="startRedirectEvent(); $event.stopPropagation(); $event.preventDefault()"
                        *ngIf="allowedGatewayTypes.length === 1 && allowedGatewayTypes[0] === 'zipi_financial_trust'"
                        class="ml-2"
                        >Setup Trust Account</a
                    >
                    <a
                        href=""
                        [routerLink]="['/marketplace/setup/moneytransfers']"
                        (click)="startRedirectEvent(); $event.stopPropagation(); $event.preventDefault()"
                        *ngIf="allowedGatewayTypes.length > 1 || !allowedGatewayTypes.includes('zipi_financial_trust')"
                        class="ml-2"
                        >Setup Money Transfers (EFT) Account</a
                    >
                </button>
            </mat-menu>

            <mat-menu #methods="matMenu">
                <ng-template matMenuContent let-gateway="gateway">
                    <ng-container *ngFor="let method of gateway.payment_methods">
                        <button
                            mat-menu-item
                            *ngIf="method.is_available_for_payment"
                            [disabled]="
                                method &&
                                (method!.status !== 'active' ||
                                    !method!.related__ledger_account_fk_id ||
                                    method!.payment_gateway!.status !== 'active' ||
                                    (payToMethodIdsControl &&
                                        method.payment_method_id &&
                                        payToMethodIdsControl.value.includes(method!.payment_method_id!)))
                            "
                            (click)="selectMethod(method)"
                        >
                            {{ method.title }}
                        </button>
                    </ng-container>
                </ng-template>
            </mat-menu>
        </h3>

        <h3 class="d-flex align-items-center" *ngIf="pickerType === 'separated'">
            {{ titleLabel }}
            <button mat-raised-button [matMenuTriggerFor]="appMenu" class="short-button ml-2">
                <mat-icon>add</mat-icon>
            </button>

            <mat-menu #appMenu="matMenu">
                <!--                <button mat-menu-item *ngIf="this.payToCardControl" [disabled]="cardGateways.length === 0" (click)="selectDefaultCardMethod()">-->
                <!--                    <span class="ml-2">Credit Card</span>-->
                <!--                </button>-->

                <button
                    mat-menu-item
                    *ngIf="this.payToCardControl"
                    [matMenuTriggerFor]="gateways"
                    [matMenuTriggerData]="{gateways: cardGateways, type: 'card'}"
                >
                    <span class="ml-2">Credit Card</span>
                </button>

                <button
                    mat-menu-item
                    *ngIf="this.payToBankControl"
                    [matMenuTriggerFor]="gateways"
                    [matMenuTriggerData]="{gateways: bankGateways, type: 'bank'}"
                >
                    <span class="ml-2">Bank Account</span>
                </button>
            </mat-menu>

            <mat-menu #gateways="matMenu">
                <ng-template matMenuContent let-gateways="gateways" let-type="type">
                    <ng-container *ngFor="let gateway of gateways">
                        <button
                            mat-menu-item
                            *ngIf="gateway.type === 'authorize_net_merchant'"
                            (click)="selectAuthMethod()"
                        >
                            <img
                                style="width: 60px"
                                src="https://storage.googleapis.com/zipi-static/addons/authnet/logos/authnet%20360x140.png"
                            />
                            <span class="ml-2">{{ 'Authorize.Net' }}</span>
                        </button>
                    </ng-container>
                    <ng-container *ngFor="let gateway of gateways">
                        <button
                            mat-menu-item
                            *ngIf="gateway.driver_type === 'payload'"
                            [matMenuTriggerFor]="methods"
                            [matMenuTriggerData]="{gateway: gateway, type: type}"
                            (mouseout)="currentGatewayId = null"
                            (mouseover)="currentGatewayId = gateway!.payment_gateway_id!"
                        >
                            <span class="ml-2">{{ gateway.title }}</span>
                        </button>
                    </ng-container>

                    <button
                        mat-menu-item
                        *ngIf="
                            ((allowedGatewayTypes.includes('zipi_financial_trust') ||
                                allowedGatewayTypes.includes('zipi_financial_business')) &&
                                !companyGateways) ||
                            companyGateways.length === 0
                        "
                    >
                        <a
                            href=""
                            [routerLink]="['/marketplace/setup/moneytransfers']"
                            (click)="startRedirectEvent(); $event.stopPropagation(); $event.preventDefault()"
                            *ngIf="
                                allowedGatewayTypes.length === 1 && allowedGatewayTypes[0] === 'zipi_financial_trust'
                            "
                            class="ml-2"
                            >Setup Trust Account</a
                        >
                        <a
                            href=""
                            [routerLink]="['/marketplace/setup/moneytransfers']"
                            (click)="startRedirectEvent(); $event.stopPropagation(); $event.preventDefault()"
                            *ngIf="
                                allowedGatewayTypes.length > 1 || !allowedGatewayTypes.includes('zipi_financial_trust')
                            "
                            class="ml-2"
                            >Setup Money Transfers (EFT) Account</a
                        >
                    </button>
                </ng-template>
            </mat-menu>

            <mat-menu #methods="matMenu">
                <ng-template matMenuContent let-gateway="gateway" let-type="type">
                    <ng-container
                        *ngIf="!!gateway.payment_methods && gateway.payment_methods.length > 0; else emptyButton"
                    >
                        <ng-container *ngFor="let method of gateway.payment_methods">
                            <ng-container *ngIf="type === 'card'">
                                <button
                                    mat-menu-item
                                    *ngIf="
                                        gateway.driver_type === 'payload' || gateway.type === 'authorize_net_merchant'
                                    "
                                    [disabled]="
                                        method &&
                                        (method!.status !== 'active' ||
                                            !method!.related__ledger_account_fk_id ||
                                            method!.payment_gateway!.status !== 'active' ||
                                            (payToCardControl &&
                                                method.payment_method_id &&
                                                payToCardControl.value === method!.payment_method_id!))
                                    "
                                    (click)="selectCardMethod(method, gateway)"
                                >
                                    {{ method.title }}
                                </button>
                            </ng-container>

                            <ng-container *ngIf="type === 'bank'">
                                <button
                                    mat-menu-item
                                    *ngIf="
                                        method.is_available_for_payment && method.type !== 'zipi_financial_credit_card'
                                    "
                                    [disabled]="
                                        method &&
                                        (method!.status !== 'active' ||
                                            !method!.related__ledger_account_fk_id ||
                                            method!.payment_gateway!.status !== 'active' ||
                                            (payToBankControl &&
                                                method.payment_method_id &&
                                                payToBankControl.value === method!.payment_method_id!))
                                    "
                                    (click)="selectBankMethod(method, gateway)"
                                >
                                    {{ method.title }}
                                </button>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #emptyButton>
                        <button
                            mat-menu-item
                            class="font-italic"
                            (click)="$event.stopPropagation(); $event.preventDefault()"
                        >
                            No connected banks
                        </button>
                    </ng-template>
                </ng-template>
            </mat-menu>
        </h3>
        <div style="min-height: 50px" class="w-100 m-0 p-1">
            <mat-chip-list class="w-100 m-0" *ngIf="pickerType === 'united'">
                <ng-container *ngFor="let method of selectedMethodsForChips">
                    <mat-chip
                        *ngIf="payToMethodIdsControl!.value.includes(method.payment_method_id!)"
                        [removable]="true"
                        (removed)="removeMethod(method.payment_method_id!)"
                        [ngStyle]="method.deleted_at || method.status !== 'active' ? {color: 'red'} : {}"
                    >
                        {{
                            method.deleted_at
                                ? 'Payment Method has been removed'
                                : method.status !== 'active'
                                  ? method.title + ' is not Active'
                                  : method.title
                        }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>
            <mat-chip-list class="w-100 m-0" *ngIf="pickerType === 'separated'">
                <ng-container *ngFor="let method of selectedCardMethodsForChips">
                    <mat-chip
                        *ngIf="payToCardControl?.value === method.payment_method_id"
                        [removable]="true"
                        (removed)="removeMethodSeparated(method.payment_method_id, 'card')"
                        [ngStyle]="method.deleted_at || method.status !== 'active' ? {color: 'red'} : {}"
                    >
                        <mat-icon class="chip-icon">credit_card</mat-icon>
                        {{
                            method.deleted_at
                                ? 'Payment Method has been removed'
                                : method.status !== 'active'
                                  ? method.title + ' is not Active'
                                  : method.payment_gateway.type === 'authorize_net_merchant'
                                    ? 'Authorize.Net'
                                    : method.title
                        }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
                <ng-container *ngFor="let method of selectedBankMethodsForChips">
                    <mat-chip
                        *ngIf="payToBankControl?.value === method.payment_method_id"
                        [removable]="true"
                        (removed)="removeMethodSeparated(method.payment_method_id, 'bank')"
                        [ngStyle]="method.deleted_at || method.status !== 'active' ? {color: 'red'} : {}"
                    >
                        <mat-icon class="chip-icon">account_balance</mat-icon>
                        {{
                            method.deleted_at
                                ? 'Payment Method has been removed'
                                : method.status !== 'active'
                                  ? method.title + ' is not Active'
                                  : method.title
                        }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>
        </div>
    </div>

    <div class="w-50 px-2">
        <mat-form-field class="w-100" *ngIf="convenienceFeeBankPayerControl && isBankConvenienceFeeAllowed">
            <mat-select
                [formControl]="convenienceFeeBankPayerControl"
                placeholder="Bank convenience fee allocation"
                [disabled]="
                    convenienceFeeBankPayerControl &&
                    convenienceFeeBankPayerControl.value &&
                    convenienceFeeBankPayerControl.value === 'system'
                "
            >
                <mat-option
                    *ngIf="
                        convenienceFeeBankPayerControl &&
                        convenienceFeeBankPayerControl.value &&
                        convenienceFeeBankPayerControl.value === 'system'
                    "
                    [value]="'system'"
                    >System</mat-option
                >
                <mat-option [value]="'money_sender'">Money Sender</mat-option>
                <mat-option [value]="'money_receiver'">Money Receiver</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100" *ngIf="convenienceFeeCardPayerControl && isCardConvenienceFeeAllowed">
            <mat-select
                [formControl]="convenienceFeeCardPayerControl"
                placeholder="Credit card convenience fee allocation"
                [disabled]="
                    convenienceFeeCardPayerControl &&
                    convenienceFeeCardPayerControl.value &&
                    convenienceFeeCardPayerControl.value === 'system'
                "
            >
                <mat-option
                    *ngIf="
                        convenienceFeeCardPayerControl &&
                        convenienceFeeCardPayerControl.value &&
                        convenienceFeeCardPayerControl.value === 'system'
                    "
                    [value]="'system'"
                    >System</mat-option
                >
                <mat-option [value]="'money_sender'">Money Sender</mat-option>
                <mat-option [value]="'money_receiver'">Money Receiver</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
