import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {RuleModel} from '../models/rule.model';
import {MatExpansionPanel} from '@angular/material/expansion';
import {FinancialElementModel} from '../models/financial-element.model';
import {ConditionEntity} from '../../../../models/condition.entity';
import {FinancialTransferEntity} from '../models/financial-transfer.entity';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Wildcard} from '../../../../models/wildcard';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';

@Component({
    selector: 'app-company-compensation-rule',
    styles: [
        `
            .intradeal-icon {
                font-size: 18px;
                color: grey;
                width: 18px;
                height: 18px;
            }
        `
    ],
    template: `
        <mat-expansion-panel
            #panel
            style="margin-bottom: 4px"
            hideToggle
            [expanded]="ruleForm?.controls?.is_new?.value"
        >
            <mat-expansion-panel-header [collapsedHeight]="'90px'" [expandedHeight]="'90px'">
                <mat-panel-title>
                    <mat-form-field (click)="stop_propagation($event)">
                        <input
                            matInput
                            placeholder="Rule Title"
                            (keydown)="handleSpacebar($event)"
                            style="padding-right: 30px;"
                            [formControl]="ruleForm.controls.title!"
                        />
                        <mat-hint
                            *ngIf="ruleForm?.controls?.public_title?.value"
                            style="color: black;font-size: 12px;display: flex;"
                        >
                            {{ ruleForm?.controls?.public_title?.value }}
                            <mat-icon
                                class="ml-1"
                                style="font-size: 14px;"
                                matTooltip="This title is visible to ALL Assigned Users (ie Selecting Cap Widget Parameters)."
                                >visibility</mat-icon
                            >
                        </mat-hint>
                        <app-edit-title
                            [titleFormControl]="ruleForm.controls.public_title!"
                            [disabled]="disabled"
                        ></app-edit-title>
                    </mat-form-field>
                </mat-panel-title>
                <mat-panel-description
                    style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center"
                >
                    <div
                        style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start; margin: 0 16px;"
                    >
                        <mat-slide-toggle
                            *ngIf="
                                financialElementFG?.controls?.type?.value ===
                                    FINANCIAL_ELEMENT.type_set.company_income_expense && isFinalEnabledFlag
                            "
                            (click)="$event.stopPropagation()"
                            [formControl]="ruleForm.controls.is_final!"
                            >Final Rule
                        </mat-slide-toggle>
                    </div>
                    <span style="display: flex; justify-content: flex-end">
                        <button
                            class="exp-button"
                            *ngIf="panel.expanded === false"
                            [disabled]="disabled"
                            mat-icon-button
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button
                            class="exp-button"
                            *ngIf="panel.expanded === true"
                            [disabled]="disabled"
                            mat-icon-button
                        >
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <button
                            [disabled]="disabled"
                            mat-icon-button
                            class="mat-warn"
                            (click)="delete.emit(); stop_propagation($event)"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                        <div
                            style="display: flex; flex-direction: column; margin-top: -20px; margin-bottom: -20px; justify-content: space-around;"
                        >
                            <button
                                [disabled]="disabled"
                                mat-icon-button
                                *ngIf="show_up"
                                (click)="up.emit(); stop_propagation($event)"
                            >
                                <mat-icon>arrow_upward</mat-icon>
                            </button>
                            <button
                                [disabled]="disabled"
                                mat-icon-button
                                *ngIf="show_down"
                                (click)="down.emit(); stop_propagation($event)"
                            >
                                <mat-icon>arrow_downward</mat-icon>
                            </button>
                        </div>
                    </span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <app-company-compensation-filters
                    style="display:none;"
                    [filtersForm]="ruleForm.controls.rule_filters!"
                    [disabled]="disabled"
                ></app-company-compensation-filters>

                <div *ngIf="!hideConditions">
                    <h3 class="label">Condition</h3>

                    <div style="display:flex; flex-direction: row">
                        <app-company-compensation-condition
                            [condForm]="ruleForm"
                            [typeOptions]="compensationType ? type_LABELS[compensationType] : type_LABELS['default']"
                            [operatorOptions]="operator_LABELS"
                            [disabled]="disabled"
                            [disabledProductReselect]="true"
                        ></app-company-compensation-condition>
                        <mat-icon
                            class="material-icons-outlined intradeal-icon"
                            matTooltip="If the element conditions dictate, it will split and calculate applicable financial rules within a single deal."
                            *ngIf="compensationType && intradealable[compensationType]"
                        >
                            assessment
                        </mat-icon>
                    </div>

                    <div class="flex-column" style="align-items: center; justify-content: space-between; width: 100%">
                        <div
                            class="flex-row"
                            *ngFor="let cond of ruleForm?.controls?.and_conditions?.controls; index as ci"
                        >
                            <app-company-compensation-condition
                                [condForm]="cond"
                                [typeOptions]="
                                    compensationType ? type_LABELS[compensationType] : type_LABELS['default']
                                "
                                [operatorOptions]="additional_operator_LABELS"
                                [disabled]="disabled"
                            ></app-company-compensation-condition>
                            <button
                                mat-icon-button
                                class="mat-warn"
                                (click)="doDeleteCondition(ci)"
                                [disabled]="disabled"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <button
                            mat-raised-button
                            style="width:100%"
                            [disabled]="disabled"
                            (click)="doAddAndCondition()"
                        >
                            + And Condition
                        </button>
                    </div>
                </div>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.agent">
                    <app-company-ancillary
                        [type]="FINANCIAL_TRANSFER.type_SET.agent_split"
                        [disabled]="disabled"
                        [hideExpense]="true"
                        [defaultSenderWildcardId]="3"
                        [hideDisbursementInstructionsFlag]="true"
                        [hidePayAtEscrowFlag]="true"
                        [limitIncomes]="1"
                        [hideSender]="true"
                        [hideReceiver]="true"
                        [hideProduct]="true"
                        [hideLabel]="true"
                        [compensationType]="compensationType"
                        [disbursementTemplateFG]="ruleForm.controls.disbursement_template"
                        [incomeLabel]="null"
                        [expenseLabel]="null"
                    ></app-company-ancillary>
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.user_royalty">
                    <app-company-ancillary
                        [type]="FINANCIAL_TRANSFER.type_SET.transfer"
                        [disabled]="disabled"
                        [hideProduct]="true"
                        [hideIncome]="true"
                        [hideDisbursementInstructionsFlag]="true"
                        [hidePayAtEscrowFlag]="true"
                        [limitExpenses]="1"
                        [hideLabel]="true"
                        [compensationType]="compensationType"
                        [disbursementTemplateFG]="ruleForm.controls.disbursement_template"
                        [incomeLabel]="null"
                        [expenseLabel]="null"
                    ></app-company-ancillary>
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.royalty">
                    <app-company-ancillary
                        [type]="FINANCIAL_TRANSFER.type_SET.transfer"
                        [disabled]="disabled"
                        [hideProduct]="true"
                        [hideIncome]="true"
                        [hideDisbursementInstructionsFlag]="true"
                        [hidePayAtEscrowFlag]="true"
                        [limitExpenses]="1"
                        [hideLabel]="true"
                        [compensationType]="compensationType"
                        [disbursementTemplateFG]="ruleForm.controls.disbursement_template"
                        [incomeLabel]="null"
                        [expenseLabel]="null"
                    ></app-company-ancillary>
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.referral">
                    <app-company-ancillary
                        [type]="FINANCIAL_TRANSFER.type_SET.referral"
                        [hideIncome]="true"
                        [hideExpenseTitle]="true"
                        [defaultSenderWildcardId]="3"
                        [compensationType]="compensationType"
                        [disbursementTemplateFG]="ruleForm.controls.disbursement_template"
                        [expenseLabel]="'Add Deal Referral'"
                        [disabled]="disabled"
                    ></app-company-ancillary>
                </ng-container>

                <ng-container *ngIf="isAncillary(compensationType)">
                    <app-company-ancillary
                        [hideIncome]="
                            compensationType === FINANCIAL_ELEMENT.type_set.sales ||
                            compensationType === FINANCIAL_ELEMENT.type_set.compensation_expense
                        "
                        [isOverride]="isOverride"
                        [disabled]="disabled"
                        [compensationType]="compensationType"
                        [disbursementTemplateFG]="ruleForm.controls.disbursement_template"
                    ></app-company-ancillary>
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.taxes">
                    <app-company-split-plan
                        [splitForm]="ruleForm.controls.split_plan"
                        [disabled]="disabled"
                    ></app-company-split-plan>
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.disbursement_instructions">
                    <app-company-disbursement-instructions
                        [compensationType]="compensationType"
                        [disbursementInstructionsFG]="ruleForm?.controls?.disbursement_instructions"
                        [disabled]="disabled"
                    ></app-company-disbursement-instructions>
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.overhead">
                    <app-company-overhead [ruleFG]="ruleForm" [disabled]="disabled"></app-company-overhead>
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.incentive">
                    <app-company-incentive [ruleFG]="ruleForm" [disabled]="disabled"></app-company-incentive>
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.participant">
                    <app-company-ancillary
                        [type]="FINANCIAL_TRANSFER.type_SET.participant_split"
                        [defaultValueType]="FINANCIAL_TRANSFER.value_type_SET.percent_of_deal"
                        [compensationType]="compensationType"
                        [receiverLabel]="'Participant'"
                        [disbursementTemplateFG]="ruleForm.controls.disbursement_template"
                        [incomeLabel]="null"
                        [expenseLabel]="'Add Participant'"
                        [hideLabel]="true"
                        [disabled]="disabled"
                    ></app-company-ancillary>
                    <!--<app-company-participant [compensationType]="compensationType" [ruleFG]="ruleForm"></app-company-participant>-->
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.company_partner">
                    <app-company-ancillary
                        [type]="FINANCIAL_TRANSFER.type_SET.transfer"
                        [compensationType]="compensationType"
                        [receiverLabel]="'Company Partner'"
                        [disbursementTemplateFG]="ruleForm.controls.disbursement_template"
                        [incomeLabel]="null"
                        [expenseLabel]="'Add Company Partner'"
                        [hideLabel]="true"
                    ></app-company-ancillary>
                    <!--<app-company-participant [compensationType]="compensationType" [ruleFG]="ruleForm"></app-company-participant>-->
                </ng-container>

                <ng-container *ngIf="compensationType === FINANCIAL_ELEMENT.type_set.commission_categorization">
                    <app-company-commission-categorize-template
                        [ruleFG]="ruleForm"
                        [disabled]="disabled"
                    ></app-company-commission-categorize-template>
                </ng-container>
            </div>
        </mat-expansion-panel>
    `
})
export class RuleComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild('panel', {static: true}) panel: MatExpansionPanel | undefined;
    public FINANCIAL_ELEMENT = FinancialElementModel;
    public FINANCIAL_TRANSFER = FinancialTransferEntity;
    public WILDCARD = Wildcard;
    @Input() isOverride: boolean = false;
    @Input() canEditCondition: boolean = false;
    @Input() hideConditions: boolean = false;
    @Input() financialElementFG: GenericFormGroup<FinancialElementModel> = new GenericFormGroup(
        new FinancialElementModel()
    );
    @Input() disabled: boolean = false;
    @Input() ruleForm: GenericFormGroup<RuleModel> = new GenericFormGroup(new RuleModel());
    @Input() performanceCompensationProvides: string = FinancialElementModel.provides_set.compensation_plan;
    @Input() compensationType: string | null = null;

    @Input() show_up: boolean = false;
    @Input() show_down: boolean = false;

    @Output() up = new EventEmitter();
    @Output() down = new EventEmitter();

    @Output() delete = new EventEmitter();
    isFinalEnabledFlag = false;

    public intradealable = FinancialElementModel.intradealable;

    public type_SET = RuleModel.type_SET;
    public type_LABELS: {[key: string]: {[key: string]: string}} = {
        // commission_categorization: {
        //     sum_of_sales_price: 'Aggregate Sales Price',
        //     count_of_deals: 'Aggregate Count of Deals',
        //     total_company_split: 'Aggregate Company Split',
        //     total_agent_split: 'Aggregate Sales Entity Split',
        // },
        agent: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        company: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        company_income_expense: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        disbursement_template: {
            sum_of_sales_price: 'Aggregate Sales Price',
            sum_of_gross_commission: 'Aggregate Gross Commission',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        // incentive: {
        //     sum_of_sales_price: 'Aggregate Sales Price',
        //     count_of_deals: 'Aggregate Count of Deals',
        //     total_company_split: 'Aggregate Company Split',
        //     total_agent_split: 'Aggregate Sales Entity Split',
        //     total_royalty_split: 'Aggregate Royalty Split',
        // },
        // overhead: {
        //     sum_of_sales_price: 'Aggregate Sales Price',
        //     count_of_deals: 'Aggregate Count of Deals',
        //     total_company_split: 'Aggregate Company Split',
        //     total_agent_split: 'Aggregate Sales Entity Split',
        //     total_royalty_split: 'Aggregate Royalty Split',
        // },
        // participant: {
        //     sum_of_sales_price: 'Aggregate Sales Price',
        //     count_of_deals: 'Aggregate Count of Deals',
        //     total_company_split: 'Aggregate Company Split',
        //     total_agent_split: 'Aggregate Sales Entity Split',
        //     total_royalty_split: 'Aggregate Royalty Split',
        // },
        royalty: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            // total_company_split: 'Aggregate Company Split',
            // total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        taxes: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            // total_company_split: 'Aggregate Company Split',
            // total_agent_split: 'Aggregate Sales Entity Split',
            // total_royalty_split: 'Aggregate Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        user_royalty: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            // total_company_split: 'Aggregate Company Split',
            // total_agent_split: 'Aggregate Sales Entity Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        user_referral: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            // total_company_split: 'Aggregate Company Split',
            // total_agent_split: 'Aggregate Sales Entity Split',
            // total_user_royalty_split: 'Aggregate User Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        sales: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        compensation_expense: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        company_partner: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        participant: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        },
        default: {
            sum_of_sales_price: 'Aggregate Sales Price',
            count_of_deals: 'Aggregate Count of Deals',
            total_company_split: 'Aggregate Company Split',
            total_agent_split: 'Aggregate Sales Entity Split',
            total_royalty_split: 'Aggregate Royalty Split',
            total_user_royalty_split: 'Aggregate User Royalty Split',
            per_deal_sales_price: 'Per Deal Sales Price',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            total_product_income: 'Aggregate Product Income',
            total_product_expense: 'Aggregate Product Expense'
        }
        // sum_of_sales_price: 'Aggregate Sales Price',
        // count_of_deals: 'Aggregate Count of Deals',
        // total_company_split: 'Aggregate Company Split',
        // total_agent_split: 'Aggregate Sales Entity Split',
        // total_royalty_split: 'Aggregate Royalty Split',
    };

    public operator_SET = RuleModel.operator_SET;
    public operator_LABELS = {
        // more_than_or_equal: '(≥) More Than or Equal',
        less_then_or_equal: '(≤) Less Than or Equal',
        // more_than: '(>) More Than',
        less_then: '(<) Less Than',
        equals: '(=) Equals'
    };
    public additional_operator_LABELS = {
        more_than_or_equal: '(≥) More Than or Equal',
        less_then_or_equal: '(≤) Less Than or Equal',
        more_than: '(>) More Than',
        less_then: '(<) Less Than',
        equals: '(=) Equals'
    };

    constructor(protected featureFlagsService: FeatureFlagsService) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.isFinalEnabledFlag = this.featureFlagsService.isFeatureEnabled('rules:not_is_final_rules');
                if (this.featureFlagsService.isFeatureEnabled('deals:show_side_count_and_sales_volume')) {
                    // Alex: next 2 tricks(with agentConditions and defaultConditions) is for adding options, hidden under the feature flag,
                    // to the correct place of the list
                    const agentConditions = this.type_LABELS.agent;
                    this.type_LABELS.agent = {};
                    Object.keys(agentConditions).forEach((conditionKey) => {
                        this.type_LABELS.agent[conditionKey] = agentConditions[conditionKey];
                        if (conditionKey === 'count_of_deals') {
                            this.type_LABELS.agent.total_side_count =
                                'Aggregate Count of Deals (Based On # Allocation)';
                        }
                        if (conditionKey === 'sum_of_sales_price') {
                            this.type_LABELS.agent.total_sales_volume = 'Aggregate Sales Price (Based On % Allocation)';
                        }
                    });
                    const defaultConditions = this.type_LABELS.default;
                    this.type_LABELS.default = {};
                    Object.keys(defaultConditions).forEach((conditionKey) => {
                        this.type_LABELS.default[conditionKey] = defaultConditions[conditionKey];
                        if (conditionKey === 'count_of_deals') {
                            this.type_LABELS.default.total_side_count =
                                'Aggregate Count of Deals (Based On # Allocation)';
                        }
                        if (conditionKey === 'sum_of_sales_price') {
                            this.type_LABELS.default.total_sales_volume =
                                'Aggregate Sales Price (Based On % Allocation)';
                        }
                    });
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.ruleForm.controls.title?.disable({emitEvent: false});
            this.ruleForm.controls.public_title?.disable({emitEvent: false});
            this.ruleForm.controls.is_final?.disable({emitEvent: false});
        } else if (changes.disabled && !changes.disabled.currentValue) {
            this.ruleForm.controls.title?.enable({emitEvent: false});
            this.ruleForm.controls.public_title?.enable({emitEvent: false});
            this.ruleForm.controls.is_final?.enable({emitEvent: false});
        }
    }

    doAddAndCondition() {
        this.ruleForm.controls.and_conditions!.controls.push(
            new GenericFormGroup<ConditionEntity>(new ConditionEntity())
        );
    }

    doDeleteCondition(index: number) {
        this.ruleForm.controls.and_conditions!.controls.splice(index, 1);
    }

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    stop_propagation(event: Event) {
        event.stopPropagation();
    }

    handleSpacebar(ev: KeyboardEvent) {
        if (ev.keyCode === 32) {
            ev.stopPropagation();
        }
    }

    syncDisTemplate() {
        const is_enforced = this.financialElementFG.controls.is_enforced!.value;
        const ruleVal = this.ruleForm.controls.disbursement_template!.getRawValue();
        this.ruleForm.controls.disbursement_template!.patchValue(
            this.financialElementFG.controls.commonRule!.controls.disbursement_template!.getRawValue()
        );
        this.ruleForm.controls.disbursement_template!.disable({emitEvent: false});
        this.ruleForm.controls.disbursement_template!.controls.additional_expenses!.controls.forEach((expense, i) => {
            expense.controls.unique_hash!.patchValue(null, {emitEvent: false});
            if (ruleVal.additional_expenses[i]) {
                if (ruleVal.additional_expenses[i].amount !== null) {
                    expense.controls.amount!.patchValue(ruleVal.additional_expenses[i].amount, {emitEvent: false});
                }
                if (ruleVal.additional_expenses[i].source_amount !== null) {
                    expense.controls.source_amount!.patchValue(ruleVal.additional_expenses[i].source_amount, {
                        emitEvent: false
                    });
                }
                if (ruleVal.additional_expenses[i].unique_hash !== null) {
                    expense.controls.unique_hash!.patchValue(ruleVal.additional_expenses[i].unique_hash, {
                        emitEvent: false
                    });
                }
                if (ruleVal.additional_expenses[i].percent !== null) {
                    expense.controls.percent!.patchValue(ruleVal.additional_expenses[i].percent, {emitEvent: false});
                }
                if (ruleVal.additional_expenses[i].value_wildcard_fk_id !== null) {
                    expense.controls.value_wildcard_fk_id!.patchValue(
                        ruleVal.additional_expenses[i].value_wildcard_fk_id,
                        {emitEvent: false}
                    );
                }
                if (ruleVal.additional_expenses[i].receiver !== null) {
                    expense.controls.receiver!.patchValue(ruleVal.additional_expenses[i].receiver, {emitEvent: false});
                }
                if (ruleVal.additional_expenses[i].receiver_wildcard_id !== null) {
                    expense.controls.receiver_wildcard_id!.patchValue(
                        ruleVal.additional_expenses[i].receiver_wildcard_id,
                        {emitEvent: false}
                    );
                }
            }
            expense.controls.amount!.enable({emitEvent: false});
            expense.controls.source_amount!.enable({emitEvent: false});
            expense.controls.unique_hash!.enable({emitEvent: false});
            expense.controls.percent!.enable({emitEvent: false});
            expense.controls.value_wildcard_fk_id!.enable({emitEvent: false});
            expense.controls.is_enforced!.patchValue(is_enforced, {emitEvent: false});
        });
        this.ruleForm.controls.disbursement_template!.controls.additional_incomes!.controls.forEach((income, i) => {
            income.controls.unique_hash!.patchValue(null, {emitEvent: false});
            if (ruleVal.additional_incomes[i]) {
                if (ruleVal.additional_incomes[i].amount !== null) {
                    income.controls.amount!.patchValue(ruleVal.additional_incomes[i].amount, {emitEvent: false});
                }
                if (ruleVal.additional_incomes[i].source_amount !== null) {
                    income.controls.source_amount!.patchValue(ruleVal.additional_incomes[i].source_amount, {
                        emitEvent: false
                    });
                }
                if (ruleVal.additional_incomes[i].unique_hash !== null) {
                    income.controls.unique_hash!.patchValue(ruleVal.additional_incomes[i].unique_hash, {
                        emitEvent: false
                    });
                }
                if (ruleVal.additional_incomes[i].percent !== null) {
                    income.controls.percent!.patchValue(ruleVal.additional_incomes[i].percent, {emitEvent: false});
                }
                if (ruleVal.additional_incomes[i].value_wildcard_fk_id !== null) {
                    income.controls.value_wildcard_fk_id!.patchValue(
                        ruleVal.additional_incomes[i].value_wildcard_fk_id,
                        {emitEvent: false}
                    );
                }
                if (ruleVal.additional_incomes[i].sender !== null) {
                    income.controls.sender!.patchValue(ruleVal.additional_incomes[i].sender, {emitEvent: false});
                }
                if (ruleVal.additional_incomes[i].sender_wildcard_id !== null) {
                    income.controls.sender_wildcard_id!.patchValue(ruleVal.additional_incomes[i].sender_wildcard_id, {
                        emitEvent: false
                    });
                }
            }
            income.controls.amount!.enable({emitEvent: false});
            income.controls.source_amount!.enable({emitEvent: false});
            income.controls.unique_hash!.enable({emitEvent: false});
            income.controls.percent!.enable({emitEvent: false});
            income.controls.value_wildcard_fk_id!.enable({emitEvent: false});
            income.controls.is_enforced!.patchValue(is_enforced, {emitEvent: false});
        });
    }

    ngOnInit() {
        if (!this.canEditCondition) {
            this.ruleForm.controls.type!.disable();
            this.ruleForm.controls.operator!.disable();
            this.ruleForm.controls.rule_filters!.controls.close_of_escrow_from!.disable();
            this.ruleForm.controls.rule_filters!.controls.close_of_escrow_till!.disable();
            this.ruleForm.controls.rule_filters!.controls.type!.disable();
            this.ruleForm.controls.rule_filters!.controls.status!.disable();
        }

        if (
            [
                FinancialElementModel.type_set.agent,
                FinancialElementModel.type_set.royalty,
                FinancialElementModel.type_set.user_royalty
            ].includes(this.compensationType!)
        ) {
            this.financialElementFG.controls
                .commonRule!.controls.disbursement_template!.valueChanges.pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.syncDisTemplate();
                });
            this.syncDisTemplate();
        }

        if (this.performanceCompensationProvides === FinancialElementModel.provides_set.overhead) {
            this.ruleForm.controls.is_final!.patchValue(false, {emitEvent: false});
        }
        if (this.performanceCompensationProvides === FinancialElementModel.provides_set.participant) {
            this.ruleForm.controls.is_final!.patchValue(false, {emitEvent: false});
        }

        this.ruleForm.controls.type!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                rule.controls.type!.patchValue(next, {emitEvent: false});
            });
        });

        this.ruleForm.controls.operator!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                rule.controls.operator!.patchValue(next, {emitEvent: false});
            });
        });

        this.ruleForm.controls
            .rule_filters!.controls.close_of_escrow_from!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((next) => {
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.rule_filters!.controls.close_of_escrow_from!.patchValue(next, {emitEvent: false});
                });
            });

        this.ruleForm.controls
            .rule_filters!.controls.close_of_escrow_till!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((next) => {
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.rule_filters!.controls.close_of_escrow_till!.patchValue(next, {emitEvent: false});
                });
            });

        this.ruleForm.controls
            .rule_filters!.controls.type!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((next) => {
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.rule_filters!.controls.type!.patchValue(next, {emitEvent: false});
                });
            });

        this.ruleForm.controls
            .rule_filters!.controls.status!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((next) => {
                this.financialElementFG.controls.rules!.controls.forEach((rule) => {
                    rule.controls.rule_filters!.controls.status!.patchValue(next, {emitEvent: false});
                });
            });
    }

    isAncillary(compensationType: string | null) {
        if (typeof compensationType !== 'string') {
            return false;
        }
        return [
            FinancialElementModel.type_set.company,
            FinancialElementModel.type_set.company_income_expense,
            FinancialElementModel.type_set.sales,
            FinancialElementModel.type_set.user_referral,
            FinancialElementModel.type_set.disbursement_template,
            FinancialElementModel.type_set.compensation_expense
        ].includes(compensationType);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
