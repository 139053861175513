import {Component, Input} from '@angular/core';

@Component({
    selector: 'reports-preview-agent-performance',
    templateUrl: './agent-performance.component.html',
    styleUrls: ['./agent-performance.component.scss']
})
export class AgentPerformancePreviewComponent {
    @Input() reportData: AgentReportData[] = [];

    public isExpanded = false;

    toggleExpandSection(agent: AgentReportData, event?: Event): void {
        event?.stopPropagation();
        agent.is_expanded = !agent.is_expanded;
    }

    toggleExpandAllSections() {
        this.isExpanded = !this.isExpanded;
        this.reportData = this.reportData.map((agent) => ({
            ...agent,
            is_expanded: this.isExpanded
        }));
    }
}

interface AgentReportData {
    agent_name: string;
    anniversary_date: string;
    address: string;
    count_seller: number;
    count_buyer: number;
    count_rental_tenant: number;
    count_rental_owner: number;
    count_referral: number;
    count_broker_price_opinion: number;
    agent_split: number;
    sum_volume: number;
    avg_commission_percent: number;
    adjusted_sales_price: number;
    gci: number;
    deals: Deal[];
    is_expanded?: boolean;
}

interface Deal {
    address: string;
    count_seller: number;
    count_buyer: number;
    count_rental_tenant: number;
    count_rental_owner: number;
    count_referral: number;
    count_broker_price_opinion: number;
    agent_split: number;
    sum_volume: number;
    avg_commission_percent: number;
    agent_office_name: string;
}
