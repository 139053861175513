import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../../../services/service.requester';

@Injectable()
export class SummaryPayoutsApiService {
    private url = '/summary-payouts';

    constructor(public requester: ServiceRequester) {}

    startPosting(
        dealIds: number[],
        postingDate: number
    ): Promise<{status: string; payouts_in_processing: Array<number>}> {
        return this.requester.makeMsCall(this.url + '/start-deal-posting', 'POST', {
            deal_ids: dealIds,
            posting_date: postingDate
        });
    }

    unPostDocs(request: {
        type: 'all-payments' | 'all-docs' | null;
        data: Array<{
            deal_id: number;
            payouts: Array<{
                payout_id: number;
                payments: Array<{payment_id: number | null; payment_action: 'void' | 'disconnect' | null}>;
            }>;
        }>;
    }): Promise<{status: string}> {
        return this.requester.makeMsCall(this.url + '/un-post-docs', 'POST', request);
    }
}
