import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {SessionService} from '../../../../services/session.service';
import {SpannerNotificationInterface, UserNotification} from '../../../../models/user-notification';
import {MatDialog} from '@angular/material/dialog';
import {ReportPdfFilesModalComponent} from '../report-pdf-files-modal/report-pdf-files-modal.component';

@Component({
    selector: 'app-notification-badge',
    templateUrl: './notification-badge.component.html',
    styleUrls: ['./notification-badge.component.css']
})
export class NotificationBadgeComponent implements OnInit {
    @Output() markAsFlaggedEvent = new EventEmitter();
    @Output() markAsWarningEvent = new EventEmitter();
    @Output() markAsSeenEvent = new EventEmitter();
    @Output() markAsUnseenEvent = new EventEmitter();
    @Output() reprocessDoneEvent = new EventEmitter();
    @Input() notification: any = {};

    STATUS_WARNING: string = 'warning';
    STATUS_SUCCESS: string = 'success';
    STATUS_ERROR: string = 'error';
    STATUS_START: string = 'start';
    STATUS_INFO: string = 'info';

    ACTION_PREPARE_PROFILES = 'prepare_profiles';
    ACTION_PREPARE_DEALS = 'prepare_deals';
    ACTION_PREPARE_INVOICES = 'prepare_invoices';
    ACTION_SAVE_AS_CSV = 'save_as_csv';
    ACTION_IMPORT_SHIPMENTS_FROM_CSV = 'import_shipments_from_csv';
    ACTION_RECALCULATE = 'recalculate';
    ACTION_REPROCESSING_UPLOADED_FILES = 'reprocessing_uploaded_files';
    ACTION_EXPORT_REPORT_CSV = 'export_report_csv';
    ACTION_EXPORT_REPORT_PDF = 'export_report_pdf';
    ACTION_ADDON_EVENT = 'addon_event';
    IMPORT_PRODUCTS_SERVICES = 'import_products_services';
    IMPORT_LEDGER_ACCOUNTS = 'import_ledger_accounts';

    constructor(
        protected sessionService: SessionService,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {}

    reprocessDone(notification: any) {
        const reprocessedFiles = notification.value.payload.filter((file: any) => file.status === 'success').length;
        if (typeof reprocessedFiles === 'number') {
            return reprocessedFiles;
        } else {
            return 'N/A';
        }
    }

    isSeen(notification: SpannerNotificationInterface) {
        const hasInSeenBy = notification.seen_by.some(
            (id) => this.sessionService.profile && id === this.sessionService.profile.id
        );
        // NOTE: notification.seen_by contains profileIds who marks READ or UNREAD notification depends on notification.seen_by_sense field
        // if notification.seen_by_sense === 'who_marked_as_not_viewed' - seen_by field will have those who mark notification as UNREAD
        // if notification.seen_by_sense === 'who_marked_as_viewed' - seen_by field will have those who mark notification as READ
        if (notification.seen_by_sense === UserNotification.provideSeenBySenseTypes().who_marked_as_not_viewed) {
            return !hasInSeenBy;
        }

        return hasInSeenBy;
    }

    isFlagged(notification: SpannerNotificationInterface) {
        return notification.flagged_by.some(
            (id) => this.sessionService.profile && id === this.sessionService.profile.id
        );
    }

    markAsSeen(notification: any) {
        if (this.isSeen(notification.value)) {
            return;
        }

        this.markAsSeenEvent.emit(notification);
    }

    markAsUnseen(notification: any) {
        if (!this.isSeen(notification.value)) {
            return;
        }

        this.markAsUnseenEvent.emit(notification);
    }

    markAsFlagged(notification: any) {
        const alreadyFlagged = this.isFlagged(notification.value);

        this.markAsFlaggedEvent.emit({notification, alreadyFlagged});
    }

    // getAddonTitle(data) {
    //     try {
    //         return data.metadata.metadata.addon_title.split('_')[0];
    //     } catch (e) {
    //         return 'Addon Event';
    //     }
    // }

    viewReportPdfFiles(notification: SpannerNotificationInterface) {
        this.dialog.open(ReportPdfFilesModalComponent, {
            width: '600px',
            data: {notification: notification}
        });
    }
}
