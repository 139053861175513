<div>
    <mat-card class="report-table" *ngIf="reportData.length">
        <div class="w-100">
            <div class="flex-row font-weight-bold title header">
                <div class="item w-40">
                    <button
                        class="header-actions icon-button"
                        [matTooltip]="isExpanded ? 'Collapse All' : 'Expand All'"
                        (click)="toggleExpandAllSections()"
                    >
                        <mat-icon>{{ isExpanded ? 'unfold_less' : 'unfold_more' }}</mat-icon>
                    </button>
                </div>
                <div class="item w-250">Agent</div>
                <div class="item w-150">Anniversary Year</div>
                <div class="item w-200">Office</div>
                <div class="item w-400">Address</div>
                <div class="item w-200">Total Count</div>
                <div class="item w-200 amount-text"><span>Agent Volume</span></div>
                <div class="item w-200 amount-text"><span>Agent Split</span></div>
                <div class="item w-200 amount-text"><span>Avg Comm %</span></div>
            </div>

            <ng-container *ngFor="let agent of reportData">
                <div class="flex-row w-100 table-body" (click)="toggleExpandSection(agent)">
                    <div class="action-icon item w-40">
                        <button class="material-icons icon-button" (click)="toggleExpandSection(agent, $event)">
                            <ng-container>
                                {{ agent.is_expanded ? 'expand_more' : 'chevron_right' }}
                            </ng-container>
                        </button>
                    </div>
                    <div class="item w-250">{{ agent.agent_name }}</div>
                    <div class="item w-150">{{ agent.anniversary_date }}</div>
                    <div class="item w-200">
                        <!-- office name -->
                    </div>
                    <div class="item w-400">
                        <!-- address -->
                    </div>

                    <div class="item w-200">
                        <div class="count-val">
                            {{
                                agent.count_seller +
                                    agent.count_buyer +
                                    agent.count_rental_tenant +
                                    agent.count_rental_owner +
                                    agent.count_referral +
                                    agent.count_broker_price_opinion | number: '1.0-2'
                            }}
                        </div>
                    </div>

                    <div class="item w-200 amount-text" [ngClass]="{'negative-value': agent.sum_volume < 0}">
                        <span>${{ agent.sum_volume | reportFormatted: 'financial' }}</span>
                    </div>
                    <div class="item w-200 amount-text" [ngClass]="{'negative-value': agent.agent_split < 0}">
                        <span>${{ agent.agent_split | reportFormatted: 'financial' }}</span>
                    </div>
                    <div
                        class="item w-200 amount-text"
                        [ngClass]="{'negative-value': agent.avg_commission_percent < 0}"
                    >
                        <span>{{ agent.avg_commission_percent | reportFormatted: 'financial' }}%</span>
                    </div>
                </div>

                <ng-container *ngIf="agent.is_expanded">
                    <div class="flex-row w-100 table-body sub-item" *ngFor="let deal of agent.deals">
                        <div class="item w-40">
                            <!-- expand/collapse -->
                        </div>
                        <div class="item w-250">
                            <!-- agent name -->
                        </div>
                        <div class="item w-150">
                            <!-- anniversary -->
                        </div>
                        <div class="item w-200">
                            {{ deal.agent_office_name }}
                        </div>
                        <div class="item w-400">{{ deal.address }}</div>

                        <!-- Total count -->
                        <div class="item w-200 d-flex flex-wrap">
                            <div *ngIf="deal.count_seller > 0">
                                <span class="count-val">{{ deal.count_seller }}</span>
                                <span class="text-sm">Listing (Sale)</span>
                            </div>
                            <div *ngIf="deal.count_buyer > 0">
                                <span class="count-val">{{ deal.count_buyer }}</span>
                                <span class="text-sm">Buyer (Sale)</span>
                            </div>
                            <div *ngIf="deal.count_rental_tenant > 0">
                                <span class="count-val">{{ deal.count_rental_tenant }}</span>
                                <span class="text-sm">Tenant (Rental)</span>
                            </div>
                            <div *ngIf="deal.count_rental_owner > 0">
                                <span class="count-val">{{ deal.count_rental_owner }}</span>
                                <span class="text-sm">Landlord (Rental)</span>
                            </div>
                            <div *ngIf="deal.count_referral > 0">
                                <span class="count-val">{{ deal.count_referral }}</span>
                                <span class="text-sm">Referral</span>
                            </div>
                            <div *ngIf="deal.count_broker_price_opinion > 0">
                                <span class="count-val">{{ deal.count_broker_price_opinion }}</span>
                                <span class="text-sm">Broker Price Opinion</span>
                            </div>
                        </div>

                        <div class="item w-200 amount-text" [ngClass]="{'negative-value': deal.sum_volume < 0}">
                            <span>${{ deal.sum_volume | reportFormatted: 'financial' }}</span>
                        </div>
                        <div class="item w-200 amount-text" [ngClass]="{'negative-value': deal.agent_split < 0}">
                            <span>${{ deal.agent_split | reportFormatted: 'financial' }}</span>
                        </div>
                        <div
                            class="item w-200 amount-text"
                            [ngClass]="{'negative-value': deal.avg_commission_percent < 0}"
                        >
                            <span>{{ deal.avg_commission_percent | reportFormatted: 'financial' }}%</span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </mat-card>
    <mat-card class="preview-body" *ngIf="!reportData.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
