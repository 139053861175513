<div [style]="{width: recordPaymentSettingsGroup.controls.payment_mode.value === 'check_record' ? '1272px' : '917px'}">
    <!--Content Container-->
    <div style="padding: 24px 24px 0 24px">
        <!--Header-->
        <div class="d-flex">
            <h2>Record Payment</h2>
            <span class="sp-spacer"></span>
            <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
        </div>

        <!--Payment Settings-->
        <div class="d-flex" [formGroup]="recordPaymentSettingsGroup">
            <div class="w-33 mr-2">
                <app-date-picker
                    [placeholder]="'Payment Date'"
                    [dateControl]="recordPaymentSettingsGroup.controls.paid_date"
                >
                </app-date-picker>
            </div>

            <mat-form-field class="w-33 mr-2">
                <mat-select formControlName="payment_mode">
                    <ng-container *ngFor="let mode of paymentModes">
                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                            <span>{{ mode.title }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Payment Mode</mat-placeholder>
            </mat-form-field>

            <app-ledger-account-selector
                #ledgerAccountPicker
                class="w-33"
                [ledgerAccountControl]="recordPaymentSettingsGroup.controls.ledger_account_id"
                [placeholder]="'Paid Through'"
            >
            </app-ledger-account-selector>
        </div>

        <!--Payouts List for non-check mode-->
        <div *ngIf="recordPaymentSettingsGroup.controls.payment_mode.value !== 'check_record'">
            <!--Table Header-->
            <div class="table-header">
                <div class="w-30">Payment To</div>
                <div class="w-20">Amount</div>
                <div class="w-50">Reference</div>
            </div>

            <!--Items Section-->
            <div style="height: 400px; max-height: 45vh; overflow-y: auto">
                <div *ngIf="itemsArray && itemsArray.length > 0 && itemsArrayBuilt">
                    <!--Item-->
                    <div *ngFor="let payoutGroup of itemsArray.controls; index as idx">
                        <div [formGroup]="payoutGroup" class="list-item">
                            <div class="w-30" style="padding-top: 17px">
                                {{ payoutGroup.controls.pay_to_name.value }}
                            </div>
                            <div class="w-20" style="padding-top: 17px">
                                <div class="amount-label d-flex p-0">
                                    <app-custom-popover
                                        *ngIf="
                                            recordPaymentSettingsGroup.controls.is_need_to_combine_payments.value &&
                                            payoutGroup.controls.sub_payouts &&
                                            payoutGroup.controls.sub_payouts.value &&
                                            payoutGroup.controls.sub_payouts.value.length &&
                                            payoutGroup.controls.sub_payouts.value.length > 1
                                        "
                                        [elementId]="'remainingSubPayout' + idx"
                                        [width]="440"
                                    >
                                        <div class="w-100 p-0">
                                            <ng-container
                                                *ngFor="
                                                    let subPayout of payoutGroup.controls.sub_payouts.value;
                                                    last as last
                                                "
                                                class="w-100 p-0"
                                            >
                                                <div class="d-flex my-3 p-0">
                                                    <span style="font-weight: bold">Deal #{{ subPayout.deal_id }}</span>
                                                    <span class="sp-spacer"></span>
                                                    <span>{{ subPayout.amount | currency }}</span>
                                                </div>
                                                <mat-divider *ngIf="!last" class="mb-2"></mat-divider>
                                            </ng-container>
                                        </div>
                                    </app-custom-popover>
                                    <span
                                        *ngIf="
                                            !payoutGroup.controls.sub_payouts ||
                                            !payoutGroup.controls.sub_payouts.value ||
                                            !payoutGroup.controls.sub_payouts.value.length ||
                                            payoutGroup.controls.sub_payouts.value.length === 1
                                        "
                                        >{{ payoutGroup.controls.amount.value | currency }}</span
                                    >
                                    <span
                                        [id]="'remainingSubPayout' + idx"
                                        class="combined-payouts"
                                        *ngIf="
                                            payoutGroup.controls.sub_payouts &&
                                            payoutGroup.controls.sub_payouts.value &&
                                            payoutGroup.controls.sub_payouts.value.length &&
                                            payoutGroup.controls.sub_payouts.value.length > 1
                                        "
                                        [matBadge]="payoutGroup.controls.sub_payouts.value.length"
                                        matBadgeOverlap="true"
                                        >{{ payoutGroup.controls.amount.value | currency }}</span
                                    >
                                </div>
                            </div>
                            <div class="w-50">
                                <mat-form-field class="w-100">
                                    <textarea matInput type="text" formControlName="reference"> </textarea>
                                    <mat-label *ngIf="!payoutGroup.controls.reference.value">Reference</mat-label>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!itemsArrayBuilt" class="w-100 text-center">Data Loading...</div>
                <div *ngIf="!itemsArray || (itemsArray.length === 0 && itemsArrayBuilt)" class="w-100 text-center mt-2">
                    Nothing to Display
                </div>
            </div>
        </div>

        <!--Payouts List for check mode-->
        <div *ngIf="recordPaymentSettingsGroup.controls.payment_mode.value === 'check_record'">
            <!--Table Header-->
            <div class="table-header">
                <div class="w-20">Payment To</div>
                <div class="w-15">Amount</div>
                <div class="w-20">Memo</div>
                <div class="w-30">Reference</div>
                <div class="w-15">Check Number</div>
            </div>

            <!--Items Section-->
            <div style="height: 400px; max-height: 45vh; overflow-y: auto">
                <div *ngIf="itemsArray && itemsArray.length > 0 && itemsArrayBuilt">
                    <!--Item-->
                    <div *ngFor="let payoutGroup of itemsArray.controls; index as idx">
                        <div [formGroup]="payoutGroup" class="list-item">
                            <div class="w-20" style="padding-top: 17px">
                                {{ payoutGroup.controls.pay_to_name.value }}
                            </div>
                            <div class="w-15" style="padding-top: 17px">
                                <div class="amount-label d-flex p-0">
                                    <app-custom-popover
                                        *ngIf="
                                            recordPaymentSettingsGroup.controls.is_need_to_combine_payments.value &&
                                            payoutGroup.controls.sub_payouts &&
                                            payoutGroup.controls.sub_payouts.value &&
                                            payoutGroup.controls.sub_payouts.value.length &&
                                            payoutGroup.controls.sub_payouts.value.length > 1
                                        "
                                        [elementId]="'remainingSubPayout' + idx"
                                        [width]="440"
                                    >
                                        <div class="w-100 p-0">
                                            <ng-container
                                                *ngFor="
                                                    let subPayout of payoutGroup.controls.sub_payouts.value;
                                                    last as last
                                                "
                                                class="w-100 p-0"
                                            >
                                                <div class="d-flex my-3 p-0">
                                                    <span style="font-weight: bold">Deal #{{ subPayout.deal_id }}</span>
                                                    <span class="sp-spacer"></span>
                                                    <span>{{ subPayout.amount | currency }}</span>
                                                </div>
                                                <mat-divider *ngIf="!last" class="mb-2"></mat-divider>
                                            </ng-container>
                                        </div>
                                    </app-custom-popover>
                                    <span
                                        *ngIf="
                                            !payoutGroup.controls.sub_payouts ||
                                            !payoutGroup.controls.sub_payouts.value ||
                                            !payoutGroup.controls.sub_payouts.value.length ||
                                            payoutGroup.controls.sub_payouts.value.length === 1
                                        "
                                        >{{ payoutGroup.controls.amount.value | currency }}</span
                                    >
                                    <span
                                        [id]="'remainingSubPayout' + idx"
                                        class="combined-payouts"
                                        *ngIf="
                                            payoutGroup.controls.sub_payouts &&
                                            payoutGroup.controls.sub_payouts.value &&
                                            payoutGroup.controls.sub_payouts.value.length &&
                                            payoutGroup.controls.sub_payouts.value.length > 1
                                        "
                                        [matBadge]="payoutGroup.controls.sub_payouts.value.length"
                                        matBadgeOverlap="true"
                                        >{{ payoutGroup.controls.amount.value | currency }}</span
                                    >
                                </div>
                            </div>
                            <div class="w-20">
                                <mat-form-field class="w-100">
                                    <textarea matInput type="text" formControlName="memo"> </textarea>
                                    <mat-label *ngIf="!payoutGroup.controls.memo.value">Memo</mat-label>
                                </mat-form-field>
                            </div>
                            <div class="w-30">
                                <mat-form-field class="w-100">
                                    <textarea matInput type="text" formControlName="reference"> </textarea>
                                    <mat-label *ngIf="!payoutGroup.controls.reference.value">Reference</mat-label>
                                </mat-form-field>
                            </div>
                            <div class="w-15">
                                <mat-form-field class="w-90">
                                    <input matInput autocomplete="off" type="search" formControlName="check_number" />
                                    <mat-label *ngIf="!payoutGroup.controls.check_number.value">Check Number</mat-label>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!itemsArrayBuilt" class="w-100 text-center mt-2">Data Loading...</div>
                <div *ngIf="!itemsArray || (itemsArray.length === 0 && itemsArrayBuilt)" class="w-100 text-center mt-2">
                    Nothing to Display
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="action-section">
        <div class="d-flex w-50 mr-2" [formGroup]="recordPaymentSettingsGroup" *ngIf="!isCombineFeatureDisabled">
            <mat-slide-toggle formControlName="is_need_to_combine_payments" (change)="setupItemsArray()"
                >Combine Payments</mat-slide-toggle
            >
        </div>
        <span class="sp-spacer"></span>
        <button mat-button color="primary" class="ml-2" (click)="dialogRef.close(false)" tabindex="-1">Back</button>
        <button
            mat-raised-button
            class="ml-1"
            [disabled]="!itemsArrayBuilt || !itemsArray || (itemsArray.length === 0 && itemsArrayBuilt)"
            color="primary"
            (click)="continue()"
            tabindex="1"
        >
            Save & Proceed
        </button>
    </div>
</div>
