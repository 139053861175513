import {Injectable} from '@angular/core';
import {Deal} from '../../../../models/deal';
import {ServiceRequester} from '../../../../services/service.requester';

@Injectable()
export class DealApproveApiService {
    url = '/deal-approval/';
    constructor(public requester: ServiceRequester) {}

    postRequestDealApproval(deal: Deal) {
        return this.requester.makeMsCall(this.url + deal.id, 'POST');
    }

    putApproveDeal(deal: Deal, force: boolean = false, saveDeal: boolean = true) {
        // commented by AntonV, because "just approve" currently produce an error with past_unapproved/future_approved deals
        // and in general seems we can't approve deal just by ID. Ideally need deal_version and approve it only if
        // "approver" will pass the latest version, i.e. he approves exact version of deal.
        // if (!saveDeal) {
        //     return this.requester.makeMsCall(this.url + deal.id, 'PUT', {force});
        // }
        return this.requester.makeMsCall(`${this.url}save-and-approve/${deal.id}`, 'PUT', {deal_data: deal, force});
    }

    revertDisbursementApproval(dealId: number, force: boolean = false) {
        return this.requester.makeMsCall(this.url + dealId + '/revert/', 'PUT', {force});
    }

    getDisbursementPdf(dealId: number) {
        return this.requester.makeMsCall(this.url + dealId + '/view-pdf/', 'GET');
    }

    getDualDisbursementPdf(dealId: number) {
        return this.requester.makeMsCall(this.url + dealId + '/dual-pdf/', 'GET');
    }

    getTradeSheetPdf(dealId: number | string, format: 'condensed' | 'expanded' | 'expanded-with-notes' | 'broker') {
        return this.requester.makeMsCall(this.url + dealId + `/trade-sheet/${format}`, 'GET');
    }

    postUnmarkEscrowPaid(dealId: number) {
        return this.requester.makeMsCall('/close-deal/' + dealId + '/unmark-escrow-paid', 'POST');
    }

    emailDisbursementPdf(dealId: number, data: {emails: string[]; pdfSelection: string}) {
        return this.requester.makeMsCall(this.url + dealId + '/email-pdf/', 'POST', data);
    }

    sendDisbursementPdfToSkyslope(dealId: number) {
        return this.requester.makeMsCall(this.url + dealId + '/upload-cda/skyslope', 'GET');
    }
}
