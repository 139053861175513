import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {FinancialElementModel} from '../models/financial-element.model';
import {ConditionEntity} from '../../../../models/condition.entity';
import {CalculationSettingsService} from '../../../../services/calculation-settings.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-company-compensation-condition',
    template: `
        <div>
            <mat-form-field style="margin-right: 4px">
                <mat-select placeholder="Condition Type" [formControl]="condForm!.controls.type!">
                    <mat-option *ngFor="let type of object_keys(typeOptions)" [value]="type">
                        {{ typeOptions[type] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <app-product-service-selector
                class="four-width"
                *ngIf="
                    condForm!.controls.type!.value === 'total_product_income' ||
                    condForm!.controls.type!.value === 'total_product_expense'
                "
                [placeholder]="'Product/Service'"
                [productServiceControl]="condForm!.controls.product_fk_id!"
                [validators]="null"
                [disabled]="disabled"
                [disabledReselect]="disabledProductReselect"
                [allowedProductsIdsOnly]="true"
                [allowedProductsIdsToSelect]="capableMetricsByProductIds"
                style="display: inline-block; position: relative; text-align: left; margin-right: 4px;"
            >
            </app-product-service-selector>

            <mat-form-field style="margin-right: 4px">
                <mat-select placeholder="Condition Operator" [formControl]="condForm!.controls.operator!">
                    <mat-option *ngFor="let operator of object_keys(operatorOptions)" [value]="operator">
                        {{ operatorOptions[operator] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 4px">
                <input matInput placeholder="Value" [formControl]="condForm!.controls.value!" />
            </mat-form-field>
        </div>
    `
})
export class ConditionComponent implements OnInit, OnChanges {
    private unsubscribe: Subject<void> = new Subject();
    @Input() typeOptions: {[key: string]: any} = {};
    @Input() operatorOptions: {[key: string]: any} = {};
    @Input() disabled: boolean = false;
    @Input() disabledProductReselect: boolean = false;
    @Input() condForm: GenericFormGroup<ConditionEntity> = new GenericFormGroup(new ConditionEntity());
    @Input() performanceCompensationProvides: string = FinancialElementModel.provides_set.compensation_plan;
    @Input() compensationType: string | null = null;

    @Input() show_up: boolean = false;
    @Input() show_down: boolean = false;

    @Output() up = new EventEmitter();
    @Output() down = new EventEmitter();
    @Output() delete = new EventEmitter();
    capableMetricsByProductIds: number[] = [];

    constructor(protected calculationSettingsService: CalculationSettingsService) {}

    object_keys(obj: {[key: string]: any}): string[] {
        if (!obj) {
            return [];
        }
        return Object.keys(obj);
    }

    ngOnInit() {
        this.calculationSettingsService.calculationSettings$.pipe(takeUntil(this.unsubscribe)).subscribe((settings) => {
            this.capableMetricsByProductIds = settings.capable_metrics_by_product_ids!;
        });
        this.calculationSettingsService.getCalculationSettings();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.condForm.controls.type?.disable({emitEvent: false});
            this.condForm.controls.operator?.disable({emitEvent: false});
            this.condForm.controls.value?.disable({emitEvent: false});
            this.condForm.controls.product_fk_id?.disable({emitEvent: false});
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
