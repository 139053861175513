import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {firstValueFrom, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {IContact, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {FeatureFlagsService} from '../../../../../../feature-flags/feature-flags.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment-timezone';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {FormGroupArray} from '../../../../../../../typings/common';
import {
    IRemainingPayoutsWithAdditionalInfo,
    IProcessByMoneyTransferEftDialogData,
    IDataToBeProcessed
} from '../../../../remaining-payouts/types/remaining-payouts.types';
import {RemainingPayoutsApiService} from '../../../services/remaining-payouts.api-service';
import {RemainingPayoutsDataService} from '../../../services/remaining-payouts.data-service';
import {MatOptionSelectionChange} from '@angular/material/core';
import {ZipiFinancialIframeDialogComponent} from '../../../../../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {IZipiFinancialIframeDialogData} from '../../../../../../../typings/zipi-financial-iframe';
import {MarketplaceSource} from '../../../../../../account-info/marketplace/marketplace.source';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {ShipperContactsService} from '../../../../../../../services/api/shipper.contacts.service';
import {NotificationsServiceZipi} from '../../../../../../notifications/notifications.service';

@Component({
    selector: 'pay-remaining-payouts-by-money-transfer-eft-dialog',
    templateUrl: 'process-remaining-payouts-by-money-transfer-eft-dialog.component.html',
    styleUrls: ['./process-remaining-payouts-by-money-transfer-eft-dialog.component.scss']
})
export class ProcessRemainingPayoutsByMoneyTransferEftDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    payoutIds: number[] = [];
    availableGateways: IPaymentGateway[] = [];
    selectedDriver: 'plaid' | 'moov' | 'muhnee' | 'tpp' | 'payload' | null = null;
    fullPayoutsInfo: IRemainingPayoutsWithAdditionalInfo[] = [];

    moneyTransferPaymentSettingsGroup: UntypedFormGroup;
    itemsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    itemsArrayBuilt: boolean = false;

    minScheduleDate = moment().add(1, 'days').toDate();
    maxScheduleDate = moment().add(28, 'days').toDate();

    submitAvailable: boolean = false;

    isCombineFeatureDisabled: boolean = false;

    // feature_flags
    isMoneyTransfersFeatureFlagEnabled: boolean = false;
    isScheduledPaymentFeatureFlagEnabled: boolean = false;
    isPayloadCoFeatureFlagEnabled: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ProcessRemainingPayoutsByMoneyTransferEftDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IProcessByMoneyTransferEftDialogData,
        private remainingPayoutsService: RemainingPayoutsApiService,
        private remainingPayoutsDataService: RemainingPayoutsDataService,
        protected featureFlagsService: FeatureFlagsService,
        private fb: UntypedFormBuilder,
        private marketplaceSource: MarketplaceSource,
        private companyGatewayService: CompanyGatewayService,
        private contactsService: ShipperContactsService,
        public dialog: MatDialog,
        private notificationServiceZipi: NotificationsServiceZipi
    ) {
        this.moneyTransferPaymentSettingsGroup = this.fb.group({
            payment_mode: ['zipi_financial', [Validators.required]],
            invoice_date: [moment().format('YYYYMMDD'), [Validators.required]],
            paid_by__payment_method_fk_id: [null, [Validators.required]],
            is_need_to_combine_payments: [true, []],
            is_schedule_enabled: [false, []],
            scheduled_date: ['', []]
        });
    }

    ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isMoneyTransfersFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:money_transfer'
                );
                this.isScheduledPaymentFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:scheduled_transfer'
                );
                this.isPayloadCoFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:payload_co'
                );
            });

        this.payoutIds = this.data.payoutIds;
        this.availableGateways = this.data.eftGateways;
        if (this.data.isCombineDisabled) {
            this.isCombineFeatureDisabled = this.data.isCombineDisabled;
            this.moneyTransferPaymentSettingsGroup.controls.is_need_to_combine_payments.setValue(false);
        }
        if (this.payoutIds && this.payoutIds.length > 0) {
            this.loadPayoutsInfo(this.payoutIds);
        } else {
            this.close();
        }
    }

    loadPayoutsInfo(payoutIds: Array<number>) {
        this.remainingPayoutsService
            .getRemainingPayoutsInfo(payoutIds)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((payouts) => {
                this.fullPayoutsInfo = payouts;
                this.setupItemsArray();
            });
    }

    setupItemsArray() {
        if (this.fullPayoutsInfo && this.fullPayoutsInfo.length > 0) {
            let preparedItemsForArray: any[] = [];
            if (this.moneyTransferPaymentSettingsGroup.controls.is_need_to_combine_payments.value) {
                preparedItemsForArray = this.remainingPayoutsDataService.combinePayouts(this.fullPayoutsInfo);
            } else {
                preparedItemsForArray = this.fullPayoutsInfo;
            }
            this.itemsArray.clear();
            for (const item of preparedItemsForArray) {
                this.itemsArray.push(
                    this.remainingPayoutsDataService.createItem(
                        item,
                        this.moneyTransferPaymentSettingsGroup.controls.is_need_to_combine_payments.value
                    )
                );
            }
            if (
                this.fullPayoutsInfo.some(
                    (payout) => payout.is_available_for_zipi_pay || payout.is_available_for_payloadco_zipi_pay
                )
            ) {
                this.submitAvailable = true;
            }
        }
        this.itemsArrayBuilt = true;
    }

    removeItem(idx: number) {
        this.itemsArray.removeAt(idx);
    }

    scheduleModeChange($event: MatSlideToggleChange) {
        if ($event.checked) {
            this.moneyTransferPaymentSettingsGroup.controls.is_schedule_enabled.setValue(true);
            this.moneyTransferPaymentSettingsGroup.controls.scheduled_date.addValidators(Validators.required);
            this.moneyTransferPaymentSettingsGroup.controls.scheduled_date.updateValueAndValidity();
            this.minScheduleDate = moment().add(1, 'days').toDate();
            this.maxScheduleDate = moment().add(28, 'days').toDate();
        } else {
            this.moneyTransferPaymentSettingsGroup.controls.is_schedule_enabled.setValue(false);
            this.moneyTransferPaymentSettingsGroup.controls.scheduled_date.clearValidators();
            this.moneyTransferPaymentSettingsGroup.controls.scheduled_date.setValue(null);
            this.moneyTransferPaymentSettingsGroup.controls.scheduled_date.updateValueAndValidity();
        }
    }

    methodChanged($event: MatOptionSelectionChange, gateway: IPaymentGateway) {
        if ($event.isUserInput) {
            this.selectedDriver = gateway.driver_type;
            this.prefillPaymentMethod();
        }
    }

    prefillPaymentMethod() {
        this.itemsArray.controls.forEach((control) => {
            if (this.selectedDriver === 'payload') {
                const payloadCoMethods = control.controls.payloadco_receive_only_methods.value;
                const payloadCoMethodId =
                    payloadCoMethods && payloadCoMethods.length > 0 ? payloadCoMethods[0].payment_method_id : null;
                control.controls.pay_to__payment_method_fk_id.setValue(payloadCoMethodId);
                control.controls.pay_to__payment_method_fk_id.setValidators([Validators.required]);
                control.controls.pay_to__payment_method_fk_id.updateValueAndValidity();
            } else if (control.controls.is_available_for_zipi_pay.value) {
                control.controls.pay_to__payment_method_fk_id.setValue(null);
                control.controls.pay_to__payment_method_fk_id.clearValidators();
                control.controls.pay_to__payment_method_fk_id.updateValueAndValidity();
            } else {
                control.controls.pay_to__payment_method_fk_id.setValue(null);
                control.controls.pay_to__payment_method_fk_id.setValidators([Validators.required]);
                control.controls.pay_to__payment_method_fk_id.updateValueAndValidity();
            }
        });
    }

    async setupZipiFinancialForContact(
        methodType: 'contact_bank',
        driverType: 'payload',
        payoutInfo: IRemainingPayoutsWithAdditionalInfo
    ) {
        let customerRef = null;
        const addons = await firstValueFrom(this.marketplaceSource.addonsChangeEvent);
        if (!addons) {
            console.error('Cannot find addons');
            return;
        }
        const zipiFinancialInstance = addons.filter(
            (instance) => instance.addon && instance.addon.slug === 'zipi_financial_integration'
        )[0];
        if (!zipiFinancialInstance) {
            console.error('Cannot find addon instance');
            return;
        }
        customerRef = zipiFinancialInstance.settings?.external_customer_ref;
        if (!customerRef) {
            console.error('Cannot find reference to Customer.');
            return;
        }
        const contact: IContact = await firstValueFrom(this.contactsService.getContactById(payoutInfo.contact_id));
        let gateway = null;
        if (methodType === 'contact_bank') {
            gateway = await firstValueFrom(
                this.companyGatewayService.getContactZipiFinancialReceiveOnlyGateway(
                    payoutInfo.contact_id,
                    driverType,
                    'bank'
                )
            );
        }

        let mainPerson = null;
        if (contact && contact.contact_persons && contact.contact_persons.length > 0) {
            mainPerson = contact.contact_persons.find((person) => person.type === 'main_person');
        }
        const contactData = {
            company_name: contact?.company_name ? contact?.company_name : '',
            email: mainPerson && mainPerson.email ? mainPerson.email : '',
            first_name: mainPerson && mainPerson.first_name ? mainPerson.first_name : '',
            last_name: mainPerson && mainPerson.last_name ? mainPerson.last_name : '',
            nick_name: mainPerson && mainPerson.preferred_name ? mainPerson.preferred_name : ''
        };
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: payoutInfo.contact_id,
                    gateway: gateway,
                    ownGateway: undefined,
                    driverType: driverType,
                    paymentMethod: null,
                    methodType: methodType,
                    accessMethod: 'internal',
                    isUniversal: true,
                    customerId: customerRef,
                    prefillData: contactData,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                }
                if (result.success) {
                    this.refreshPayoutsInfo();
                } else {
                    this.prefillPaymentMethod();
                }
            });
    }

    refreshPayoutsInfo() {
        this.itemsArrayBuilt = false;
        this.moneyTransferPaymentSettingsGroup.controls.paid_by__payment_method_fk_id.setValue(null);
        this.selectedDriver = null;
        this.loadPayoutsInfo(this.payoutIds);
    }

    continue() {
        if (this.moneyTransferPaymentSettingsGroup.invalid || this.itemsArray.invalid) {
            this.itemsArray.markAllAsTouched();
            this.moneyTransferPaymentSettingsGroup.markAllAsTouched();
            return;
        }

        const result: IDataToBeProcessed = {
            processing_mode: 'money_transfer',
            money_transfer_payment_options: this.moneyTransferPaymentSettingsGroup.getRawValue(),
            payouts: this.remainingPayoutsDataService.separatePayouts(
                this.itemsArray
                    .getRawValue()
                    .filter(
                        (payout) =>
                            (this.selectedDriver !== 'payload' && payout.is_available_for_zipi_pay) ||
                            (this.selectedDriver === 'payload' && payout.is_available_for_payloadco_zipi_pay)
                    )
            )
        };
        if (result.money_transfer_payment_options && result.money_transfer_payment_options.is_schedule_enabled) {
            result.money_transfer_payment_options.scheduled_date = moment(
                result.money_transfer_payment_options?.scheduled_date
            ).format('YYYYMMDD');
        }
        this.dialogRef.close(result);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
