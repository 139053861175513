import {Routes, RouterModule} from '@angular/router';

import {FinancePageComponent} from './components/finance-page/finance-page.component';
import {AuthBootstrapService} from 'app/services/resolvers/auth-bootstrap.service';
import {UserLayoutComponent} from 'app/layouts/user-layout.component';
import {InvoicesComponent} from './components/invoice/invoices/invoices.component';
import {RecurringInvoicesComponent} from './components/recurring-invoice/recurring-invoices/recurring-invoices.component';
import {EditRecurringInvoiceComponent} from './components/recurring-invoice/edit-recurring-invoice/edit-recurring-invoice.component';
import {GuestLayoutComponent} from '../../layouts/guest-layout.component';
import {InvoicePageComponent} from './components/invoice/invoice-page/invoice-page.component';
import {BillsComponent} from './components/bills/billls/bills.component';
import {BillPageComponent} from './components/bills/bill-page/bill-page.component';
import {PurchasesPageComponent} from './components/purchases-page/purchases-page.component';
import {PaymentsReceivedListComponent} from './components/payments-received/payments-received-list/payments-received-list.component';
import {PaymentsMadeListComponent} from './components/payments-made/payments-made-list/payments-made-list.component';
import {EditInvoicePageComponent} from './components/invoice/edit-invoice-page/edit-invoice-page.component';
import {EditBillComponent} from './components/bills/edit-bill/edit-bill.component';
import {ExpenseListComponent} from './components/expense/expense-list/expense-list.component';
import {ExpensePageComponent} from './components/expense/expense-page/expense-page.component';
import {ExpenseEditComponent} from './components/expense/expense-edit/expense-edit.component';
import {CreditNotesComponent} from './components/credit-notes/credit-notes.component';
import {EditCreditNoteComponent} from './components/credit-notes/edit-credit-note/edit-credit-note.component';
import {EditVendorCreditComponent} from './components/vendor-credits/edit-vendor-credit/edit-vendor-credit.component';
import {VendorCreditsComponent} from './components/vendor-credits/vendor-credits.component';
import {ViewCreditNoteComponent} from './components/credit-notes/view-credit-note/view-credit-note.component';
import {ViewVendorCreditComponent} from './components/vendor-credits/view-vendor-credit/view-vendor-credit.component';
import {CreatePaymentReceivedComponent} from './components/payments-received/create-payment-received/create-payment-received.component';
import {PaymentReceivedPageComponent} from './components/payments-received/payment-received-page/payment-received-page.component';
import {CreatePaymentMadeComponent} from './components/payments-made/create-payment-made/create-payment-made.component';
import {PaymentMadePageComponent} from './components/payments-made/payment-made-page/payment-made-page.component';
import {AdvancesComponent} from './components/lending/advances/advances.component';
import {EditAdvancePageComponent} from './components/lending/edit-advance-page/edit-advance-page.component';
import {AdvancePageComponent} from './components/lending/advance-page/advance-page.component';
import {GarnishmentsComponent} from './components/lending/garnishments/garnishments.component';
import {EditGarnishmentPageComponent} from './components/lending/edit-garnishment-page/edit-garnishment-page.component';
import {GarnishmentPageComponent} from './components/lending/garnishment-page/garnishment-page.component';
import {InvoiceForApprovePageComponent} from './components/bills/invoice-for-approve-page/invoice-for-approve-page.component';
import {BillForApprovePageComponent} from './components/invoice/bill-for-approve-page/bill-for-approve-page.component';
import {SourceVendorCreditComponent} from './components/credit-notes/source-vendor-credit/source-vendor-credit.component';
import {SourceVendorCreditListComponent} from './components/credit-notes/source-vendor-credit-list/source-vendor-credit-list.component';
import {SourceCreditNoteComponent} from './components/vendor-credits/source-credit-note/source-credit-note.component';
import {BillsForApproveListComponent} from './components/invoice/bills-for-approve-list/bills-for-approve-list.component';
import {InvoicesForApproveListComponent} from './components/bills/invoices-for-approve-list/invoices-for-approve-list.component';
import {UserLoansComponent} from './components/lending/user-loans/user-loans.component';
import {UserLoanPageComponent} from './components/lending/user-loan-page/user-loan-page.component';
import {PayoutForApproveAsBillPageComponent} from './components/bills/payout-for-approve-as-bill-page/payout-for-approve-as-bill-page.component';
import {PayoutForApproveAsInvoicePageComponent} from './components/invoice/payout-for-approve-as-invoice-page/payout-for-approve-as-invoice-page.component';
import {RecurringCreditNotesListComponent} from './components/recurring-credit-note/recurring-credit-notes-list/recurring-credit-notes-list.component';
import {EditRecurringCreditNoteComponent} from './components/recurring-credit-note/edit-recurring-credit-note/edit-recurring-credit-note.component';
import {RequestListComponent} from './components/deal-finance/deal-finance-deposit-request/request-list/request-list.component';
import {RequestPageComponent} from './components/deal-finance/deal-finance-deposit-request/request-page/request-page.component';
import {SourceCreditNotesListComponent} from './components/vendor-credits/source-credit-notes-list/source-credit-notes-list.component';
import {PaymentReceivedRefundComponent} from './components/refunds/payment-received-refund/payment-received-refund.component';
import {AccessGuardService} from '../../services/resolvers/access-guard.service';
import {RecurringBillListComponent} from './components/recurring-bill/recurring-bill-list/recurring-bill-list.component';
import {RecurringBillEditComponent} from './components/recurring-bill/recurring-bill-edit/recurring-bill-edit.component';
import {ReleaseListComponent} from './components/deal-finance/deal-finance-deposit-release/release-list/release-list.component';
import {SpeedupTransferPublicPageComponent} from './components/speedup-transfer-public-page/speedup-transfer-public-page.component';
import {InvoicePublicPageV2Component} from './components/invoice-public/invoice-public-page-v2/invoice-public-page-v2.component';
import {StatementComponent} from '@app/modules/finance/components/statement/statement.component';
import {StatementPublicPageComponent} from '@app/modules/finance/components/statement-public/statement-public-page/statement-public-page.component';

export const routes: Routes = [
    {
        // path: 'finance',
        path: 'sales',
        component: UserLayoutComponent,
        resolve: {authBootstrap: AuthBootstrapService},
        runGuardsAndResolvers: 'always',
        canActivate: [AccessGuardService],
        data: {
            access: {rule: {sales__access: true}}
        },
        children: [
            {
                path: '',
                component: FinancePageComponent,
                children: [
                    {path: '', pathMatch: 'full', redirectTo: '/sales/invoices'},
                    {
                        path: 'invoices',
                        children: [
                            {path: '', component: InvoicesComponent},
                            {
                                path: 'edit/:id',
                                component: EditInvoicePageComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {sales__manage_invoices: true}}
                                }
                            },
                            {
                                path: 'create',
                                component: EditInvoicePageComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {sales__manage_invoices: true}}
                                }
                            },
                            {
                                path: 'recurring',
                                children: [
                                    {path: '', component: RecurringInvoicesComponent},
                                    {
                                        path: ':id',
                                        component: EditRecurringInvoiceComponent,
                                        canActivate: [AccessGuardService],
                                        data: {
                                            access: {rule: {sales__manage_recurring_invoices: true}}
                                        }
                                    },
                                    {
                                        path: 'create',
                                        component: EditRecurringInvoiceComponent,
                                        canActivate: [AccessGuardService],
                                        data: {
                                            access: {rule: {sales__manage_recurring_invoices: true}}
                                        }
                                    }
                                ],
                                canActivate: [AccessGuardService],
                                data: {
                                    title: 'Recurring Invoices',
                                    access: {rule: {sales__view_recurring_invoices: true}}
                                }
                            },
                            {path: ':id', component: InvoicePageComponent}
                        ],
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Invoices',
                            access: {rule: {sales__view_invoices: true}}
                        }
                    },
                    {
                        path: 'sourcedocuments',
                        children: [
                            {path: '', component: BillsForApproveListComponent},
                            {
                                path: 'approve/:id',
                                component: BillForApprovePageComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {sales__manage_source_documents: true}}
                                }
                            }
                        ],
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Invoices',
                            access: {rule: {sales__view_source_documents: true}}
                        }
                    },

                    {
                        path: 'payout',
                        children: [
                            {path: '', component: BillsForApproveListComponent},
                            {path: 'approve/:id', component: PayoutForApproveAsInvoicePageComponent}
                        ],
                        data: {
                            title: 'Invoices'
                        }
                    },
                    {
                        path: 'paymentsreceived',
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Payments Received',
                            access: {rule: {sales__view_payments_received: true}}
                        },
                        children: [
                            {path: '', component: PaymentsReceivedListComponent},
                            {
                                path: 'create',
                                component: CreatePaymentReceivedComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {sales__manage_payments_received: true}}
                                }
                            },
                            {
                                path: 'edit/:id',
                                component: CreatePaymentReceivedComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {sales__manage_payments_received: true}}
                                }
                            },
                            {
                                path: 'refund/:id',
                                component: PaymentReceivedRefundComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {sales__manage_payments_received: true}}
                                }
                            },
                            {path: ':id', component: PaymentReceivedPageComponent}
                        ]
                    },
                    {
                        path: 'creditnotes',
                        children: [
                            {path: '', component: CreditNotesComponent},
                            {
                                path: 'create',
                                component: EditCreditNoteComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {sales__manage_customer_credits: true}}
                                }
                            },
                            {
                                path: 'recurring',
                                children: [
                                    {path: '', component: RecurringCreditNotesListComponent},
                                    {
                                        path: 'create',
                                        component: EditRecurringCreditNoteComponent,
                                        canActivate: [AccessGuardService],
                                        data: {
                                            access: {rule: {sales__manage_customer_credits: true}}
                                        }
                                    },
                                    {
                                        path: ':id/edit',
                                        component: EditRecurringCreditNoteComponent,
                                        canActivate: [AccessGuardService],
                                        data: {
                                            access: {rule: {sales__manage_customer_credits: true}}
                                        }
                                    }
                                ],
                                data: {
                                    title: 'Recurring Credit Notes'
                                }
                            },
                            {
                                path: ':id/edit',
                                component: EditCreditNoteComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {sales__manage_customer_credits: true}}
                                }
                            },
                            {path: ':id', component: ViewCreditNoteComponent}
                        ],
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Credit Notes',
                            access: {rule: {sales__view_customer_credits: true}}
                        }
                    },
                    {
                        path: 'statements',
                        children: [{path: '', component: StatementComponent}],
                        data: {
                            title: 'Statements',
                            access: {rule: {sales__manage_statements: true}}
                        }
                    },
                    {
                        path: 'sourcecredits',
                        children: [
                            {path: '', component: SourceVendorCreditListComponent},
                            {path: ':id', component: SourceVendorCreditComponent}
                        ],
                        data: {
                            title: 'Credit Notes',
                            access: {rule: {sales__view_source_documents: true}}
                        }
                    }
                ]
            }
        ]
    },
    {
        path: 'purchases',
        component: UserLayoutComponent,
        resolve: {authBootstrap: AuthBootstrapService},
        canActivate: [AccessGuardService],
        data: {
            access: {rule: {purchases__access: true}}
        },
        children: [
            {
                path: '',
                component: PurchasesPageComponent,
                children: [
                    {
                        path: 'payments',
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Payments Made',
                            access: {rule: {purchases__view_payments_made: true}}
                        },
                        children: [
                            {path: '', component: PaymentsMadeListComponent},
                            {
                                path: 'create',
                                component: CreatePaymentMadeComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_payments_made: true}}
                                }
                            },
                            {
                                path: 'edit/:id',
                                component: CreatePaymentMadeComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_payments_made: true}}
                                }
                            },
                            {path: ':id', component: PaymentMadePageComponent}
                        ]
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/purchases/bills'
                    },
                    {
                        path: 'bills',
                        children: [
                            {path: '', component: BillsComponent},
                            {
                                path: 'create',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_bills: true}}
                                },
                                component: EditBillComponent
                            },
                            {
                                path: 'edit/:id',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_bills: true}}
                                },
                                component: EditBillComponent
                            },
                            {
                                path: 'recurring',
                                children: [
                                    {path: '', component: RecurringBillListComponent},
                                    {
                                        path: ':id',
                                        component: RecurringBillEditComponent,
                                        data: {
                                            access: {rule: {purchases__manage_recurring_bills: true}}
                                        }
                                    },
                                    {
                                        path: 'create',
                                        component: RecurringBillEditComponent,
                                        data: {
                                            access: {rule: {purchases__manage_recurring_bills: true}}
                                        }
                                    }
                                ],
                                data: {
                                    title: 'Recurring Bills',
                                    access: {rule: {purchases__view_recurring_bills: true}}
                                }
                            },
                            {path: ':id', component: BillPageComponent},
                            {
                                path: ':id/checkpayment',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_bills: true}}
                                },
                                component: CreatePaymentMadeComponent
                            }
                        ],
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Bills',
                            access: {rule: {purchases__view_bills: true}}
                        }
                    },

                    {
                        path: 'sourcedocuments',
                        children: [
                            {path: '', component: InvoicesForApproveListComponent},
                            {
                                path: 'approve/:id',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_source_documents: true}}
                                },
                                component: InvoiceForApprovePageComponent
                            }
                        ],
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Bills',
                            access: {rule: {purchases__view_source_documents: true}}
                        }
                    },

                    {
                        path: 'payout',
                        canActivate: [AccessGuardService],
                        children: [
                            {path: '', component: InvoicesForApproveListComponent},
                            {path: 'approve/:id', component: PayoutForApproveAsBillPageComponent}
                        ],
                        data: {
                            title: 'Bills',
                            access: {rule: {purchases__view_bills: true}}
                        }
                    },

                    {
                        path: 'expenses',
                        canActivate: [AccessGuardService],
                        children: [
                            {path: '', component: ExpenseListComponent},
                            {
                                path: 'create',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_expenses: true}}
                                },
                                component: ExpenseEditComponent
                            },
                            {
                                path: ':id/edit',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_expenses: true}}
                                },
                                component: ExpenseEditComponent
                            },
                            {path: ':id', component: ExpensePageComponent}
                        ],
                        data: {
                            title: 'Expenses',
                            access: {rule: {purchases__view_expenses: true}}
                        }
                    },
                    {
                        path: 'vendorcredits',
                        canActivate: [AccessGuardService],
                        children: [
                            {path: '', component: VendorCreditsComponent},
                            {
                                path: 'create',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_vendor_credits: true}}
                                },
                                component: EditVendorCreditComponent
                            },
                            {
                                path: ':id/edit',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {purchases__manage_vendor_credits: true}}
                                },
                                component: EditVendorCreditComponent
                            },
                            {path: ':id', component: ViewVendorCreditComponent}
                        ],
                        data: {
                            title: 'Vendor Credits',
                            access: {rule: {purchases__view_vendor_credits: true}}
                        }
                    },
                    {
                        path: 'sourcecredits',
                        canActivate: [AccessGuardService],
                        children: [
                            {path: '', component: SourceCreditNotesListComponent},
                            {path: ':id', component: SourceCreditNoteComponent}
                        ],
                        data: {
                            title: 'Vendor Credits',
                            access: {rule: {purchases__view_source_documents: true}}
                        }
                    }
                ]
            }
        ]
    },
    {
        path: 'escrowdeposit',
        component: UserLayoutComponent,
        resolve: {authBootstrap: AuthBootstrapService},
        canActivate: [AccessGuardService],
        children: [
            {
                path: '',
                component: FinancePageComponent,
                children: [
                    {path: '', pathMatch: 'full', component: RequestListComponent},
                    {
                        path: 'releases',
                        children: [{path: '', component: ReleaseListComponent}],
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Deposit Releases',
                            access: {anyRule: [{escrow_deposits__access: true}, {deals__manage_escrow_deposits: true}]}
                        }
                    },
                    {path: ':id', component: RequestPageComponent}
                ]
            }
        ],
        data: {
            title: 'Escrow Deposit',
            access: {anyRule: [{escrow_deposits__access: true}, {deals__manage_escrow_deposits: true}]}
        }
    },
    {
        path: 'lending',
        component: UserLayoutComponent,
        resolve: {authBootstrap: AuthBootstrapService},
        canActivate: [AccessGuardService],
        data: {
            access: {rule: {lending__access: true}}
        },
        children: [
            {
                path: '',
                component: FinancePageComponent,
                children: [
                    {path: '', pathMatch: 'full', redirectTo: '/lending/advances'},
                    {
                        path: 'advances',
                        children: [
                            {path: '', component: AdvancesComponent},
                            {
                                path: 'edit/:id',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {lending__manage_advances: true}}
                                },
                                component: EditAdvancePageComponent
                            },
                            {
                                path: 'create',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {lending__manage_advances: true}}
                                },
                                component: EditAdvancePageComponent
                            },
                            {path: ':id', component: AdvancePageComponent}
                        ],
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Advances',
                            access: {rule: {lending__view_advances: true}}
                        }
                    },
                    {
                        path: 'garnishments',
                        canActivate: [AccessGuardService],
                        children: [
                            {path: '', component: GarnishmentsComponent},
                            {
                                path: 'edit/:id',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {lending__manage_garnishments: true}}
                                },
                                component: EditGarnishmentPageComponent
                            },
                            {
                                path: 'create',
                                canActivate: [AccessGuardService],
                                data: {
                                    access: {rule: {lending__manage_garnishments: true}}
                                },
                                component: EditGarnishmentPageComponent
                            },
                            {path: ':id', component: GarnishmentPageComponent}
                        ],
                        data: {
                            title: 'Garnishments',
                            access: {rule: {lending__view_garnishments: true}}
                        }
                    },
                    {
                        path: 'my-loans',
                        canActivate: [AccessGuardService],
                        children: [
                            {path: '', component: UserLoansComponent},
                            {path: ':id', component: UserLoanPageComponent}
                        ],
                        data: {
                            title: 'Loans',
                            access: {rule: {lending__view_loans: true}}
                        }
                    }
                ]
            }
        ]
    },
    {
        path: 'viewinvoice',
        component: GuestLayoutComponent,
        resolve: {
            // guestBootstrap: PublicBootstrapService
        },
        children: [
            {
                path: ':invoice_hash',
                component: InvoicePublicPageV2Component
            }
        ]
    },
    {
        path: 'invoice-statement',
        component: GuestLayoutComponent,
        resolve: {},
        children: [
            {
                path: ':statement_hash',
                component: StatementPublicPageComponent
            }
        ]
    },
    {
        // speeduptransfer
        path: 'servicelevel/upgrade',
        component: GuestLayoutComponent,
        resolve: {
            // guestBootstrap: PublicBootstrapService
        },
        children: [
            {
                path: ':transfer_hash',
                component: SpeedupTransferPublicPageComponent
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
