import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CurrencyPipe} from '@angular/common';
import {IProduct} from '@cyberco-nodejs/zipi-typings';
import {select, Store} from '@ngrx/store';
import {selectProducts} from '../../../finance/store/finance.selectors';
import {FetchProducts} from '../../../finance/store/finance.actions';
import {IFinanceState} from '../../../finance/store/finance.reducer';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {CreateProductDialogComponent} from '../../../finance/components/products/create-product-dialog/create-product-dialog.component';

@Component({
    selector: 'app-product-service-selector',
    styles: [
        `
            .custom-required-error {
                position: absolute;
                font-size: smaller;
                margin-top: -1.5em;
                color: red;
            }
        `
    ],
    template: `
        <ng-container *ngIf="productServiceControl && !productServiceControl.value">
            <mat-form-field class="w-100" [hideRequiredMarker]="hideRequiredMarker" *ngIf="filterControl">
                <input
                    type="text"
                    matInput
                    [formControl]="filterControl"
                    placeholder="{{ placeholder }}"
                    autocomplete="off"
                    [matAutocomplete]="autoGroup"
                />
                <mat-autocomplete #autoGroup="matAutocomplete">
                    <mat-option (click)="$event.stopPropagation(); createProduct()">Create New Product</mat-option>

                    <ng-container *ngFor="let product of filteredProducts">
                        <mat-option
                            *ngIf="product.status === 'active' || productServiceControl.value === product.product_id"
                            [value]="product.name"
                            [disabled]="
                                disabledProductSystemKeys.includes(product.system_key) ||
                                (allowedProductsIdsOnly &&
                                    !(
                                        Array.isArray(allowedProductsIdsToSelect) &&
                                        allowedProductsIdsToSelect.includes(product.product_id)
                                    ))
                            "
                            (onSelectionChange)="selectItem(product)"
                        >
                            {{ product.name }}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
                <mat-hint align="end" *ngIf="!products">No data</mat-hint>
            </mat-form-field>
            <div *ngIf="filterControl && filterControl.invalid" class="custom-required-error">Required field</div>
        </ng-container>

        <ng-container *ngIf="productServiceControl && productServiceControl.value">
            <mat-form-field
                class="w-100"
                [ngStyle]="{'font-style': productServiceControl.value === -1 ? 'italic' : 'normal'}"
                *ngIf="filterControl"
            >
                <input [formControl]="filterControl" matInput type="text" placeholder="{{ placeholder }}" />
                <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    *ngIf="!hideReselect"
                    [disabled]="disabledReselect"
                    (click)="removeProductFkId()"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>
    `
})
export class ProductServiceSelectorComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    protected readonly Array = Array;

    @Input() disabledProductSystemKeys: Array<string> = ['closing_debit', 'closing_credit'];
    @Input() allowedProductsIdsToSelect: Array<number> = [];
    @Input() allowedProductsIdsOnly: boolean = false;
    @Input() disabledReselect: boolean = false;
    @Input() hideReselect: boolean = false;
    @Input() productServiceControl: UntypedFormControl | undefined;
    @Input() disabled: boolean = false;
    @Input() placeholder: string = 'Product/Service';
    @Input() hideRequiredMarker: boolean = false;
    @Input() validators: ValidatorFn | ValidatorFn[] | null = Validators.required;
    @Output() onProductSelectionChange = new EventEmitter<IProduct>();

    products: IProduct[] = [];
    filteredProducts: IProduct[] = [];

    filterControl: UntypedFormControl | undefined;

    constructor(
        protected dialog: MatDialog,
        protected currency: CurrencyPipe,
        protected fb: UntypedFormBuilder,
        private store: Store<IFinanceState>
    ) {}

    ngOnInit() {
        this.filterControl = new UntypedFormControl('', []);
        if (this.validators) {
            this.filterControl?.setValidators(this.validators);
        }
        if (this.disabledReselect) {
            this.filterControl.disable({emitEvent: false});
        }

        this.filterControl?.updateValueAndValidity({emitEvent: false});

        this.store.dispatch(new FetchProducts());
        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((products) => {
            this.products = products;
            this.filteredProducts = products;
            if (typeof this.productServiceControl !== 'undefined' && this.productServiceControl.value) {
                this.setFilterControl();
                this.patchFilterControlIfNeeded();
            }
        });

        this.filterControl.valueChanges
            .pipe(
                startWith(''),
                map((val) => this.filterProducts(val)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        if (typeof this.productServiceControl !== 'undefined') {
            this.productServiceControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                this.setFilterControl();
                this.patchFilterControlIfNeeded();
            });
        }
    }

    patchFilterControlIfNeeded() {
        if (
            typeof this.productServiceControl !== 'undefined' &&
            typeof this.filterControl !== 'undefined' &&
            this.productServiceControl.value === -1
        ) {
            this.removeProductFkId();
            // That was a legacy solution. Now we just remove the value from the control
            // and will force the user to select some product.
            // this.filterControl.patchValue('No Product', {emitEvent: false});
        }
    }

    filterProducts(filterValue?: string) {
        const filteredProducts: IProduct[] = [];
        this.products.map((product) => {
            if (filterValue) {
                const isValid = product.name && product.name.toLowerCase().indexOf(filterValue) !== -1;
                if (!isValid) {
                    return;
                }
            }
            filteredProducts.push(product);
        });
        this.filteredProducts = filteredProducts;
    }

    setFilterControl() {
        if (
            typeof this.productServiceControl !== 'undefined' &&
            typeof this.filterControl !== 'undefined' &&
            this.productServiceControl.value === null
        ) {
            this.filterControl.patchValue(null);
            if (!this.disabledReselect) {
                this.filterControl.enable();
            }
            return;
        }

        const product: IProduct | undefined = this.products.find(
            (ac) => ac.product_id === this.productServiceControl?.value
        );

        if (product && typeof this.filterControl !== 'undefined') {
            this.filterControl.patchValue(product.name);
            this.filterControl.disable();
            this.onProductSelectionChange.emit(product);
        }
    }

    removeProductFkId() {
        if (
            typeof this.productServiceControl === 'undefined' ||
            typeof this.filterControl === 'undefined' ||
            this.disabled
        ) {
            return;
        }
        this.productServiceControl.patchValue(null);
        this.filterControl.patchValue(null);
        if (!this.disabledReselect) {
            this.filterControl.enable();
        }
    }

    selectItem(product: IProduct) {
        if (typeof this.productServiceControl === 'undefined') {
            return;
        }
        this.productServiceControl.patchValue(product.product_id);
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {});

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.onProductSelectionChange.complete();
    }
}
