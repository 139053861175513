import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import * as moment from 'moment';
import {get as getProp} from 'lodash-es';
import {IInvoiceItem, IProduct, IInvoice, IFinancialTransfer, IGroup} from '@cyberco-nodejs/zipi-typings';
import {pipe, Subject, combineLatest, ReplaySubject} from 'rxjs';
import {takeUntil, tap, map} from 'rxjs/operators';

import {IMaybeValidInvoiceTemplate} from '../../recurring-invoice/edit-recurring-invoice/edit-recurring-invoice.component';
import {IFinanceState} from '../../../store/finance.reducer';
import {selectProducts} from 'app/store/root.selectors';
import {PAYMENT_TERMS} from 'app/local-typings';
import {FetchProducts} from '../../../store/finance.actions';
import {MatDialog} from '@angular/material/dialog';
import {ContactCreateDialogComponent} from 'app/modules/contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {DealService} from '../../../../../services/deal.service';
import {CombinedCompensationProfilePickerComponent} from '../../../../account-info/compensation/components/combined-compensation-profile-picker/combined-compensation-profile-picker.component';
import {SessionService} from '../../../../../services/session.service';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import Decimal from 'decimal.js-light';
import {FinancialTransferEntity} from '../../../../account-info/compensation/models/financial-transfer.entity';
import {
    cleanCurrencyString,
    currencyMaskitoOptions,
    currencyWithNegativeMaskitoOptions,
    unmaskCurrencyControlValue
} from '../../../../../utilities/maskito';
import {customAmountValidator} from '../../../../../utilities';
import {IRelatedDealPayoutInfo} from '@app/modules/finance/types/financial.types';

@Component({
    selector: 'app-invoice-template',
    templateUrl: 'invoice-template.component.html',
    styleUrls: ['invoice-template.component.scss']
})
export class InvoiceTemplateComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private gotInvoice = new ReplaySubject<IInvoice>(1);

    @Input() set invoice(inv: IInvoice | undefined) {
        if (!inv) {
            return;
        }
        this._invoice = inv;
        this.gotInvoice.next(inv);
    }
    @Input() makeInvoiceFormTouch: Subject<void> = new Subject<void>();
    @Input() invoice_id: number | undefined;

    get invoice() {
        return this._invoice;
    }

    public _invoice: IInvoice | undefined;

    @Output() onUpdate = new EventEmitter<IMaybeValidInvoiceTemplate>();

    @ViewChild('combinedPicker', {static: true}) combinedPicker: CombinedCompensationProfilePickerComponent | undefined;

    itemsArray: FormGroupArray = this.fb.array([this.createItem()]) as FormGroupArray;
    // productForDeductArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    associationItemArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    associationItemForDeleteArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        invoice_number: null,
        sales_person__profile_fk_id: null, // Sales Person
        payment_terms: ['due-on-receipt', Validators.required],
        payment_terms_custom: null,
        invoice_date: [new Date(), Validators.required],
        due_date: [moment().add(1, 'week').format(), []],
        total_amount: [{value: 0, disabled: true}],
        pay_to__payment_method_fk_ids: [[], []],
        pay_to_card__payment_method_fk_id: [null, []],
        pay_to_bank__payment_method_fk_id: [null, []],
        to_be_paid__payment_method_fk_id: [null, []],
        items: this.itemsArray,
        money_sender__contact_fk_id: [null, Validators.required],
        money_senders__company_group_fk_id: null,
        is_deduct_from_deal: [true, []],
        division__company_group_fk_id: [null, []],
        bank_fee_payer: ['money_sender', []],
        card_fee_payer: ['money_sender', []]
    }) as FormGroupWithFormControls;
    moneySenderCtrl = this.fb.control(null, Validators.required);

    emptyItem = {
        rate: 0,
        quantity: 1,
        amount: 0,
        invoice_item_id: null,
        product_fk_id: null,
        name: null,
        description: null,
        ledger_account_fk_id: null,
        connected__deal_fk_id: null,
        item_has_transfer: null
    };

    currencyMaskitoMask = currencyMaskitoOptions;
    currencyWithNegativeMaskitoMask = currencyWithNegativeMaskitoOptions;

    products: IProduct[] = [];

    paymentTerms = PAYMENT_TERMS;

    showGroupDetails: boolean;

    filterForm: UntypedFormGroup = this.fb.group({
        filterData: ''
    });

    isAllLoaded = false;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedContactIds: Array<number> = [];
    selectedProfileArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedProfileIds: number[] = [];

    availableDivisionsGroups: IGroup[] = [];

    warning: string | null = null;
    prodSet: {[key: number]: {product: IProduct | undefined; max_amount: number}} = {};
    object = Object;

    lastGroupId: number | null = null;

    groupList: IGroup[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        public dialog: MatDialog,
        private dealService: DealService,
        private sessionService: SessionService,
        private groupSrc: GroupsSource
    ) {
        this.showGroupDetails = false;
    }

    private createItem(item: IInvoiceItem | null = null, itemIndex: number = 0) {
        const isDisabled = !!(item && item.connected__deal_fk_id && item.connected__financial_transfer_fk_id);

        const group = this.fb.group({
            invoice_item_id: [item ? item.invoice_item_id : null],
            product_fk_id: [
                {
                    value: item ? item.product_fk_id : null,
                    disabled: isDisabled
                }
            ],
            name: [
                {
                    value: item ? item.name : '',
                    disabled: item && item.product_fk_id
                },
                [Validators.required, Validators.minLength(1)]
            ],
            description: [item ? item.description : null],
            quantity: [item ? item.quantity : 1, [Validators.required, Validators.min(1)]],
            rate: [{value: item ? item.rate : 0, disabled: false}, customAmountValidator],
            rate__wildcard_fk_id: [{value: null, disabled: false}],
            quantity__wildcard_fk_id: [{value: null, disabled: false}],
            amount: [{value: item ? item.amount : 0, disabled: true}],
            ledger_account_fk_id: [
                {
                    value: item ? item.ledger_account_fk_id : null,
                    disabled: (item && item.product_fk_id) || isDisabled
                },
                Validators.required
            ],
            connected__deal_fk_id: [
                {
                    value: item ? item.connected__deal_fk_id : null,
                    disabled: isDisabled
                }
            ],
            item_has_transfer: [isDisabled],
            order_index: [item && item.order_index],
            division__company_group_fk_id: [
                {
                    value: item ? item.division__company_group_fk_id : this.lastGroupId,
                    disabled: !!this.lastGroupId
                }
            ],
            selected_divisions: new GenericFormArray<ChipNode>([]),
            selected_division_ids: [item ? item.division__company_group_fk_id : null],
            selected_full_ledger: null
        });

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });

        const handleValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => {
                const quantity = group.controls.quantity.value;
                const rate = group.controls.rate.value;
                if (!isNaN(quantity) && !isNaN(rate)) {
                    group.controls.amount.patchValue(quantity * rate);
                }
            })
        );

        const unmaskRateFn = unmaskCurrencyControlValue(group.controls.rate);

        group.controls.quantity.valueChanges
            .pipe(
                handleValueChanges,
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        group.controls.rate.valueChanges
            .pipe(
                unmaskRateFn,
                handleValueChanges,
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        group.controls.rate__wildcard_fk_id.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        group.controls.quantity__wildcard_fk_id.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        return group;
    }

    buildAssociationProductsArray() {
        this.prodSet = {};
        // this.productForDeductArray.clear();
        const items = this.itemsArray.getRawValue();
        if (items && items.length > 0) {
            items.forEach((item) => {
                if (item.product_fk_id) {
                    if (this.prodSet.hasOwnProperty(item.product_fk_id)) {
                        const newProductMax = new Decimal(item.amount)
                            .add(this.prodSet[item.product_fk_id].max_amount)
                            .toDecimalPlaces(2)
                            .toNumber();
                        this.prodSet[item.product_fk_id].max_amount =
                            newProductMax > this.formGroup.controls.total_amount.value
                                ? this.formGroup.controls.total_amount.value
                                : newProductMax;
                    } else {
                        const newProductMax = item.amount;
                        this.prodSet[item.product_fk_id] = {
                            max_amount:
                                newProductMax > this.formGroup.controls.total_amount.value
                                    ? this.formGroup.controls.total_amount.value
                                    : newProductMax,
                            product: this.products.find((product) => product.product_id === item.product_fk_id)
                        };
                    }
                }
            });
        }
        if (this.associationItemArray.length > 0) {
            this.associationItemArray.controls.forEach((associationControl) => {
                const associationGroup: UntypedFormGroup = associationControl as unknown as UntypedFormGroup;
                if (
                    associationGroup.controls.product_id.value &&
                    this.prodSet.hasOwnProperty(associationGroup.controls.product_id.value)
                ) {
                    // if (associationGroup.controls.transfer_id.value) {
                    //     this.prodSet[associationGroup.controls.product_id.value].max_amount -= Number(unmaskCurrencyString(associationGroup.controls.amount.value));
                    // }
                } else {
                    associationGroup.controls.product_id.setValue(null);
                }
            });
        }
    }

    addItem(item?: IInvoiceItem) {
        this.itemsArray.push(this.createItem(item, this.itemsArray.length));
    }

    addDealAssociation(finTransfer?: IRelatedDealPayoutInfo) {
        if (finTransfer && finTransfer.amount !== null) {
            const group = this.fb.group({
                transfer_id: [finTransfer.financial_transfer_id, []],
                product_id: [finTransfer.product_id, [Validators.required]],
                product_name: [finTransfer.product_name, [Validators.required]],
                amount: [finTransfer.amount ? finTransfer.amount : null, []],
                connected__deal_fk_id: [finTransfer.deal_id, [Validators.required]]
            });
            group.disable();
            this.associationItemArray.push(group);
        } else {
            this.associationItemArray.push(
                this.fb.group({
                    transfer_id: [null, []],
                    product_id: [null, [Validators.required]],
                    product_name: [null, [Validators.required]],
                    amount: [0, []],
                    connected__deal_fk_id: [null, [Validators.required]]
                })
            );
        }
    }
    removeDealAssociation(index: number) {
        const itemToDelete: UntypedFormGroup = this.associationItemArray.at(index) as UntypedFormGroup;

        if (itemToDelete.controls.transfer_id?.value) {
            this.associationItemForDeleteArray.push(itemToDelete);
        }
        this.associationItemArray.removeAt(index);
    }

    validateAmount($event: any, product_id: number) {
        if (product_id) {
            const inputValue = Number(cleanCurrencyString($event.target.value));

            const associations = this.associationItemArray.getRawValue();
            const usedAmount: Decimal = associations
                .reduce((acc, ass) => {
                    return Number(ass.product_id) === Number(product_id)
                        ? acc.add(Number(cleanCurrencyString(ass.amount)))
                        : acc;
                }, new Decimal(0))
                .sub(inputValue);
            const maxAmount: Decimal = new Decimal(this.prodSet[product_id].max_amount);
            const availableAmount: Decimal = maxAmount.sub(usedAmount);

            if (availableAmount.toDecimalPlaces(2).toNumber() < inputValue || inputValue === 0) {
                this.associationItemArray.controls.forEach((associationItem) => {
                    const associationGroup: UntypedFormGroup = associationItem as unknown as UntypedFormGroup;
                    if (
                        associationGroup.controls.product_id.value &&
                        associationGroup.controls.product_id.value === product_id
                    ) {
                        associationGroup.controls.amount.setValidators([Validators.email, Validators.min(1)]);
                        associationGroup.controls.amount.updateValueAndValidity();
                        associationGroup.controls.amount.markAsTouched();
                    }
                });
            } else {
                this.associationItemArray.controls.forEach((associationItem) => {
                    const associationGroup: UntypedFormGroup = associationItem as unknown as UntypedFormGroup;
                    if (
                        associationGroup.controls.product_id.value &&
                        associationGroup.controls.product_id.value === product_id
                    ) {
                        associationGroup.controls.amount.clearValidators();
                        associationGroup.controls.amount.setValidators([Validators.min(1)]);
                        associationGroup.controls.amount.updateValueAndValidity();
                    }
                });
            }
        }
    }

    setProductName($event: any, associationGroup: UntypedFormGroup) {
        const prodId = $event.value;
        const currentProduct = this.prodSet[prodId].product;
        if (currentProduct) {
            associationGroup.controls.product_name.setValue(currentProduct.name);
        }
    }

    selectItem(item: AbstractControl, product: IProduct) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.product_fk_id.patchValue(product.product_id);
        itemGroup.controls.rate.patchValue(product.price);
        itemGroup.controls.rate__wildcard_fk_id.patchValue(null);
        itemGroup.controls.quantity__wildcard_fk_id.patchValue(null);
        itemGroup.controls.description.patchValue(product.description);
        itemGroup.controls.ledger_account_fk_id.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.quantity.patchValue(1);
        itemGroup.controls.name.patchValue(product.name);

        itemGroup.controls.ledger_account_fk_id.disable();
        itemGroup.controls.name.disable();
    }

    removeProductFkId(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.ledger_account_fk_id.enable();
        itemGroup.controls.name.enable();
        itemGroup.controls.name.patchValue(null);
        itemGroup.controls.product_fk_id.patchValue(null);
    }

    resetItem(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.reset(this.emptyItem);
        itemGroup.controls.name.enable();
    }

    trimOnBlur(control: AbstractControl) {
        if (control.value) {
            const trimmedValue = control.value.trim().length === 0 ? null : control.value.trim();
            control.patchValue(trimmedValue);
        }
    }

    private computeTotal = () => {
        const total: Decimal = this.itemsArray.controls.reduce<Decimal>((acc: Decimal, item: AbstractControl) => {
            const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
            return acc.add(itemGroup.getRawValue().amount);
        }, new Decimal(0));
        this.formGroup.controls.total_amount.patchValue(total.toDecimalPlaces(2).toNumber());
    };

    initEdit(invoice: IInvoice) {
        this.formGroup.patchValue(invoice);
        this.formGroup.controls.division__company_group_fk_id.disable();

        const items = getProp(invoice, 'items');
        if (!items || items.length === 0) {
            return;
        }
        this.itemsArray.removeAt(0);
        items.forEach((item: IInvoiceItem) => this.addItem(item));

        if (invoice.invoice_date) {
            this.formGroup.controls.invoice_date.setValue(invoice.invoice_date);
        }

        if (invoice.money_sender__contact_fk_id) {
            this.moneySenderCtrl.patchValue(invoice.money_sender__contact_fk_id, {emitEvent: false});
        }

        if (invoice && invoice.money_sender__contact_fk_id) {
            this.selectedContactIds = [invoice.money_sender__contact_fk_id];
        }
        if (invoice && invoice.sales_person__profile_fk_id) {
            this.selectedProfileIds = [invoice.sales_person__profile_fk_id];
        }
        this.buildAssociationProductsArray();
    }

    deselectCustomer() {
        this.formGroup.controls.money_senders__company_group_fk_id.reset(null, {emitEvent: false});
        this.formGroup.controls.money_sender__contact_fk_id.reset(null);
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            // minWidth: 320,
            // width: '90vw',
            // maxWidth: '90vh',
            autoFocus: false,
            data: {
                category: 'customer'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                // force reload
                this.combinedPicker!.params.offset = 0;
                this.combinedPicker!.initialLoadData();
            });
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {
            // width: '90vw',
            // minHeight: '30vh'
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    getProducts(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.getRawValue().name) {
            const filteredValue = itemGroup.getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    getItemProducts(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.getRawValue().name) {
            const filteredValue = itemGroup.getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    ngOnInit() {
        this.lastGroupId = Number(localStorage.getItem('current_company_group_id'));

        this.store.dispatch(new FetchProducts());
        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
            this.buildAssociationProductsArray();
        });

        this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
            this.onUpdate.emit({
                valid:
                    this.formGroup.valid &&
                    this.formGroup.controls.total_amount.value > 0 &&
                    (this.associationItemArray.disabled || this.associationItemArray.valid),
                template: {
                    ...this.formGroup.getRawValue(),
                    items: this.itemsArray.getRawValue().map((item, idx) => {
                        delete item.rate__wildcard_fk_id;
                        delete item.quantity__wildcard_fk_id;
                        return {...item, rate: Number(item.rate), order_index: idx + 1};
                    })
                },
                association: {
                    to_create: this.associationItemArray.getRawValue(),
                    to_delete: this.associationItemForDeleteArray.getRawValue()
                }
            })
        );

        this.associationItemArray.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
            this.onUpdate.emit({
                valid:
                    this.formGroup.valid &&
                    this.formGroup.controls.total_amount.value > 0 &&
                    (this.associationItemArray.disabled || this.associationItemArray.valid),
                template: {
                    ...this.formGroup.getRawValue(),
                    items: this.itemsArray.getRawValue().map((item, idx) => {
                        delete item.rate__wildcard_fk_id;
                        delete item.quantity__wildcard_fk_id;
                        return {...item, rate: Number(item.rate), order_index: idx + 1};
                    })
                },
                association: {
                    to_create: this.associationItemArray.getRawValue(),
                    to_delete: this.associationItemForDeleteArray.getRawValue()
                }
            })
        );

        this.formGroup.controls.payment_terms.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value === 'custom') {
                this.formGroup.controls.payment_terms_custom.setValidators([Validators.required, Validators.min(1)]);
                this.formGroup.controls.payment_terms_custom.updateValueAndValidity();
            } else {
                this.formGroup.controls.payment_terms_custom.clearValidators();
                this.formGroup.controls.payment_terms_custom.updateValueAndValidity();
            }
        });

        this.itemsArray.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe((change) => this.buildAssociationProductsArray());

        if (this.sessionService.profile && this.sessionService.profile.company_groups_member) {
            this.availableDivisionsGroups = this.sessionService.profile.company_groups_member
                .filter((member) => member.company_group && member.company_group.type === 'division')
                .map((member) => member.company_group as IGroup)
                .filter((member) => !!member);
        }
        if (!this.invoice || !this.invoice.invoice_id) {
            const lastGroupId = localStorage.getItem('current_company_group_id');
            this.formGroup.controls.division__company_group_fk_id.setValue(Number(lastGroupId));
            this.formGroup.controls.division__company_group_fk_id.disable();
        }

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                if (values[0].type === 'contact') {
                    this.formGroup.controls.money_sender__contact_fk_id.setValue(values[0].target_id);
                }
            } else {
                this.deselectCustomer();
            }
        });

        this.selectedProfileArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: any[]) => {
            if (values && values.length > 0) {
                if (values[0].type === 'individual') {
                    this.formGroup.controls.sales_person__profile_fk_id.setValue(values[0].target_id);
                }
            } else {
                this.formGroup.controls.sales_person__profile_fk_id.reset(null, {emitEvent: false});
            }
        });

        this.filterForm = this.fb.group({
            filterData: ''
        });

        combineLatest([this.gotInvoice])
            .pipe(
                map(([invoice]) => {
                    this.initEdit(invoice);
                    this.isAllLoaded = true;
                    if (invoice && invoice.invoice_id) {
                        this.dealService.getFinancialTransfersByInvoiceId(invoice.invoice_id).then((transfers) => {
                            if (transfers && transfers.length > 0) {
                                transfers
                                    .filter((tr) => tr.type === FinancialTransferEntity.type_SET.associate_transfer)
                                    .forEach((transfer) => this.addDealAssociation(transfer));
                            }
                        });
                    }
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        // touch forms
        this.makeInvoiceFormTouch.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.formGroup.markAllAsTouched();
            this.associationItemArray.markAllAsTouched();
        });

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list.filter((group) => group.type === 'division') as IGroup[];
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.gotInvoice.complete();
        this.onUpdate.complete();
        this.makeInvoiceFormTouch.complete();
    }
}
