import {Injectable} from '@angular/core';
import {IRemainingPayoutsWithAdditionalInfo} from '../../remaining-payouts/types/remaining-payouts.types';
import Decimal from 'decimal.js-light';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Injectable()
export class RemainingPayoutsDataService {
    constructor(private fb: UntypedFormBuilder) {}

    combinePayouts(data: IRemainingPayoutsWithAdditionalInfo[]) {
        let combinedPayouts: any[] = [];
        const independentArrayOfPayouts = JSON.parse(JSON.stringify(data));
        for (const payout of independentArrayOfPayouts) {
            const existingMainPayout = combinedPayouts.find(
                (existingPayout) => existingPayout.contact_id === payout.contact_id
            );
            if (existingMainPayout) {
                existingMainPayout.amount = new Decimal(existingMainPayout.amount)
                    .add(payout.amount)
                    .toDecimalPlaces(2)
                    .toNumber();
                existingMainPayout.sub_payouts.push(JSON.parse(JSON.stringify(payout)));
            } else {
                payout.sub_payouts = [JSON.parse(JSON.stringify(payout))];
                combinedPayouts.push(payout);
            }
        }

        return combinedPayouts;
    }

    separatePayouts(data: any[]) {
        let separatedPayouts: any[] = [];
        for (const payout of data) {
            if (!payout.sub_payouts || (payout.sub_payouts && payout.sub_payouts.length === 1)) {
                delete payout.sub_payouts;
                separatedPayouts.push(payout);
            } else {
                for (const subPayout of payout.sub_payouts) {
                    subPayout.check_number = payout.check_number;
                    subPayout.memo = payout.memo;
                    subPayout.reference = payout.reference;
                    subPayout.pay_to__payment_method_fk_id = payout.pay_to__payment_method_fk_id;
                    separatedPayouts.push(subPayout);
                }
            }
        }
        return separatedPayouts;
    }

    createItem(payout: IRemainingPayoutsWithAdditionalInfo, isNeedToCombine: boolean) {
        const group: UntypedFormGroup = this.fb.group({
            financial_transfer_id: [payout.financial_transfer_id, [Validators.required]],
            amount: [payout.amount, []],
            pay_to_name: [payout.pay_to_name, []],
            contact_id: [payout.contact_id, []],
            deal_name: payout.deal_name,
            deal_id: payout.deal_id,
            sub_payouts: [payout.sub_payouts, []],
            check_number: ['', []],
            memo: [this.buildPayoutReference(payout), []],
            reference: [`Payment for deal# ${payout.deal_id}.\r\n${this.buildPayoutReference(payout)}`, []],
            is_available_for_zipi_pay: [payout.is_available_for_zipi_pay, []],
            is_available_for_payloadco_zipi_pay: [payout.is_available_for_payloadco_zipi_pay, []],
            payloadco_receive_only_method_title: [payout.payloadco_receive_only_method_title, []],
            payloadco_receive_only_methods: [payout.payloadco_receive_only_methods, []],
            pay_to__payment_method_fk_id: [null, []]
        });
        if (isNeedToCombine && payout.sub_payouts && payout.sub_payouts.length > 1) {
            let multiRef = 'Payment for deals';
            for (const [index, subPayout] of payout.sub_payouts.entries()) {
                multiRef += ` #${subPayout.deal_id}${payout.sub_payouts.length === index + 1 ? '.' : ','}`;
            }
            group.controls.memo.setValue(multiRef);
            group.controls.reference.setValue(multiRef);
        }
        return group;
    }

    buildPayoutReference(payout: IRemainingPayoutsWithAdditionalInfo) {
        let fullAddress = '';
        if (payout.deal_address) {
            if (payout.deal_street_number) {
                fullAddress += `${payout.deal_street_number} `;
            }
            fullAddress += `${payout.deal_address} `;
            if (payout.deal_unit) {
                fullAddress += `${payout.deal_unit}, `;
            }
            fullAddress += `${payout.deal_city}, `;
            fullAddress += `${payout.deal_state} `;
            fullAddress += payout.deal_zip;
        } else {
            fullAddress = 'Property address';
        }
        return fullAddress;
    }
}
