<div>
    <mat-card class="preview-body" *ngIf="reportData.length">
        <div>
            <table class="mat-table tables w-100">
                <tr class="mat-header-row">
                    <th class="header-cell sticky">Name</th>
                    <th class="header-cell sticky">Profile</th>
                    <th class="header-cell sticky">Element</th>
                    <th class="header-cell sticky">Rule</th>
                    <th class="header-cell sticky">Target</th>
                    <th class="header-cell sticky">Condition Type</th>
                    <th class="header-cell sticky">Product/Service</th>
                    <th class="header-cell sticky">Current Status</th>
                    <th class="header-cell sticky">Cap Start Date</th>
                    <th class="header-cell sticky">Cap End Date</th>
                    <th class="header-cell sticky">Entity Split</th>
                    <th class="header-cell sticky">Company Split</th>
                </tr>
                <tr class="table-row" *ngFor="let item of reportData">
                    <td class="body-cell">{{ item.name }}</td>
                    <td class="body-cell">{{ item.compensation_profile_title }}</td>
                    <td class="body-cell">{{ item.financial_element_title }}</td>
                    <td class="body-cell">{{ item.current_rule_name }}</td>
                    <td class="body-cell">{{ item.rule_target | reportFormatted: 'financial' }}</td>
                    <td class="body-cell">{{ item.condition_type }}</td>
                    <td class="body-cell">{{ item.product_name }}</td>
                    <td class="body-cell">{{ item.current_status | reportFormatted: 'financial' }}</td>
                    <td class="body-cell">{{ item.startDate }}</td>
                    <td class="body-cell">{{ item.endDate }}</td>
                    <td class="body-cell">{{ item.total_agent_split | reportFormatted: 'financial' }}</td>
                    <td class="body-cell">{{ item.total_company_split | reportFormatted: 'financial' }}</td>
                </tr>
            </table>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!reportData.length">
        <h4 class="table-header">
            We didn't find any data matching report criteria. So there nothing to show. Please adjust report parameters
            to get some results
        </h4>
    </mat-card>
</div>
